import parser, { j2xParser as JSONparser } from 'fast-xml-parser';
import { processAnexoF } from './anexoF_utils';
import { processAnexoJ } from './anexoJ_utils';
export function fillIRS(parsedJsonFromExcel, xmlFromAt) {
  // console.log('got parsedJsonFromExcel', parsedJsonFromExcel);
  //console.log('got xmlFromAt', xmlFromAt);
  let finalJSONFile = {
    //fix AT header
  };

  //main function (merge all data)
  let errors = [];

  let validJsonFromExcel = null;
  //let validJsonFromAT = null;

  try {
    //make sure EXCEL json  is valid
    validJsonFromExcel = JSON.parse(JSON.stringify(parsedJsonFromExcel));
  } catch (e) {
    console.log('ERROR: JSON not valid', e);
    errors.push('Erro ao traduzir o excel para XML AT');
    return { xml: null, errors };
  }
  // try {
  //   //make sure AT json  is valid
  //   //if (!xmlFromAt) {
  //   xmlFromAt = emptyIRSXml; //DELETE AFTER DELETE 2º STEP
  //   // }
  //   let IrsATJson = parseXmlToJson(xmlFromAt);
  //   validJsonFromAT = JSON.parse(JSON.stringify(IrsATJson)); //make sure is valid
  //   // console.log(validJsonFromAT);
  // } catch (e) {
  //   console.log('Erro ao traduzir o excel para XML AT');
  //   errors.push('Erro ao traduzir o excel para XML AT');
  // }

  if (!validJsonFromExcel) {
    console.log('Erro ao traduzir o excel para XML AT');
    errors.push('Erro ao traduzir o excel para XML AT');
  }

  console.log('finalJSONFile', validJsonFromExcel);

  let anexos = Object.keys(validJsonFromExcel);

  anexos.forEach(anexo => {
    //ROSTO always exists

    finalJSONFile.Rosto = [
      {
        //temporary - get personal info
        QuadroInicio: '',
        Quadro01: '',
        Quadro02: '',
        Quadro03: '',
        Quadro04: '',
        Quadro05: '',
        Quadro06: '',
        Quadro07: '',
        Quadro08: '',
        Quadro09: '',
        Quadro10: '',
        Quadro11: '',
        Quadro13: ''
      }
    ];

    if (anexo === 'j') {
      finalJSONFile.AnexoJ = [];

      validJsonFromExcel[anexo].forEach((anexo, index) => {
        //we may get more than 1 anexo (family)
        processAnexoJ(
          validJsonFromExcel.j[index], //anexo full json
          errors,
          finalJSONFile
        );
      });
    }

    if (anexo === 'f') {
      finalJSONFile.AnexoF = [];

      validJsonFromExcel[anexo].forEach((anexo, index) => {
        //we may get more than 1 anexo (family)
        console.log(
          'Processign : validJsonFromExcel.f[index],finalJSONFile',
          validJsonFromExcel.f[index],
          finalJSONFile
        );

        processAnexoF(
          validJsonFromExcel.f[index], //anexo full json
          errors,
          finalJSONFile
        );
      });
    }
  });

  //finally we add the "parent AT" tag

  let something = { ...finalJSONFile };
  let finalJson = {
    Modelo3IRSv2024: {
      '@_xmlns': 'http://www.dgci.gov.pt/2009/Modelo3IRSv2024',
      '@_xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      '@_versao': 1,
      '@_xsi:schemaLocation':
        'http://www.dgci.gov.pt/2009/Modelo3IRSv2024 Modelo3IRSv2024.xsd',

      ...something
    }
  };

  // console.log('finalJSONFile4', finalJson);
  let finalXml = exportJsonToXml(finalJson);
  // console.log('finalXml', finalXml);
  return { xml: finalXml, errors };
}

export function parseXmlToJson(irsFile) {
  //IRS
  let optionsXMLtoJSON = {
    //attributeNamePrefix: '@_',
    //attrNodeName: 'attr', //default is 'false'
    // textNodeName: '#text',
    ignoreAttributes: false,
    ignoreNameSpace: false,
    allowBooleanAttributes: true,
    parseNodeValue: true,
    parseAttributeValue: true,
    // trimValues: true,
    //cdataTagName: '__cdata', //default is 'false'
    //cdataPositionChar: '\\c',
    // parseTrueNumberOnly: false,
    arrayMode: true //"strict"

    //stopNodes: ['parse-me-as-string']
  };

  if (parser.validate(irsFile) === true) {
    let jsonObj = parser.parse(irsFile, optionsXMLtoJSON);

    return jsonObj;
  } else {
    console.log('Not valid!'); //
    throw new Error('O ficheiro IRS da AT não é válido'); //format in some way to show to user
  }
}

export function exportJsonToXml(jsonObj) {
  //TODO try catch
  let optinsJSONtoXML = {
    // attributeNamePrefix: '@_',
    //attrNodeName: '@', //default is false
    //textNodeName: '#text',
    ignoreAttributes: false,
    // cdataTagName: '__cdata', //default is false
    //cdataPositionChar: '\\c',
    format: false
    //indentBy: '  ',
    //supressEmptyNode: true
  };
  //edition example
  //jsonObj.Modelo3IRSv2020[0].Rosto[0].Quadro01[0].Q01C01 = '9999';

  let jsonP = new JSONparser(optinsJSONtoXML);
  let xml = jsonP.parse(jsonObj);
  return xml;
}
