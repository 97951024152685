import React from 'react';

import './Fields.scss';

const Hr = ({ width }) => {
  return (
    <div key={'hr'} className={`input-container hr`}>
      <div className={`hr-inner w-${width ?? '25'}`}></div>
    </div>
  );
};

export default Hr;
