import React, { useEffect, useState, useCallback } from 'react';
import { Tag, DatePicker } from 'antd4';
import op from 'object-path';
import moment from 'moment';

import './Fields.scss';

const DateField = ({
  path,
  label,
  width,
  picker,
  disabled,
  data,
  updateField,
  showTime
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);
  const ref = React.createRef();

  useEffect(() => {
    const updateValue = op.get(data, path);
    setValue(updateValue);
  }, [data, path]);

  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async value => {
      const response = await updateField(path, value);
      if (response?.ok) {
        setStatusClass('save-success');
        setTimeout(() => {
          setStatusClass('');
        }, 3000);
      } else {
        setStatusClass('save-error');
      }
    },
    [path, updateField]
  );

  return (
    <div
      key={path}
      className={`input-container d-flex align-items-center w-${width ?? '25'}`}
    >
      <div className="input-tag-container">
        <Tag className="input-prefix border-radius">{label}</Tag>
      </div>
      <DatePicker
        showTime={
          showTime ? { defaultValue: moment('12:00:00', 'HH:mm:ss') } : false
        }
        picker={picker}
        disabled={disabled}
        ref={ref}
        className={'input input-date ' + statusClass}
        value={value ? moment(value) : null}
        onChange={onSave}
      />
    </div>
  );
};

export default DateField;
