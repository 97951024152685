import React from 'react';

import '../../../fields/Fields.scss';

const ArrayTitle = ({ title, subTitle = '(Histórico)' }) => {
  return (
    <>
      <h4 className="mb-0 pr-2">{title}</h4>
      <span className="font-1 pr-2">{subTitle}</span>
    </>
  );
};

export default ArrayTitle;
