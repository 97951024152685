import React from 'react';
import { Helmet } from 'react-helmet';
import autobind from 'auto-bind/react';
import { Layout, message, Button, Input } from 'antd';
import deburr from 'lodash/deburr';
import { Parser } from 'json2csv';

import AppContext from '../../context/AppContextBase';
import ClientTable, {
  scopes as clientScopes
} from '../../components/table/ClientTable';
import InsertClient from '../../components/form/InsertClient';
import { fetchClients, postClient } from '../../network/clients';
import g from '../../styles/global';
import { mrg } from '../../common/util';

const { Content } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  content: {
    paddingLeft: g.global.baseline,
    paddingRight: g.global.baseline
  },
  pageHeaderContainer: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexBetween,
    ...g.layout.alignCenter,
    marginBottom: g.global.baseline,
    marginTop: g.global.baseline
  },
  pageHeaderSection: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  pageHeaderBox: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: g.global.baseline
  },
  pageHeaderButton: {
    marginLeft: g.global.baseline,
    ...g.layout.flexVertical,
    ...g.layout.flexCenter,
    ...g.layout.alignCenter
  }
};

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      clients: null,
      showCreateClient: false,
      search: '',
      searching: false
    };

    autobind(this);
  }

  async componentDidMount() {
    await this.refreshClients();
  }

  async search(search) {
    this.setState({ searching: true });
    if (!this.state.clients || this.state.searching || !search) {
      this.setState({ searching: false, clients: this.clientCache });
      return;
    }

    const clients = this.clientCache.filter(client => {
      const matchesSearch =
        deburr(JSON.stringify(Object.values(client)))
          .toLowerCase()
          .indexOf(deburr(search).toLowerCase()) !== -1;
      return matchesSearch;
    });
    this.setState({ clients: clients ?? [], searching: false });
  }

  async refreshClients() {
    let clients = await fetchClients();
    if (clients && clients.result === 'OK' && clients.data.docs.length > 0) {
      for (let client in clients.data.docs) {
        const curr = clients.data.docs[client];
        if (curr && curr.email_contacts)
          curr.email_contacts = curr.email_contacts.replace(',', ', ');
      }
      this.clientCache = clients.data.docs;
      this.setState({
        clients: clients.data.docs
      });
    } else {
      this.clientCache = [];
      this.setState({ clients: [] });
    }
  }

  generateCsv() {
    const fields = [
      { label: 'NIF', value: 'nif', default: 'NULL' },
      { label: 'Notas', value: 'notes', default: 'NULL' },
      { label: 'Data de Criação', value: 'createdAt', default: 'NULL' }
      /*{ label: 'Língua', value: 'language', default: 'NULL' },*/
    ];
    const opts = { fields };

    try {
      const parser = new Parser(opts);
      const csv = parser.parse(this.state.clients);
      console.log(csv);

      var blob = new Blob([csv], { type: 'text/csv' });

      var a = document.createElement('a');
      a.download = 'report.csv';
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() {
        URL.revokeObjectURL(a.href);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const separatorHeight = g.global.baseline;
    return (
      <>
        <Helmet>
          <title>Finpartner - Clientes</title>
        </Helmet>
        <div
          style={{
            backgroundColor: g.colors.background.queenBlue,
            width: '100%',
            height: separatorHeight
          }}
        ></div>
        <Content
          id="clients"
          style={mrg([
            styles.fullWidth,
            g.layout.flexVertical,
            g.layout.flexStart,
            {
              maxHeight: `calc(100% - ${separatorHeight}px)`,
              overflowY: 'hidden'
            },
            styles.content
          ])}
        >
          <div
            style={mrg([
              g.layout.flexVertical,
              g.layout.flexStart,
              g.layout.alignStart,
              styles.fullWidth,
              { height: '100%', overflowY: 'scroll' }
            ])}
          >
            <div style={styles.pageHeaderContainer}>
              <div style={styles.pageHeaderSection}>
                <span style={styles.pageHeaderContent}>Clientes</span>

                {this.context.checkPermission('base:clients:download') && (
                  <Button
                    disabled={
                      this.state.loading ||
                      this.state.searching ||
                      !Array.isArray(this.state.clients)
                    }
                    type="primary"
                    ghost
                    style={styles.pageHeaderButton}
                    onClick={this.generateCsv}
                  >
                    Descarregar Listagem
                  </Button>
                )}
                {this.context.checkPermission('base:clients:create') && (
                  <>
                    {!this.state.showCreateClient && (
                      <Button
                        disabled={
                          this.state.loading ||
                          this.state.searching ||
                          !Array.isArray(this.state.clients)
                        }
                        style={styles.pageHeaderButton}
                        ghost
                        type="primary"
                        onClick={() => {
                          this.setState({ showCreateClient: true });
                        }}
                      >
                        Inserir clientes
                      </Button>
                    )}
                  </>
                )}
              </div>
              <div style={styles.pageHeaderSection}>
                <Input
                  style={{ maxWidth: '20rem' }}
                  placeholder="Pesquisar"
                  onChange={e => {
                    if (!e.target.value) {
                      this.search();
                    }
                  }}
                  disabled={
                    this.state.loading ||
                    this.state.searching ||
                    !Array.isArray(this.state.clients)
                  }
                  // value={this.state.search}
                  onPressEnter={e => this.search(e.target.value)}
                  onBlur={e => this.search(e.target.value)}
                />
              </div>
            </div>
            {this.context.checkPermission('base:clients:create') && (
              <>
                {this.state.showCreateClient && (
                  <InsertClient
                    action={client => postClient(client)}
                    done={() => {
                      this.refreshClients();
                      message.success(<span>Sucesso</span>);
                    }}
                  />
                )}
              </>
            )}
            {this.state.clients && (
              <ClientTable
                scope={clientScopes.DEFAULT}
                clients={this.state.clients}
              />
            )}
          </div>
        </Content>
      </>
    );
  }
}

export default Home;
