import ExcelJS from 'exceljs';
import moment from 'moment';
//'../../../data/processClientDebt';
import { calculateClientDebt } from '../../pages/id/data/processClientDebt';

export async function exportClients(clients, expanded) {
  let workbook = new ExcelJS.Workbook();

  workbook.creator = 'Vicki Finpartner';
  workbook.lastModifiedBy = 'Vicki Finpartner';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible'
    }
  ];

  let worksheet = workbook.addWorksheet('Clientes');

  /*
Agência
Serviço
Valor do serviço
Pago 1º ano p/ CG
Nº Fatura
Data Fatura
Valor Faturado
Nacionalidade
Morada
  */
  worksheet.columns = [
    { header: 'NIF', key: 'nif', width: 10, bgColor: { argb: 'FFFF0000' } },
    { header: 'Faturação Própria', key: 'familiar_com_faturacao', width: 10 },
    { header: 'Passaporte', key: 'passaporte', width: 15 },
    { header: 'Nome', key: 'nome', width: 30 },
    { header: 'Nacionalidade', key: 'nacionalidade', width: 15 },

    {
      header: 'Tipos de Investimento',
      key: 'investment_type',
      width: 30,
      outlineLevel: 1
    },

    { header: 'Dossier.', key: 'dossier', width: 10, outlineLevel: 1 },
    { header: 'Juris', key: 'juris', width: 10, outlineLevel: 1 },
    { header: 'Situação', key: 'situacao', width: 10, outlineLevel: 1 },
    { header: 'Desistências', key: 'dropout', width: 15, outlineLevel: 1 },
    {
      header: 'Data Comunicação Desis.',
      key: 'dropout_communication_date',
      width: 25,
      outlineLevel: 1
    },
    {
      header: 'Data Alteração Desis.',
      key: 'dropout_change_date',
      width: 25,
      outlineLevel: 1
    },

    {
      header: 'Observação Geral',
      key: 'observacao_geral',
      width: 30,
      outlineLevel: 1
    },
    {
      header: 'Data Pedido Nif',
      key: 'nif_data_pedido',
      width: 15,
      outlineLevel: 1
    },
    {
      header: 'Data Obtenção Nif',
      key: 'nif_data_recebido',
      width: 15,
      outlineLevel: 1
    },
    { header: 'Requerente', key: 'applicant', width: 15, outlineLevel: 1 },
    { header: 'Agência', key: 'agency', width: 10, outlineLevel: 1 },
    //more fields requested
    {
      header: 'Valor Serv. Faturado',
      key: 'service_invoiced',
      width: 15,
      outlineLevel: 1
    },

    {
      header: 'Data de contracto',
      key: 'contract_date',
      width: 15,
      outlineLevel: 1
    },
    {
      header: 'Tipo de Faturação',
      key: 'invoicing_type',
      width: 10,
      outlineLevel: 1
    },
    {
      header: 'Mês de Faturação',
      key: 'invoicing_month',
      width: 10,
      outlineLevel: 1
    },
    {
      header: 'Dia de Faturação',
      key: 'invoicing_day',
      width: 10,
      outlineLevel: 1
    },
    {
      header: 'Débito Direto',
      key: 'has_direct_debit',
      width: 5,
      outlineLevel: 1
    },
    {
      header: 'Imposto',
      key: 'tax_type',
      width: 5,
      outlineLevel: 1
    },
    {
      header: 'Banco',
      key: 'bank',
      width: 15,
      outlineLevel: 1
    },
    {
      header: 'IBAN',
      key: 'bank_account_number',
      width: 15,
      outlineLevel: 1
    },
    {
      header: 'Ano Serviço',
      key: 'service_payments',
      width: 15,
      outlineLevel: 1
    },
    { header: 'Montante pago', key: 'value', width: 15, outlineLevel: 1 },
    {
      header: 'Pago 1º ano p/ CG',
      key: 'first_year_paid_by_cg',
      width: 20,
      outlineLevel: 1
    },
    { header: 'Nº Fatura', key: 'invoice_number', width: 10, outlineLevel: 1 },
    { header: 'Data Fatura', key: 'invoice_date', width: 12, outlineLevel: 1 },
    {
      header: 'Valor Recebido',
      key: 'value_received',
      width: 15,
      outlineLevel: 1
    },
    { header: 'Está pago?', key: 'is_paid', width: 10, outlineLevel: 1 },

    { header: 'Saldo', key: 'debt', width: 10, outlineLevel: 1 },

    {
      header: 'Morada',
      key: 'origin_fiscal_residence',
      width: 40,
      outlineLevel: 1
    },
    {
      header: 'Email',
      key: 'email',
      width: 30,
      outlineLevel: 1
    },
    {
      header: 'Contactos - Tel',
      key: 'tel',
      width: 30,
      outlineLevel: 1
    },
    {
      header: 'Contactos - Wechat',
      key: 'wechat',
      width: 30,
      outlineLevel: 1
    }
  ];

  formatColors(worksheet);

  clients.forEach(client => {
    let servicePayments = {
      service_payments: '',
      value: '',
      first_year_paid_by_cg: '',
      invoice_number: '',
      invoice_date: '',
      value_received: '',
      is_paid: '',
      origin_fiscal_residence: ''
    };

    let lastService = {
      serviceInvoiced: '',
      contract_date: ''
    };
    let debt = '';
    //total faturado (in pt)
    if (client.service_payments.length > 0) {
      servicePayments =
        client.service_payments[client.service_payments.length - 1];

      //calculing ammount to be paid:
      debt = calculateClientDebt(client.service_payments);
      debt = (debt.totalPaid - debt.totalInDebt).toFixed(2);
    }

    // let serviceInvoiced = '';
    //total faturado (in pt)
    if (client?.service?.length > 0) {
      lastService.serviceInvoiced =
        client.service?.[client?.service?.length - 1]?.value;

      lastService.contract_date =
        client.service?.[client?.service?.length - 1]?.contract_date;

      lastService.invoicing_type =
        client.service?.[client?.service?.length - 1]?.invoicing_type;
      lastService.invoicing_month =
        client.service?.[client?.service?.length - 1]?.invoicing_month;
      lastService.invoicing_day =
        client.service?.[client?.service?.length - 1]?.invoicing_day;
      lastService.has_direct_debit =
        client.service?.[client?.service?.length - 1]?.has_direct_debit;
      lastService.tax_type =
        client.service?.[client?.service?.length - 1]?.tax_type;

      lastService.bank = client.service?.[client?.service?.length - 1]?.bank;

      lastService.bank_account_number =
        client.service?.[client?.service?.length - 1]?.bank_account_number;
    }

    let row = worksheet.addRow({
      nif: client.main.nif,
      passaporte: client.main.passaporte,
      nome: client.main.nome,
      nacionalidade: client.main.nacionalidade,
      investment_type: client.main.investment_type?.join(', ') ?? '',
      dossier: client.main.dossier,
      juris: client.main.juris,
      dropout: client.dropout.is_dropout ? 'Sim' : 'Não',
      dropout_communication_date: maybePrintMoment(
        client.dropout.communication_date
      ),
      dropout_change_date: maybePrintMoment(client.dropout.change_date),
      situacao: client.main.situacao,
      observacao_geral: client.main.observacao_geral,
      nif_data_pedido: maybePrintMoment(client.main.nif_data_pedido),
      nif_data_recebido: maybePrintMoment(client.main.nif_data_recebido),

      applicant: client.main.applicant,
      agency: client.agency.name,
      service_invoiced: lastService.serviceInvoiced ?? '',
      contract_date: maybePrintMoment(lastService.contract_date) ?? '',
      invoicing_type: !lastService.invoicing_type
        ? ''
        : lastService.invoicing_type === 'yearly'
        ? 'Anual'
        : lastService.invoicing_type === 'monthly'
        ? 'Mensal'
        : lastService.invoicing_type,
      invoicing_month: lastService.invoicing_month ?? '',
      invoicing_day: lastService.invoicing_day ?? '',
      has_direct_debit:
        lastService.has_direct_debit === true
          ? 'Sim'
          : lastService.has_direct_debit === false
          ? 'Não'
          : '',
      tax_type: lastService.tax_type?.length
        ? lastService.tax_type?.join(', ')
        : '',
      bank: lastService.bank ?? '',
      bank_account_number: lastService.bank_account_number ?? '',
      service_payments: maybePrintMoment(servicePayments.service_year, 'YYYY'),
      value: servicePayments.value,
      first_year_paid_by_cg: servicePayments.first_year_paid_by_cg,
      invoice_number: servicePayments.invoice_number,
      invoice_date: maybePrintMoment(servicePayments.invoice_date),
      value_received: servicePayments.value_received,

      is_paid: servicePayments.is_paid ? 'Sim' : 'Não',

      debt: debt,
      origin_fiscal_residence: client.contact.origin_fiscal_residence,
      email: client.contact.email,
      tel: client.contact.telephone,
      wechat: client.contact.wechat
    });

    row.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    row.eachCell(cell => {
      cell.font = {
        bold: true
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE6EFFB' }
      };
    });

    // console.log('client', client, 'expanded', expanded);
    if (client.family?.length > 0) {
      client.family.forEach(client => {
        let servicePayments = {
          service_payments: '',
          value: '',
          first_year_paid_by_cg: '',
          invoice_number: '',
          invoice_date: '',
          value_received: '',
          is_paid: '',
          origin_fiscal_residence: ''
        };

        let lastService = {
          serviceInvoiced: '',
          contract_date: '',
          bank: '',
          bank_account_number: ''
        };

        let debt = '';
        //total faturado (in pt)
        if (client.service_payments.length > 0) {
          servicePayments =
            client.service_payments[client.service_payments.length - 1];

          //calculing ammount to be paid:
          debt = calculateClientDebt(client.service_payments);
          debt = (debt.totalPaid - debt.totalInDebt).toFixed(2);
        }

        //total faturado (in pt)
        if (client.service.length > 0) {
          lastService.serviceInvoiced =
            client.service?.[client?.service?.length - 1]?.value;

          lastService.contract_date =
            client.service?.[client?.service?.length - 1]?.contract_date;

          lastService.invoicing_type =
            client.service?.[client?.service?.length - 1]?.invoicing_type;
          lastService.invoicing_month =
            client.service?.[client?.service?.length - 1]?.invoicing_month;
          lastService.invoicing_day =
            client.service?.[client?.service?.length - 1]?.invoicing_day;
          lastService.has_direct_debit =
            client.service?.[client?.service?.length - 1]?.has_direct_debit;
          lastService.tax_type =
            client.service?.[client?.service?.length - 1]?.tax_type;

          lastService.bank =
            client.service?.[client?.service?.length - 1]?.bank;

          lastService.bank_account_number =
            client.service?.[client?.service?.length - 1]?.bank_account_number;
        }

        let row = worksheet.addRow({
          nif: client.main.nif,
          familiar_com_faturacao: client.main.familiar_com_faturacao
            ? 'Sim'
            : null,
          passaporte: client.main.passaporte,
          nome: client.main.nome,
          nacionalidade: client.main.nacionalidade,
          investment_type: client.main.investment_type?.join(', ') ?? '',
          dossier: client.main.dossier,
          juris: client.main.juris,
          dropout: client.dropout.is_dropout ? 'Sim' : 'Não',
          dropout_communication_date: maybePrintMoment(
            client.dropout.communication_date
          ),
          dropout_change_date: maybePrintMoment(client.dropout.change_date),

          situacao: client.main.situacao,
          observacao_geral: client.main.observacao_geral,
          nif_data_pedido: maybePrintMoment(client.main.nif_data_pedido),
          nif_data_recebido: maybePrintMoment(client.main.nif_data_recebido),
          applicant: client.main.applicant,
          agency: client.agency.name,
          service_invoiced: lastService.serviceInvoiced ?? '',
          contract_date: maybePrintMoment(lastService.contract_date) ?? '',
          invoicing_type: !lastService.invoicing_type
            ? ''
            : lastService.invoicing_type === 'yearly'
            ? 'Anual'
            : lastService.invoicing_type === 'monthly'
            ? 'Mensal'
            : lastService.invoicing_type,
          invoicing_month: lastService.invoicing_month ?? '',
          invoicing_day: lastService.invoicing_day ?? '',
          has_direct_debit:
            lastService.has_direct_debit === true
              ? 'Sim'
              : lastService.has_direct_debit === false
              ? 'Não'
              : '',
          tax_type: lastService.tax_type?.length
            ? lastService.tax_type?.join(', ')
            : '',
          bank: lastService.bank ?? '',
          bank_account_number: lastService.bank_account_number ?? '',
          service_payments: maybePrintMoment(
            servicePayments.service_year,
            'YYYY'
          ),
          value: servicePayments.value,
          first_year_paid_by_cg: servicePayments.first_year_paid_by_cg,
          invoice_number: servicePayments.invoice_number,
          invoice_date: maybePrintMoment(servicePayments.invoice_date),
          value_received: servicePayments.value_received,
          is_paid: servicePayments.is_paid ? 'Sim' : 'Não',

          debt: debt,
          origin_fiscal_residence: client.contact.origin_fiscal_residence,
          email: client.contact.email,
          tel: client.contact.telephone,
          wechat: client.contact.wechat
        });
        row.alignment = {
          vertical: 'middle',
          horizontal: 'left',
          wrapText: true
        };

        row.eachCell(cell => {
          cell.font = {
            bold: false
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFAEED6' }
          };
        });
      });
    }
  });

  return await downloadExcel(workbook);
}

const maybePrintMoment = (date, format = 'DD-MM-YYYY') => {
  if (!date) {
    return '';
  }
  const mmt = moment(date);
  if (mmt.isValid()) {
    return mmt.format(format);
  }
  return date;
};

function formatColors(worksheet) {
  //Paint in gray from A1 to L1
  [
    'A1',
    'B1',
    'C1',
    'D1',
    'E1',
    'F1',
    'G1',
    'H1',
    'I1',
    'J1',
    'K1',
    'L1'
  ].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'cccccc' }
    };
    worksheet.getCell(key).font = {
      size: 12,
      underline: true,
      bold: true
    };
  });
  //create filters
  worksheet.autoFilter = {
    from: 'A1',
    to: 'L1'
  };
  //Paint in *color* after "L1"
  ['M1', 'N1', 'O1', 'P1', 'Q1'].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F4B12C' }
    };
    worksheet.getCell(key).font = {
      size: 12,
      underline: true,
      bold: true
    };
  });

  //Paint in *color* after "L1"
  ['R1', 'S1', 'T1', 'U1', 'V1', 'W1', 'X1', 'Y1', 'Z1'].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2CF46E' }
    };
    worksheet.getCell(key).font = {
      size: 12,
      underline: true,
      bold: true
    };
  });
}

async function downloadExcel(workbook) {
  try {
    let blob = await workbook2Blob(workbook);

    let filename =
      'id_clients_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
    return anchorDownload(blob, filename);
  } catch (err) {
    console.error(err);
    return null;
  }
}

const workbook2Blob = async workbook => {
  let buffer = await workbook.xlsx.writeBuffer();
  let blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
};
const anchorDownload = (blob, filename) => {
  let a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
};
