import React from 'react';

import { Helmet } from 'react-helmet';
import { Input, Radio, Button } from 'antd4';
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  CloseCircleFilled,
  DeleteOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons';
import { isURL } from 'validator';

import './DigitalJourney.scss';

const NODE_DEV = process.env.NODE_ENV === 'development';

const linkPrefix = NODE_DEV
  ? 'http://localhost:4001/meetings'
  : 'https://vicki.finpartner.pt/meetings';

const videoValues = {
  long: 'ebf36a',
  short: 'aa59f1'
};

// https://us04web.zoom.us/j/75083209054?pwd=RW9Sc2R5cXlKcnRoZ3gxTnZCdVl1dz09

const DigitalJourney = () => {
  const [zoomLink, setZoomLink] = React.useState('');
  const [zoomLinkOk, setZoomLinkOk] = React.useState('');
  // const [meetingId, setMeetingId] = React.useState('');
  // const [meetingPassword, setMeetingPassword] = React.useState('');
  // const [meetingUser, setMeetingUser] = React.useState('');
  const [videoCode, setVideoCode] = React.useState('');
  const [newLink, setNewLink] = React.useState('');

  const onChangeLink = event => {
    const value = event.target.value;
    setZoomLink(value);
    if (isURL(value) && value.includes('zoom')) {
      setZoomLinkOk(true);
    } else {
      setZoomLinkOk(false);
    }
  };

  const onChangeVideo = event => {
    const value = event.target.value;
    setVideoCode(value);
  };

  const generateLink = () => {
    if (zoomLinkOk && videoCode) {
      const urlEncodedZoom = encodeURIComponent(zoomLink);
      const newLink = `${linkPrefix}/${urlEncodedZoom}/${videoCode}`;
      setNewLink(newLink);
    }
    // else if (meetingId && meetingPassword && meetingUser) {
    //   const encodedId = encodeURIComponent(meetingId);
    //   const encodedPassword = encodeURIComponent(meetingPassword);
    //   const encodedUser = encodeURIComponent(meetingUser);
    //   const newLink = `${linkPrefix}/embedded/${encodedId}/${encodedPassword}/${encodedUser}/${videoCode}`;
    //   setNewLink(newLink);
    // }
  };

  const clear = () => {
    setZoomLink('');
    setZoomLinkOk(false);
    setVideoCode('');
    setNewLink('');
  };

  return (
    <>
      <Helmet>
        <title>Digital Journey</title>
      </Helmet>

      <div
        id="journey"
        className="w-100 h-100 p-5 d-flex justify-content-start align-items-start"
      >
        <div className="w-50 pl-3 pr-3 d-flex flex-col justify-content-start align-items-start">
          <h3>1. Link de Reunião Zoom</h3>
          {process.env.NODE_ENV === 'development' && (
            <>
              <div>(e.g.)</div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setZoomLink(
                    'https://us04web.zoom.us/j/75083209054?pwd=RW9Sc2R5cXlKcnRoZ3gxTnZCdVl1dz09'
                  );
                  setZoomLinkOk(true);
                }}
              >
                https://us04web.zoom.us/j/75083209054?pwd=RW9Sc2R5cXlKcnRoZ3gxTnZCdVl1dz09
              </div>
            </>
          )}
          <div className="w-100 d-flex justify-content-start align-items-center mb-5">
            <Input
              allowClear
              value={zoomLink}
              className="mr-3 "
              disabled={!!newLink}
              onChange={onChangeLink}
              placeholder="Link Zoom"
            />
            {zoomLinkOk ? (
              <CheckOutlined></CheckOutlined>
            ) : (
              <ExclamationCircleOutlined
                style={{ color: 'red' }}
              ></ExclamationCircleOutlined>
            )}
          </div>
          {/* <div className="w-100 d-flex justify-content-start align-items-center mb-5">
            <Input
              allowClear
              value={meetingId}
              className="mr-3 "
              disabled={!!newLink}
              onChange={event => setMeetingId(event.target.value)}
              placeholder="ID Reunião Zoom"
            />
            {meetingId ? (
              <CheckOutlined></CheckOutlined>
            ) : (
              <ExclamationCircleOutlined
                style={{ color: 'red' }}
              ></ExclamationCircleOutlined>
            )}
          </div>
          <div className="w-100 d-flex justify-content-start align-items-center mb-5">
            <Input
              allowClear
              value={meetingPassword}
              className="mr-3 "
              disabled={!!newLink}
              onChange={event => setMeetingPassword(event.target.value)}
              placeholder="Password Reunião Zoom"
            />
            {meetingPassword ? (
              <CheckOutlined></CheckOutlined>
            ) : (
              <ExclamationCircleOutlined
                style={{ color: 'red' }}
              ></ExclamationCircleOutlined>
            )}
          </div>
          <div className="w-100 d-flex justify-content-start align-items-center mb-5">
            <Input
              allowClear
              value={meetingUser}
              className="mr-3 "
              disabled={!!newLink}
              onChange={event => setMeetingUser(event.target.value)}
              placeholder="Nome de utilizador"
            />
            {zoomLinkOk ? (
              <CheckOutlined></CheckOutlined>
            ) : (
              <ExclamationCircleOutlined
                style={{ color: 'red' }}
              ></ExclamationCircleOutlined>
            )}
          </div> */}
          <h3>2. Escolha de vídeo</h3>
          <div className="w-100 d-flex justify-content-start align-items-center mb-5">
            <div className="w-100 d-flex align-items-center">
              <Radio.Group
                disabled={!!newLink}
                className="w-100"
                onChange={onChangeVideo}
                value={videoCode}
              >
                <Radio value={videoValues.long}>Vídeo de Boas-vindas</Radio>
                <Radio value={videoValues.short}>Vídeo Resumido</Radio>
              </Radio.Group>
              <CloseCircleFilled
                onClick={() => {
                  setVideoCode('');
                }}
                style={{
                  color: 'rgba(0, 0, 0, 0.25)',
                  fontSize: 12,
                  paddingRight: 24
                }}
              />
            </div>
            {videoCode ? (
              <CheckOutlined></CheckOutlined>
            ) : (
              <ExclamationCircleOutlined
                style={{ color: 'red' }}
              ></ExclamationCircleOutlined>
            )}
          </div>
          <h3>3. Geração de link final</h3>
          <div className="w-100 d-flex justify-content-start align-items-center">
            <Button
              type="primary"
              className="mr-3"
              onClick={generateLink}
              disabled={
                !(
                  (videoCode && zoomLinkOk)
                  // || (videoCode && meetingId && meetingPassword && meetingUser)
                )
              }
            >
              <CloudDownloadOutlined className="mr-3" />
              Gerar Link
            </Button>
            <Button
              type="danger"
              onClick={clear}
              disabled={!zoomLink && !videoCode}
            >
              <DeleteOutlined className="mr-3" />
              Limpar valores
            </Button>
          </div>
        </div>
        <div className="w-50 pl-5 pr-3 d-flex flex-col justify-content-start align-items-start">
          <h3>Link para Reunião</h3>
          {// (
          videoCode && zoomLinkOk && newLink && (
            // || (videoCode && meetingId && meetingPassword && meetingUser))
            <>
              <div
                style={{ backgroundColor: '#fafafa', wordBreak: 'break-all' }}
                className="w-100 p-3 d-flex justify-content-start align-items-center border-radius border-lightgray mb-3"
              >
                {newLink}
              </div>
              <h2 className="w-100 pl-3 p-1 pb-0">
                <b>Guarde este link!</b>
              </h2>
              <div className="w-100 p-3">
                O link não será gravado na base de dados Vicki. Em caso de perda
                proceder novamente à sua geração, com o mesmo link de reunião
                Zoom.
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default DigitalJourney;
