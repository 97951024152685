import React from 'react';
import { Auth } from 'aws-amplify';
import { Drawer, Avatar, Icon, Divider, Col, Row, Button } from 'antd';
import globalStyles from '../styles/global';

import Loading from './Loading';

const styles = {
  container: {
    height: '100%',
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter,
    alignItems: 'center'
  },
  row: { marginBottom: globalStyles.global.baseline * 2 },

  avatar: {
    i: {
      margin: 0
    }
  },
  subHeading: {
    fontWeight: 300,
    fontSize: globalStyles.global.baseline * 3
  },
  header: {
    info: {
      fontWeight: 300,
      fontSize: globalStyles.global.baseline * 2
    },
    light: {
      color: globalStyles.colors.background.caiadoBlue,
      fontWeight: 300,
      fontSize: globalStyles.global.baseline * 2
    }
  },
  info: {
    fontWeight: 300,
    fontSize: globalStyles.global.baseline * 1.5
  },
  light: {
    color: globalStyles.colors.background.caiadoBlue,
    fontWeight: 300,
    fontSize: globalStyles.global.baseline * 1.5
  },
  button: {
    width: '100%'
  }
};

export default class Profile extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, visible: props.visible };
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <>
        <style type="text/css">{`
        #menu-avatar i.anticon {
            margin: 0;
            font-size: 18px;
            vertical-align: 0;
        }
      }`}</style>
        <div style={styles.container}>
          <Icon
            style={{
              fontSize: globalStyles.global.baseline * 1.5
            }}
            type="user"
            onClick={() =>
              this.props.handleClick !== false ? this.showDrawer() : null
            }
          />
        </div>
        <Drawer
          mask={true}
          maskClosable={true}
          width={this.props.drawerWidth ? this.props.drawerWidth : '50vw'}
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          {this.props.user ? (
            <>
              <Row style={styles.row}>
                <Col span={24}>
                  <Avatar
                    size={globalStyles.global.baseline * 8}
                    shape="square"
                    icon="user"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={styles.header.info}>{this.props.user.name}</span>
                </Col>
                <Col span={24}>
                  <span style={styles.header.light}>
                    {this.props.user.email}
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row style={styles.row}>
                <Col span={24}>
                  <span style={styles.info}>Role: </span>
                  <span style={styles.light}>{this.props.user.role}</span>
                </Col>
                <Col span={24}>
                  <span style={styles.info}>Department: </span>
                  <span style={styles.light}>
                    {this.props.user.permissions.department}
                  </span>
                </Col>
                {/* <Col span={24}>
                  <span style={styles.info}>Scope: </span>
                  <span style={styles.light}>
                    {this.props.user.permissions.scope === 'external'
                      ? 'Partner'
                      : this.props.user.permissions.scope}
                  </span>
                </Col> */}
              </Row>
              <Row type="flex" justify="start">
                <Col span={6}>
                  <Button
                    style={styles.button}
                    onClick={() => {
                      return Auth.signOut()
                        .then(() => {})
                        .catch(err => console.log(err));
                    }}
                  >
                    Logout
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Loading />
          )}
        </Drawer>
      </>
    );
  }
}
