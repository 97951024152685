import { address } from '../common/variables';
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

const BE_ADDR = address;

export async function fetchSystemState() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/system-state', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
export async function downloadReportZip() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/download-zip', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  console.log(response);

  response.blob().then(blob => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'reportZip.zip';
    a.click();
  });

  return response;
}

export async function setSystemState(stateName) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/system-state', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ current_state: stateName })
  });
  return await handleResponse(response);
}

export async function fetchConsistency() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/consistency', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function uploadMissingFiles(files) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  // Fake form submission
  const data = new FormData();
  data.append('files', files);

  const response = await fetch(BE_ADDR + 'clients/consistency/upload-many', {
    method: 'POST',
    headers: {
      // Accept: 'application/json',
      /* Explicitly omit content-type header, otherwise the browser sets the wrong boundary
       * https://stackoverflow.com/questions/35192841/fetch-post-with-multipart-form-data#comment91367674_40714217
       */
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: data
  });
  return await handleResponse(response);
}

export async function consistencyRecheck() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/consistency/recheck', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function fetchReport() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/report', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function sendEmails() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(BE_ADDR + 'clients/send', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function reset() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/reset', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
