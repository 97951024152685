import React from 'react';

import { Drawer, Button, Tooltip } from 'antd4';
import { FilterOutlined, ClearOutlined } from '@ant-design/icons';

import '../../../fields/Fields.scss';

const ArrayFilters = ({
  filters = [],
  dataCache,
  onFilter,
  onClear = () => onFilter(dataCache),
  showClear = true,
  isFiltered
}) => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const buttonGhostState =
    isFiltered === true ? { ghost: false } : { ghost: true }; // isFiltered might be undefined. Use ===
  return (
    <div className="d-flex justify-content-start align-items-center">
      <Tooltip title={'Filtrar'}>
        <Button
          className="add-relative mr-3"
          size="small"
          type="primary"
          {...buttonGhostState}
          icon={<FilterOutlined></FilterOutlined>}
          shape="circle"
          onClick={() => setShowDrawer(true)}
        ></Button>
      </Tooltip>
      {showClear && (
        <Tooltip title={'Limpar Filtros'}>
          <Button
            className="add-relative mr-3"
            size="small"
            type="default"
            icon={<ClearOutlined></ClearOutlined>}
            shape="circle"
            onClick={() => {
              setShowDrawer(false);
              onClear();
            }}
          ></Button>
        </Tooltip>
      )}
      {showDrawer && (
        <Drawer
          width={'33%'}
          title="Filtrar Lista"
          placement="right"
          closable={true}
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}
        >
          <div className="d-flex flex-col justify-content-start align-items-start h-100 w-100">
            {filters}
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default ArrayFilters;
