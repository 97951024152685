import React from 'react';

import '../../../fields/Fields.scss';
import {
  ArraySection,
  ArrayTitle,
  ActionAddArrayElement,
  ActionDeleteArrayElement
} from '../array';

import { Field, DateField, NumberField, TextAreaField } from '../../../fields';

import '../../../fields/Fields.scss';

const dataPath = 'lease';

let dataCache; //eslint-disable-line no-unused-vars

const Leases = ({ data, addArrayElement, deleteArrayElement, updateField }) => {
  const [filteredData, setFilteredData] = React.useState(data);
  dataCache = data;

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    setFilteredData(data);
    dataCache = data;
  }, [data]);

  return (
    <ArraySection
      data={filteredData}
      dataPath={dataPath}
      deleteArrayElement={deleteArrayElement}
      updateField={updateField}
      renderToolbar={props => {
        return (
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-start align-items-center">
              <ArrayTitle title="Contratos de Arrendamento"></ArrayTitle>
            </div>
            <div className="d-flex justify-content-end align-items-center fg-1">
              <ActionAddArrayElement
                onClick={() => addArrayElement(dataPath)}
              ></ActionAddArrayElement>
            </div>
          </div>
        );
      }}
    >
      <SingleLease></SingleLease>
    </ArraySection>
  );
};

export default Leases;

const SingleLease = ({ data, dataIndex, updateField, deleteArrayElement }) => {
  const element = data?.[dataPath]?.[dataIndex] ?? {};
  return (
    <div
      key={'' + dataPath + dataIndex}
      className={`fields-container array-item array-item-${
        dataIndex % 2 === 0 ? 'even' : 'odd'
      } w-100 border-radius`}
    >
      <div className="array-index d-flex justify-content-start w-100">
        <span className="d-flex justify-content-center align-items-center border-radius-50 mr-3">
          {dataIndex}
        </span>
        <ActionDeleteArrayElement
          onClick={() => deleteArrayElement(dataPath, element?._id)}
        ></ActionDeleteArrayElement>
      </div>
      <DateField
        showTime={false}
        key={'contract_date'}
        label={'Data Contrato'}
        path={`lease.${dataIndex}.contract_date`}
        data={data}
        updateField={updateField}
      ></DateField>
      <Field
        key={'fraction'}
        label={'Fração'}
        path={`lease.${dataIndex}.fraction`}
        data={data}
        updateField={updateField}
      ></Field>
      <NumberField
        key={'monthly_rent'}
        label={'Renda Mensal'}
        path={`lease.${dataIndex}.monthly_rent`}
        data={data}
        updateField={updateField}
      ></NumberField>
      <DateField
        showTime={false}
        key={'last_receipt'}
        label={'Último Recibo'}
        path={`lease.${dataIndex}.last_receipt`}
        data={data}
        updateField={updateField}
      ></DateField>
      <Field
        key={'tenant'}
        label={'Inquilino'}
        width={75}
        path={`lease.${dataIndex}.tenant`}
        data={data}
        updateField={updateField}
      ></Field>
      <Field
        key={'tenant_contact'}
        width={75}
        label={'Contacto Inquilino'}
        path={`lease.${dataIndex}.tenant_contact`}
        data={data}
        updateField={updateField}
      ></Field>
      <TextAreaField
        key={'observation'}
        label={'Observação'}
        width={100}
        path={`lease.${dataIndex}.observation`}
        data={data}
        updateField={updateField}
      ></TextAreaField>
    </div>
  );
};
