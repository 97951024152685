import { address } from '../../../common/variables';
import { handleResponse, authenticate } from './common';

const BE_ADDR = address;

const postBulkServicePayments = async servicePaymentsArray => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  console.log('REQ: ' + servicePaymentsArray);

  const response = await fetch(BE_ADDR + 'id/bulkservicepayments', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(servicePaymentsArray)
  });
  return await handleResponse(response);
};

export { postBulkServicePayments };
