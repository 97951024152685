import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Table, Modal, message, Icon, Input, Button, Tooltip } from 'antd';
import merge from 'lodash.merge';
import op from 'object-path';

import AppContext from '../../context/AppContextBase';

import { textSorter } from '../../common/table';
import { getFullToc, updateToc, removeToc } from '../../network/tocs';

import g from '../../styles/global';
import ConfirmLogin from '../form/ConfirmLogin';
import ConfirmDelete from '../util/ConfirmDelete';
import EditableField from '../util/EditableField';
import DetailedMessage from '../util/DetailedMessage';

const styles = {
  pageHeaderBox: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: g.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  table: {
    width: '100%',
    fontSize: g.global.baseline * 1.2,
    fontWeight: 100,
    border: '1px solid #e8e8e8'
  },
  toc: {
    title: {
      marginRight: g.global.baseline * 0.5,
      fontSize: g.global.baseline * 1.6,
      fontWeight: 700,
      color: '#888888',
      width: '100%'
    }
  },
  modal: {
    body: {
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  },
  actions: {
    container: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter,
      width: '100%'
    },
    action: {
      width: '32px',
      ...g.layout.flexHorizontal,
      ...g.layout.flexCenter,
      marginRight: g.global.baseline,
      fontSize: g.global.baseline * 1.2
    }
  }
};

let searchInput;

const getColumnSearchProps = (
  dataIndex,
  handleSearch,
  handleReset,
  extraInfo = []
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      {extraInfo && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {extraInfo.map(info => (
            <code
              key={info}
              style={{
                color: '#aaa',
                padding: '3px'
              }}
            >
              {info}
            </code>
          ))}
        </div>
      )}
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Pesquisar
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <Icon
      type="search"
      style={{ color: filtered ? 'rgb(255, 77, 91)' : undefined }}
    />
  ),
  onFilter: (value, record) =>
    op.get(record, dataIndex)
      ? op
          .get(record, dataIndex)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : false,
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  }
});

const hasBlanks = value => {
  console.log(!value);
  console.log(value.trim().includes(' '));
  return !value || value.trim().includes(' ');
};

const isOwner = (user, toc) => {
  try {
    return user.id === toc.owner.id;
  } catch (err) {
    return false;
  }
};

export default class TocTable extends React.Component {
  static contextType = AppContext;

  getColumns() {
    const isDev = this.context.state.userData.role === 'dev';
    let columns = [];
    if (isDev) {
      columns = [
        {
          title: '[Dev] Owner id',
          dataIndex: 'owner',
          key: 'owner.id',
          width: '25%',
          render: (obj, record) => ({
            props: { colSpan: 1 },
            children: <div>{obj?.id}</div>
          }),
          sorter: textSorter('owner.id'),
          ...getColumnSearchProps(
            'owner.id',
            this.handleSearch,
            this.handleReset
          )
        }
      ];
    }

    columns = columns.concat([
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        width: isDev ? '25%' : '50%',
        render: (obj, record) => ({
          props: { colSpan: 1 },
          children: <div>{obj}</div>
        }),
        sorter: textSorter('name'),
        ...getColumnSearchProps('name', this.handleSearch, this.handleReset)
      },
      {
        title: 'NIF',
        dataIndex: 'nif',
        key: 'nif',
        width: '25%',
        render: (obj, record) => ({
          props: { colSpan: 1 },
          children: <div>{obj}</div>
        }),
        sorter: textSorter('nif'),
        ...getColumnSearchProps('nif', this.handleSearch, this.handleReset)
      },

      {
        title: 'Acções',
        key: 'Acções',
        width: '25%',
        render: (text, record) => ({
          props: { colSpan: 1 },
          children: (
            <div style={styles.actions.container}>
              {this.context.checkPermission('base:tocs:edit') && (
                <Tooltip title={'Editar'}>
                  <Button
                    style={styles.actions.action}
                    disabled={
                      record.editable ||
                      record.saving ||
                      !isOwner(this.context.state.userData, record)
                    }
                    type="primary"
                    icon="lock"
                    ghost
                    onClick={async () => {
                      return await this.setEditToc(record._id);
                    }}
                  ></Button>
                </Tooltip>
              )}
              {this.context.checkPermission('base:tocs:remove') && (
                <>
                  {this.state.deleteUserId === record._id ? (
                    <div style={styles.actions.container}>
                      <ConfirmDelete
                        deleteLabel="Apagar"
                        confirmLabel="Confirmar!"
                        isConfirm={true}
                        disabled={false}
                        onDelete={async () => await this.remove(record._id)}
                      ></ConfirmDelete>
                      <Button
                        style={{
                          width: 'auto',
                          marginLeft: g.global.baseline
                        }}
                        disabled={record.editable || record.saving}
                        type="secondary"
                        onClick={() => {
                          this.setState({ deleteUserId: null });
                        }}
                      >
                        Cancelar
                      </Button>
                    </div>
                  ) : (
                    <Tooltip title={'Apagar'}>
                      <Button
                        style={styles.actions.action}
                        disabled={
                          record.editable ||
                          record.saving ||
                          !isOwner(this.context.state.userData, record)
                        }
                        icon="delete"
                        ghost
                        type="danger"
                        onClick={() => {
                          this.setState({ deleteUserId: record._id });
                        }}
                      ></Button>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          )
        })
      }
    ]);

    return columns;
  }

  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.remove = this.remove.bind(this);
    this.confirmReveal = this.setEditToc.bind(this);

    this.state = {
      data: props.data ?? [],
      revealData: null
    };

    this.cacheData = JSON.parse(JSON.stringify(props.data ?? []));
    this.state.cacheInit = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevState.data) {
      this.setState({ data: this.props.data ?? [] });
      if (!this.state.cacheInit) {
        this.cacheData = JSON.parse(JSON.stringify(this.props.data ?? []));
        if (this.cacheData && this.cacheData.length) {
          this.setState({ cacheInit: true });
        }
      }
    }
    if (prevState.searchText !== this.state.searchText) {
      this.setState({
        columns: this.getColumns()
      });
    }
  }
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleChange(value, _id, column) {
    const newData = this.state.data;
    const target = newData.filter(item => _id === item._id)[0];
    if (target) {
      target[column] = value;
      this.setState({ data: newData });
    }
  }

  edit(_id) {
    const newData = [...this.state.data];
    const target = newData.filter(item => _id === item._id)[0];
    if (target) {
      target.editable = true;
      let old = this.cacheData.filter(item => _id === item._id)[0];
      if (!old) {
        this.cacheData.push(JSON.parse(JSON.stringify(target)));
      } else {
        Object.assign(old, JSON.parse(JSON.stringify(target)));
      }

      this.setState({ data: newData });
    }
  }
  async save(_id) {
    this.setState(async state => {
      const newData = [...state.data];
      const target = newData.filter(item => _id === item._id)[0];
      if (target) {
        for (let field in target) {
          if (!(typeof target[field] === 'string')) {
            continue;
          }
        }
        target.saving = true;
        target.editable = false;

        const response = await updateToc(target._id, target, this.props.scope);

        if (response?.result === 'OK') {
          target.edited = true;
          this.cacheData = JSON.parse(JSON.stringify(newData));
          message.success('Alterações Guardadas', 3);
          if (this.props.onEdit) {
            this.props.onEdit();
          }
          target.saving = false;
          return { data: newData };
        } else {
          DetailedMessage.error(
            'Ocorreu um erro, as alterações não foram guardadas!',
            response
          );
          target.saving = false;
          return {};
        }
      }
    });
  }
  cancel(_id) {
    this.setState(state => {
      const newData = JSON.parse(JSON.stringify(state.data));
      let target = newData.filter(item => _id === item._id)[0];
      if (target) {
        const old = this.cacheData.filter(item => _id === item._id)[0];
        if (old) {
          Object.assign(target, old);

          target.info_to_send = old.info_to_send;
          target.send_method = old.send_method;

          target.editable = false;
          delete target.edited;
          delete target.saving;
          return { data: newData };
        }
      }
    });
  }

  async setEditToc(key) {
    this.setState({ editTocId: key });
  }

  async saveTocData(_id, data) {
    const response = await updateToc(_id, data, this.props.scope);

    if (response?.result === 'OK') {
      this.setState(state => {
        let newData = [...state.data];
        const target = newData?.find?.(toc => toc._id === _id);
        merge(target, data);
        this.cacheData = JSON.parse(JSON.stringify(newData));
        return { data: newData };
      });
      message.success('Alterações Guardadas', 3);
    } else {
      DetailedMessage.error('Erro.', response);
    }
  }

  async remove(_id) {
    const response = await removeToc(_id);

    if (response?.result === 'OK') {
      this.setState(state => {
        let newData = [...state.data];
        newData = newData.filter(toc => toc._id !== _id);
        this.cacheData = JSON.parse(JSON.stringify(newData));
        return { data: newData, deleteUserId: null };
      });
      message.success('Alterações Guardadas', 3);
    } else {
      DetailedMessage.error(
        'Ocorreu um erro, as alterações não foram guardadas! Verifique a sua ligação à internet e/ou permissões de acesso.',
        response
      );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          {/* antd overrides - I should get shot for this */}
          <style type="text/css">{`.ant-table-thead > tr > th {
              padding: ${g.global.baseline}px;
            }
            tr.ant-table-row td {
              padding: ${g.global.baseline}px;
              line-height: 1;
            }
            .ant-table-tbody > tr.error {
              background-color: ${g.colors.foreground.error}
            }

            .ant-table-tbody > tr.edited {
              background-color: ${g.colors.foreground.edited}
            }
            .ant-table-tbody > tr.warning {
              background-color: ${g.colors.foreground.warning}
            }
            .ant-table-tbody > tr.success {
              background-color: ${g.colors.foreground.success}
            }
          `}</style>
        </Helmet>
        <Table
          loading={!this.state.data}
          style={styles.table}
          rowKey={record => record._id}
          columns={this.getColumns()}
          dataSource={this.state.data}
          rowClassName={(record, index) => {
            if (record.edited) {
              return 'edited';
            } else if (record.has_errors) {
              return 'error';
            } else if (record.warning) {
              return 'warning';
            } else if (record.success) {
              return 'success';
            }
            return '';
          }}
          pagination={{
            position: 'both',
            showSizeChanger: true,
            total: this.state.data.length || 0,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
            defaultPageSize: 100,
            onShowSizeChange: (current, size) => {
              //TODO: save page size change on user settings
            }
          }}
        />

        {this.state.editTocId && (
          <Modal
            width="40vw"
            visible
            bodyStyle={styles.modal.body}
            closeable
            onCancel={() => {
              this.setState({ revealData: null, editTocId: null });
            }}
            footer={null}
          >
            {this.state.revealData ? (
              <Row
                style={{
                  width: '100%',
                  padding: g.global.baseline * 2
                }}
              >
                <div
                  style={{
                    width: '100%',
                    paddingBottom: g.global.baseline
                  }}
                >
                  Editar TOC
                </div>
                {this.context?.state?.userData?.role === 'dev' && (
                  <EditableField
                    span={24}
                    editable
                    title="Owner Id"
                    value={this.state.revealData?.owner?.id}
                    onSave={async value => {
                      if (hasBlanks(value)) {
                        throw new Error('Caracteres de espaço não permitidos');
                      }
                      return await this.saveTocData(this.state.editTocId, {
                        owner: { id: value }
                      });
                    }}
                    onSaveSuccess={() => {}}
                    onSaveError={async err => {
                      DetailedMessage.error('Error: ' + err.message, err);
                      return;
                    }}
                  />
                )}
                <EditableField
                  span={24}
                  editable
                  title="Name"
                  value={this.state.revealData?.name}
                  onSave={async value => {
                    if (hasBlanks(value)) {
                      console.log(value, hasBlanks(value));
                      throw new Error('Caracteres de espaço não permitidos');
                    }
                    return await this.saveTocData(this.state.editTocId, {
                      name: value
                    });
                  }}
                  onSaveSuccess={() => {}}
                  onSaveError={async err => {
                    DetailedMessage.error('Error: ' + err.message, err);
                    return;
                  }}
                />
                <EditableField
                  span={24}
                  editable
                  title="Username"
                  value={this.state.revealData?.at?.username}
                  onSave={async value => {
                    if (hasBlanks(value)) {
                      throw new Error('Caracteres de espaço não permitidos');
                    }
                    return await this.saveTocData(this.state.editTocId, {
                      nif: value,
                      at: {
                        username: value
                      }
                    });
                  }}
                  onSaveSuccess={() => {}}
                  onSaveError={async err => {
                    DetailedMessage.error('Error: ' + err.message, err);
                    return;
                  }}
                />
                <EditableField
                  span={24}
                  editable
                  title="Password"
                  value={this.state.revealData?.at?.password}
                  onSave={async value => {
                    if (hasBlanks(value)) {
                      throw new Error('Caracteres de espaço não permitidos');
                    }
                    return await this.saveTocData(this.state.editTocId, {
                      at: {
                        password: value
                      }
                    });
                  }}
                  onSaveSuccess={() => {}}
                  onSaveError={async err => {
                    DetailedMessage.error('Error: ' + err.message, err);
                    return;
                  }}
                />
              </Row>
            ) : (
              <ConfirmLogin
                username={this.context.state.userData.email}
                success={async user => {
                  const response = await getFullToc(this.state.editTocId);
                  if (response?.result === 'OK') {
                    this.setState({ revealData: response.data });
                  } else {
                    delete response?.data?.at; // delete sensitive data before message
                    DetailedMessage.error(
                      'Erro a obter dados do servidor',
                      response
                    );
                    this.setState({ revealData: null, editTocId: null });
                    return;
                  }
                }}
                error={async e => {
                  DetailedMessage.error('Erro na autenticação', e);
                  return;
                }}
              />
            )}
          </Modal>
        )}
      </>
    );
  }
}

TocTable.contextType = AppContext;
