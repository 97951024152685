import { address } from '../common/variables';
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

import { scopes } from '../components/table/ClientTable';

//eslint-disable-next-line
const BE_ADDR = address;

export async function fetchClients() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postClient(client) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(client)
  });
  return await handleResponse(response);
}

export async function updateClient(client_id, data, scope = scopes.DEFAULT) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const path = scope === scopes.DEFAULT ? '' : 'consistency/';
  const endpoint = BE_ADDR + 'clients/' + path + client_id;

  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function removeClient(client_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const endpoint = BE_ADDR + 'clients/' + client_id;

  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function getSecrets(nif) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'clients/full/' + nif, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
