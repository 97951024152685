let numberErrorEmitted = false;

const parseCurrency = currency => {
  let toConvert = currency;
  if (toConvert?.trim) {
    toConvert = toConvert.trim();
  }

  if (toConvert?.replace) {
    toConvert = toConvert.replace(/( | )/g, ''); //eslint-disable-line
  }

  // const result = Number(currency.replace(/[^0-9.-]+/g, ''));
  const result = Number(toConvert);
  if (isNaN(result)) {
    let errorMessage = `Valor inválido: ${toConvert}.`;
    if (!numberErrorEmitted) {
      errorMessage += ` Células numéricas apenas devem conter dígitos e opcionalmente um ponto (.) . Não são permitidos espaços, vírgulas, símbolos de moeda ou outros caracteres.`;
      numberErrorEmitted = true;
    }
    throw new Error(errorMessage);
  }
  if (toConvert) {
    console.log(`currency/result: |${toConvert}|${result}|`);
  }
  return result;
};

// eslint-disable-next-line
const incomeCodeRegexes = {
  '4A': /^A0[1-3]$/,
  '5A': /^H0[1-5]$/,
  '6A': /^B[01][0-9]$/,
  '7A': /^F01$/,
  '8A': /^(E01|E10|E11|E20|E21|E22|E23|E24|E99)$/,
  '91B': /^.*$/,
  '92B': /^.*$/
};

const cleanIncomeCode = (incomeCode, tableNumber) => {
  //fuck
  return incomeCode;

  // const trimmed = incomeCode.trim?.();
  // const trimmedTable = tableNumber.trim?.();
  // const regex = incomeCodeRegexes[trimmedTable];
  // console.log({ trimmed, regex, test: regex.test(trimmed) });
  // if (!trimmed || !regex || !regex.test(trimmed)) {
  //   throw new Error('Código de rendimento inválido: ' + incomeCode);
  // }
  // return trimmed;
};

export { parseCurrency, cleanIncomeCode };
