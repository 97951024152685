import React from 'react';

import './Fields.scss';

const Placeholder = ({ width }) => {
  return (
    <div
      key={'placeholder'}
      className={`input-container w-${width ?? '25'}`}
    ></div>
  );
};

export default Placeholder;
