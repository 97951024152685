export function getClientsWithDebt(clients) {
  //console.log('clients received', clients);

  let listOfClientsWithDebt = [];

  clients.forEach(client => {
    let debt = calculateClientDebt(client.service_payments);

    let accounting = debt.totalPaid - debt.totalInDebt;

    if (accounting < 0) {
      client.totalOwned = accounting.toFixed(2);
      listOfClientsWithDebt.push(client);
    }
  });
  // console.log('Clients with debt', listOfClientsWithDebt);

  return listOfClientsWithDebt;
}

export function calculateClientDebt(service_payments) {
  // console.log(
  //   'service_payments',
  //   service_payments,
  //   Array.isArray(service_payments)
  // );
  let totalInDebt = 0;
  let totalPaid = 0;

  if (service_payments && service_payments[0]) {
    service_payments.forEach(invoice => {
      totalPaid += Number(invoice.value_received);
      totalInDebt += Number(invoice.value);
    });
    // console.log('totalPaid', totalPaid);
    // console.log('totalInDebt', totalInDebt);
    return { totalPaid, totalInDebt };
  } else {
    // console.log('No valid service payment invoices found.');
    return { totalPaid, totalInDebt };
  }
}
