import React, { useState } from 'react';
import { Route, NavLink, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import { Row, Col, Layout, Menu, Icon } from 'antd';

import AppContext from '../../context/AppContextBase';
import Saft from './saft/Saft';
import Dmr from './dmr/Dmr';
import DmrSS from './dmrss/DmrSS';
import Iva from './iva/Iva';
import Irs from './irs/Irs';

import breakpoints from '../../styles/breakpoints';
import g from '../../styles/global';

const { Header, Content } = Layout;

const styles = {
  header: {
    height: g.global.baseline * 4,
    backgroundColor: g.colors.background.queenBlue,
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    padding: 0
  },
  menu: {
    fontSize: g.typography.size.base * 1.5,
    height: g.global.baseline * 3,
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: g.colors.background.queenBlue,
    borderBottom: '1px solid ' + g.colors.background.queenBlue,
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart
  },
  subMenu: {
    height: 48,
    backgroundColor: g.colors.background.queenBlue
  },
  menuItem: {
    fontSize: g.typography.size.base * 1.5,
    fontWeight: 100,
    minWidth: '20%'
  },
  subMenuItem: {
    fontSize: g.typography.size.base * 1.5,
    fontWeight: 100
  },
  menuLink: {
    color: '#fff'
  },
  menuIcon: {
    fontSize: g.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + g.global.baseline + 'px'
  }
};
class FinMenu extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props);
    this.state = { collapsed: true, currentMenuItem: props.location?.pathname };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.setState({ currentMenuItem: this.props.location?.pathname });
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <Menu
        onClick={this.handleClick}
        activeKey={this.state.currentMenuItem}
        mode="horizontal"
        style={styles.menu}
        overflowedIndicator={
          <div style={{ borderBottom: 'unset' }}>
            <Icon style={g.global.menuIcon} type="more" />
          </div>
        }
      >
        {this.context.checkPermission('menu:send:saft') && (
          <Menu.Item
            className="font-thin min-w-1/6 h-full text-xl flex justify-center items-center"
            key="/delivery/saft"
          >
            <NavLink
              location={this.props.location}
              style={styles.menuLink}
              onClick={() =>
                this.setState({ currentMenuItem: '/delivery/saft' })
              }
              to={'/delivery/saft'}
            >
              <MediaQuery minWidth={breakpoints.lgMin}>
                <Icon style={styles.menuIcon} type="calculator" />
                SAF-T
              </MediaQuery>
              <MediaQuery maxWidth={breakpoints.mdMax}>
                <Icon style={styles.menuIcon} type="calculator" />
              </MediaQuery>
            </NavLink>
          </Menu.Item>
        )}
        {this.context.checkPermission('menu:send:dmr') && (
          <Menu.Item
            className="font-thin min-w-1/6 h-full text-xl flex justify-center items-center"
            key="/delivery/dmr"
          >
            <NavLink
              location={this.props.location}
              style={styles.menuLink}
              onClick={() =>
                this.setState({ currentMenuItem: '/delivery/dmr' })
              }
              to={'/delivery/dmr'}
            >
              <MediaQuery minWidth={breakpoints.lgMin}>
                <Icon style={styles.menuIcon} type="android" />
                DMR AT
              </MediaQuery>
              <MediaQuery maxWidth={breakpoints.mdMax}>
                <Icon style={styles.menuIcon} type="android" />
              </MediaQuery>
            </NavLink>
          </Menu.Item>
        )}
        {this.context.checkPermission('menu:send:dmrss') && (
          <Menu.Item
            className="font-thin min-w-1/6 h-full text-xl flex justify-center items-center"
            key="/delivery/dmrss"
          >
            <NavLink
              location={this.props.location}
              style={styles.menuLink}
              onClick={() =>
                this.setState({ currentMenuItem: '/delivery/dmrss' })
              }
              to={'/delivery/dmrss'}
            >
              <MediaQuery minWidth={breakpoints.lgMin}>
                <Icon style={styles.menuIcon} type="android" />
                DMR SS
              </MediaQuery>
              <MediaQuery maxWidth={breakpoints.mdMax}>
                <Icon style={styles.menuIcon} type="android" />
              </MediaQuery>
            </NavLink>
          </Menu.Item>
        )}
        {this.context.checkPermission('menu:send:iva') && (
          <Menu.Item
            className="font-thin min-w-1/6 h-full text-xl flex justify-center items-center"
            key="/delivery/iva"
          >
            <NavLink
              location={this.props.location}
              style={styles.menuLink}
              onClick={() =>
                this.setState({ currentMenuItem: '/delivery/iva' })
              }
              to={'/delivery/iva'}
            >
              <MediaQuery minWidth={breakpoints.lgMin}>
                <Icon style={styles.menuIcon} type="api" />
                IVA
              </MediaQuery>
              <MediaQuery maxWidth={breakpoints.mdMax}>
                <Icon style={styles.menuIcon} type="api" />
              </MediaQuery>
            </NavLink>
          </Menu.Item>
        )}
        {this.context.checkPermission('menu:send:irs') && (
          <Menu.Item
            className="font-thin min-w-1/6 h-full text-xl flex justify-center items-center"
            key="/delivery/irs"
          >
            <NavLink
              location={this.props.location}
              style={styles.menuLink}
              onClick={() =>
                this.setState({ currentMenuItem: '/delivery/irs' })
              }
              to={'/delivery/irs'}
            >
              <MediaQuery minWidth={breakpoints.lgMin}>
                <Icon style={styles.menuIcon} type="file-excel" />
                IRS
              </MediaQuery>
              <MediaQuery maxWidth={breakpoints.mdMax}>
                <Icon style={styles.menuIcon} type="file-excel" />
              </MediaQuery>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    );
  }
}

const Dashboard = ({ locations }) => {
  const [hover, setHover] = useState(
    locations.map(location => ({ [location.url]: false }))
  );
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        ...g.layout.flexVertical,
        ...g.layout.flexStart,
        ...g.layout.alignCenter,
        padding: g.global.baseline * 2
      }}
    >
      <h1>Envios</h1>
      <p>Seleccione o tipo de envio</p>
      <Row
        style={{
          width: '100%',
          height: '100%',
          paddingTop: g.global.baseline * 2
        }}
        type={'flex'}
        justify={'center'}
        align={'top'}
        gutter={[g.global.baseline * 2, g.global.baseline * 2]}
      >
        {locations.map(location => {
          return (
            <Col key={location.url} className="w-1/5">
              <Link
                onMouseEnter={() => {
                  setHover({ ...hover, [location.url]: true });
                }}
                onMouseLeave={() => {
                  setHover({ ...hover, [location.url]: false });
                }}
                style={{
                  ...g.layout.flexVertical,
                  ...g.layout.flexCenter,
                  ...g.layout.alignCenter,
                  width: '100%',
                  minHeight: '20vh',
                  backgroundColor: hover[location.url] ? '#f9f9f9' : '#ffffff',
                  transition: 'background-color 0.2s ease-in-out',
                  boxShadow: '0 2px 2px #dddddd',
                  border: '1px solid #bbbbbb',
                  color: '#222222',
                  borderRadius: g.global.baseline,
                  padding: g.global.baseline * 2
                }}
                to={location.url}
              >
                {location.icon ? (
                  <div
                    style={{
                      padding: g.global.baseline * 3,
                      paddingBottom: g.global.baseline * 3
                    }}
                  >
                    <Icon
                      style={{
                        ...styles.menuIcon,
                        color: '#c8d7e0',
                        fontSize: '10vmax'
                      }}
                      theme="filled"
                      type={location.icon}
                    />
                  </div>
                ) : null}
                <h1 style={{ paddingBottom: 0 }}>{location.label}</h1>
                {location.description ? (
                  <div style={{ textAlign: 'center', minHeight: '3em' }}>
                    {location.description}
                  </div>
                ) : null}
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  async componentDidMount() {
    /* nothing */
  }

  render() {
    const locations = [];

    if (this.context.checkPermission('menu:send:saft')) {
      locations.push({
        label: 'SAF-T',
        description: 'Entrega via programa especializado da A.T.',
        icon: 'calculator',
        url: '/delivery/saft'
      });
    }
    if (this.context.checkPermission('menu:send:dmr')) {
      locations.push({
        label: 'DMR AT',
        description: 'Entrega via Robot',
        icon: 'android',
        url: '/delivery/dmr'
      });
    }
    if (this.context.checkPermission('menu:send:dmrss')) {
      locations.push({
        label: 'DMR SS',
        description: 'Entrega via Robot',
        icon: 'android',
        url: '/delivery/dmrss'
      });
    }
    if (this.context.checkPermission('menu:send:iva')) {
      locations.push({
        label: 'IVA',
        description: 'Entrega via Webservice',
        icon: 'api',
        url: '/delivery/iva'
      });
    }
    if (this.context.checkPermission('menu:send:irs')) {
      locations.push({
        label: 'IRS',
        description: 'Criação de XML',
        icon: 'file-excel',
        url: '/delivery/irs'
      });
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Finpartner - Entregas</title>
        </Helmet>
        <Header style={styles.header}>
          <FinMenu location={this.props.location} />
        </Header>
        <Content
          style={{
            height: 'calc(100% - ' + g.global.baseline * 4 + 'px)',
            ...g.layout.flexVertical,
            ...g.layout.flexStart
          }}
        >
          <Route
            exact
            path={'/delivery'}
            render={() => <Dashboard locations={locations}></Dashboard>}
          />
          {this.context.checkPermission('menu:send:saft') && (
            <Route exact path={'/delivery/saft'} component={Saft} />
          )}
          {this.context.checkPermission('menu:send:dmr') && (
            <Route exact path={'/delivery/dmr'} component={Dmr} />
          )}
          {this.context.checkPermission('menu:send:dmrss') && (
            <Route exact path={'/delivery/dmrss'} component={DmrSS} />
          )}
          {this.context.checkPermission('menu:send:iva') && (
            <Route exact path={'/delivery/iva'} component={Iva} />
          )}
          {this.context.checkPermission('menu:send:irs') && (
            <Route exact path={'/delivery/irs'} component={Irs} />
          )}
        </Content>
      </>
    );
  }
}

export default Home;
