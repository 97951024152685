import React from 'react';

import { Button } from 'antd4';
import { DeleteOutlined } from '@ant-design/icons';

import '../../../fields/Fields.scss';

const ActionDeleteArrayElement = ({ onClick }) => {
  return (
    <Button
      className="delete-element mr-3"
      size="small"
      type="danger"
      ghost
      icon={<DeleteOutlined></DeleteOutlined>}
      onClick={onClick}
      shape="circle"
    ></Button>
  );
};

export default ActionDeleteArrayElement;
