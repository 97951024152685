import React from 'react';
import autobind from 'auto-bind/react';
import { Button, Modal, Icon } from 'antd';
import moment from 'moment';

import ReportTable from '../../../components/table/iva/ReportTable';
import { scopes } from '../../../components/table/iva/ReportTable';
//import { getDucZip } from '../../../network/iva';
import { anchorDownload, mrg } from '../../../common/util';
import {
  prepareWorkbook,
  workbook2Blob,
  prepareExcelData,
  insertExcelData
} from '../excelReports';
import globalStyles from '../../../styles/global';

const { confirm } = Modal;

const styles = {
  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline
  },
  collapse: {
    position: 'relative',
    width: '100%'
  },
  pageHeaderBox: {
    color: globalStyles.colors.background.pink
  },
  pageHeaderButton: {
    width: 'auto',
    height: '46px',
    fontWeight: 100,
    fontSize: globalStyles.global.baseline * 1.5,
    marginLeft: globalStyles.global.baseline
  },
  result: {
    width: '100%',
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  modal: {
    body: {
      height: '60vh',
      maxHeight: '90vh',
      paddingTop: globalStyles.global.baseline * 4,
      paddingBottom: globalStyles.global.baseline * 3,
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter
    }
  },
  feedback: {
    title: {
      fontSize: globalStyles.global.baseline * 3,
      fontWeight: 100,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      paddingBottom: globalStyles.global.baseline,
      marginBottom: 0,
      textAlign: 'center'
    },
    info: {
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none'
    },
    code: {
      fontSize: globalStyles.global.baseline,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none',
      fontFamily: 'monospace',
      textAlign: 'left'
    },
    all: {
      position: 'relative',
      zIndex: 20,
      width: '96%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexBetween,
      ...globalStyles.layout.alignCenter,
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    },
    progress: {
      width: '80%',
      flexGrow: 3,
      overflowY: 'scroll',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart,
      fontSize: globalStyles.global.baseline,
      fontWeight: 300,
      color: '#666666',
      textTransform: 'unset'
    }
  }
};

const excelColumns = [
  { header: 'NIF', key: 'nif', dataPath: 'nif', width: 20 },
  {
    header: 'Nome',
    key: 'name',
    dataPath: 'consistency.name',
    width: 20
  },
  {
    header: 'Primavera',
    key: 'primavera',
    dataPath: 'consistency.primavera',
    width: 20
  },
  {
    header: 'Data',
    key: 'sendDate',
    dataPath: 'report.sendDate',
    transform: date => moment(date).toDate(),
    width: 20
  },
  {
    header: 'Validações Vicki',
    key: 'status',
    dataPath: 'consistency.status',
    width: 20
  },
  {
    header: 'Alertas Vicki',
    key: 'consistencyWarnings',
    dataPath: 'consistency.warningList',
    transform: array => {
      return array.join('\n');
    },
    width: 60
  },
  {
    header: 'Erros Vicki',
    key: 'consistencyErrors',
    dataPath: 'consistency.errorList',
    transform: array => {
      return array.join('\n');
    },
    width: 60
  },
  {
    header: 'Resultado AT',
    key: 'status',
    dataPath: 'report.status',
    width: 20
  },
  {
    header: 'Alertas AT',
    key: 'reportWarnings',
    dataPath: 'report.warningList',
    transform: array => {
      return array.join('\n');
    },
    width: 60
  },
  {
    header: 'Erros AT',
    key: 'reportErrors',
    dataPath: 'report.errorList',
    transform: array => {
      return array.join('\n');
    },
    width: 60
  }
];

export default class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      showUploadModal: false,
      clientsChanged: false,
      errors: true
    };

    autobind(this);
  }

  static getDerivedStateFromProps(props, state) {
    console.log({ reportData: props.data });
    const errors = props?.consistency?.consistency?.errorList;
    if (errors?.length) {
      return { errors: errors };
    } else {
      return { errors: false };
    }
  }

  async generateReportFile() {
    const { workbook, worksheet } = prepareWorkbook({
      worksheetColumns: excelColumns,
      sheetTitle: 'IVA Delivery Report'
    });

    const reportData = !this.props.data
      ? []
      : prepareExcelData(this.props.data, excelColumns);

    insertExcelData(worksheet, reportData);

    try {
      const blob = await workbook2Blob(workbook);

      const filename =
        'iva_report_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
      anchorDownload(blob, filename);
    } catch (err) {
      console.error(err);
    }
  }

  async send() {
    this.setState({ uploading: true });
    if (this.props.onSend) {
      await this.props.onSend();
    }
    this.setState({ uploading: false });
  }

  confirmReset() {
    const self = this;
    confirm({
      okButtonProps: { ghost: true },
      title: (
        <span style={{ fontSize: globalStyles.global.baseline * 2 }}>
          Fechar processo de envio
        </span>
      ),
      icon: (
        <Icon
          type="warning"
          style={{
            fontSize: globalStyles.global.baseline * 2.5,
            color: globalStyles.colors.feedback.error
          }}
        />
      ),
      content: (
        <div style={mrg([styles.modal.body, { height: 'auto' }])}>
          <span style={styles.modal.message}>
            Tem certeza de que pretende terminar o processo de envio?
          </span>
          <ul style={styles.modal.list}>
            <li style={styles.modal.error}>
              Todos os utilizadores serão redireccionados para o ecran inicial.
            </li>
            <li style={styles.modal.error}>
              Toda a informação, resultados e ficheiros do processo corrente
              serão apagados.
            </li>
            <li style={{ ...styles.modal.error, fontWeight: 700 }}>
              Esta operação é irreversível!
            </li>
          </ul>
        </div>
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        if (self.props.reset) {
          self.props.reset();
        }
      }
    });
  }

  render() {
    return (
      <>
        {this.props.data ? (
          <div style={styles.result}>
            <div
              style={mrg([
                styles.pageHeaderContainer,
                {
                  justifyContent: this.state.errors
                    ? 'space-between'
                    : 'flex-end'
                }
              ])}
            >
              <div style={styles.pageHeaderContainer}>
                {this.props.scope === scopes.REPORT && (
                  <>
                    <Button
                      type="primary"
                      ghost={true}
                      disabled={this.state.loading}
                      style={styles.pageHeaderButton}
                      onClick={this.generateReportFile}
                    >
                      Descarregar Relatório
                    </Button>
                    {/* <Button
                      type="primary"
                      disabled={this.state.loading}
                      ghost={true}
                      style={styles.pageHeaderButton}
                      onClick={getDucZip}
                    >
                      Descarregar DUCs
                    </Button> */}
                  </>
                )}
              </div>

              <Button
                type="danger"
                ghost
                disabled={this.state.loading}
                style={styles.pageHeaderButton}
                onClick={this.confirmReset}
              >
                {this.props.scope === scopes.CONSISTENCY
                  ? 'REINICIAR PROCESSO'
                  : 'FECHAR PROCESSO'}
              </Button>
              {this.props.scope === scopes.CONSISTENCY && (
                <Button
                  type={this.state.errors ? 'danger' : 'primary'}
                  ghost
                  style={styles.pageHeaderButton}
                  onClick={this.send}
                >
                  Enviar
                </Button>
              )}
            </div>

            <ReportTable scope={this.props.scope} clients={this.props.data} />
          </div>
        ) : (
          <div style={styles.feedback.all}>
            <h2 style={styles.feedback.title}>A processar</h2>
            {this.props.progress && (
              <div style={styles.feedback.progress}>
                {Object.keys(this.props.progress ?? {}).map((key, index) => (
                  <span key={index}>{this.props.progress[key]}</span>
                ))}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
