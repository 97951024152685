import React, { Fragment } from 'react';
import autobind from 'auto-bind';

import { Field, TextAreaField } from '../../../fields';

import '../../../fields/Fields.scss';

class Agency extends React.Component {
  constructor(props) {
    super(props);

    autobind(this);
  }

  render() {
    return (
      <Fragment key={'agency'}>
        <h4 className="w-100">Agência</h4>
        <Field
          key={'name'}
          width={50}
          label={'Nome'}
          path={`agency.name`}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'contact'}
          width={75}
          label={'Contacto'}
          path={`agency.contact`}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <TextAreaField
          key={'observation'}
          width={100}
          label={'Observação'}
          minRows={2}
          path={`agency.observation`}
          data={this.props.data}
          updateField={this.props.updateField}
        ></TextAreaField>
      </Fragment>
    );
  }
}

export default Agency;
