import React, { useEffect, useState, useCallback } from 'react';
import { Input, Tag } from 'antd4';
import op from 'object-path';

import './Fields.scss';

const Field = ({
  path,
  width,
  label,
  editable,
  disabled,
  data,
  updateField
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);
  const [isDirty, setDirty] = useState(false);

  useEffect(
    value => {
      const updateValue = op.get(data, path);
      setValue(updateValue);
    },
    [data, path]
  );

  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async event => {
      const value = event?.target?.value;
      if (isDirty) {
        const response = await updateField(path, value);
        if (response?.ok) {
          // console.log('response ok, setting dirty to FALSE. adding save-success' );
          setDirty(false);
          setStatusClass('save-success');

          setTimeout(() => {
            setStatusClass('');
          }, 3000);
        } else {
          setStatusClass('save-error');
        }
      } else {
        // console.log('not dirty');
      }
    },
    [isDirty, updateField, path]
  );

  if (!op.has(data, path)) {
    return null;
  }

  return (
    <div key={path} className={`input-container w-${width ?? '25'}`}>
      <Input
        className={'input ' + statusClass}
        value={value}
        disabled={disabled}
        onBlur={event => {
          if (editable === false) {
            event.preventDefault();
            return null;
          }
          onSave(event);
        }}
        onPressEnter={event => {
          if (editable === false) {
            event.preventDefault();
            return null;
          }
          onSave(event);
        }}
        onChange={event => {
          if (editable === false) {
            event.preventDefault();
            return null;
          }
          // console.log('SETTING dirty and value');
          setDirty(true);
          setValue(event.target.value);
        }}
        prefix={<Tag className="input-prefix border-radius">{label}</Tag>}
      />
    </div>
  );
};

export default Field;
