import React, { Component } from 'react';

//import './App.css';
import Root from './Root';
// import RootPublic, { publicPathPrefixes } from './RootPublic';
import AppContext from './context/AppContextBase';

class App extends Component {
  static contextType = AppContext;

  render() {
    // const pathname = window.location?.pathname;

    // const isPublic = publicPathPrefixes.reduce((prevBoolean, currentPath) => {
    //   return prevBoolean || pathname.includes(currentPath);
    // }, false);
    // console.log(pathname, isPublic);
    // if (isPublic) {
    //   return <RootPublic />;
    // }

    return <Root />;
  }
}

App.contextType = AppContext;

export default App;
