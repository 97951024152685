import { address } from '../common/variables';
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

//eslint-disable-next-line
const BE_ADDR = address;

export async function fetchSystemState() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'dmrss/system-state', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
export async function getDucZip() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'dmrss/download-zip', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  console.log(response);

  response.blob().then(blob => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'reportZip.zip';
    a.click();
  });

  return response;
}

export async function setSystemState(stateName) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'dmrss/system-state', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ current_state: stateName })
  });
  return await handleResponse(response);
}

export async function fetchReport() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'dmrss/report', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function sendDmrs(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'dmrss/upload', {
    method: 'POST',
    body: data,
    headers: {
      Accept: 'application/json',
      //'Content-Type': 'multipart/form-data',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function reset() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'dmrss/reset', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
