import React from 'react';

import {
  Row,
  Col,
  Card,
  Form,
  Select,
  Input,
  Button,
  message,
  Divider,
  Popover
} from 'antd';

import AppContext from '../../../context/AppContextBase';

import DetailedMessage from '../../../components/util/DetailedMessage';
import validator from 'validator';
import { departments, roles, scopes } from '../../../common/variables';
import g from '../../../styles/global';

import { getGroups, postUser } from '../../../network/users';

const { Option } = Select;

const styles = {
  pageHeaderBox: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: g.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  attachments: {
    borderTop: '1px solid #e8e8e8',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    alignItems: 'flex-start',
    marginTop: g.global.baseline * 2,
    width: '100%'
  },
  card: {
    body: {
      width: '100%',
      paddingBottom: g.global.baseline
    }
  },
  type: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    backgroundColor: '#e8e8e8',
    marginRight: g.global.baseline,
    paddingLeft: g.global.baseline,
    paddingRight: g.global.baseline,
    paddingTop: g.global.baseline * 0.5,
    paddingBottom: g.global.baseline * 0.5
  },
  help: {
    paragraph: {
      paddingBottom: g.global.baseline
    }
  },
  form: {
    item: {
      marginBottom: 0,
      width: '100%'
    },
    actions: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart
    },
    body: {
      margin: '2%',
      width: '96%'
    }
  }
};

class CreateUser extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { departments: [], groups: null, loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.state = { loading: false };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    // User groups:
    if (this.context.checkPermission('menu:settings:users:groups')) {
      let groups = await getGroups();
      if (groups && groups.result === 'OK' && groups.data.length > 0) {
        groups.data.unshift({
          _id: null,
          name: 'None'
        });
        this.setState({
          groups: groups.data
        });
      } else if (groups?.result === 'OK') {
        this.setState({
          groups: null
        });
      } else {
        this.setState({
          groups: null
        });
      }
    }

    // Current user dept:
    const dept = this.context.getUserAttribute('department');

    if (dept !== '*') {
      this.setState({ departments: [departments?.[dept]] });
    } else {
      this.setState({
        departments: Object.values(departments).filter(dept => dept.key !== '*')
      });
    }

    this.setState({ loading: false });
  }

  resetFields() {
    this.props.form.resetFields([
      'firstName',
      'lastName',
      'role',
      'scope',
      'department',
      'email',
      'password',
      'group'
    ]);
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const newValues = { ...values };
        newValues.name = values.firstName + ' ' + values.lastName;
        newValues.permissions = {
          department: values.department,
          scope: scopes.internal.key
        };
        delete newValues.department;
        delete newValues.scope;

        const response = await postUser(newValues);
        if (response?.result === 'OK') {
          message.success('User Created Successfully');
          this.resetFields();
        } else {
          DetailedMessage.error(
            'Error saving data - ' + response.message,
            response
          );
        }
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form style={styles.form.body} onSubmit={this.handleSubmit}>
        <Card
          bodyStyle={styles.card.body}
          headStyle={{}}
          title={'New User'}
          bordered={true}
        >
          <Row gutter={g.global.baseline} style={styles.card.body}>
            <Col span={8}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('firstName', {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório'
                    }
                  ]
                })(
                  <Input
                    disabled={this.state.loading}
                    placeholder="Primeiro Nome"
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('lastName', {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório'
                    }
                  ]
                })(
                  <Input
                    disabled={this.state.loading}
                    placeholder="Último Nome"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={g.global.baseline}
            style={{ ...styles.card.body, paddingBottom: 0 }}
          >
            <Col span={8} offset={0}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório'
                    },
                    {
                      validator: async (rule, value, callback) => {
                        if (value && !validator.isEmail(value)) {
                          callback('Email inválido');
                        }
                        callback();
                      }
                    }
                  ]
                })(<Input disabled={this.state.loading} placeholder="Email" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório'
                    }
                  ]
                })(
                  <Input.Password
                    disabled={this.state.loading}
                    placeholder="Password Temporária"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider
            style={{ width: '66.66%', minWidth: '66.66%' }}
            type="horizontal"
          ></Divider>
          <Row
            gutter={g.global.baseline}
            style={{
              ...styles.card.body,
              paddingBottom: g.global.baseline * 2
            }}
          >
            <Col span={7}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('department', {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório'
                    }
                  ]
                })(
                  <Select
                    disabled={this.state.loading || !this.state.departments}
                    placeholder="Departamento"
                  >
                    {this.state.departments
                      ? this.state.departments.map(option => (
                          <Option key={option.key}>{option.name}</Option>
                        ))
                      : null}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col
              span={1}
              style={{
                ...g.layout.flexHorizontal,
                ...g.layout.flexCentert,
                ...g.layout.alignCenter,
                height: '40px'
              }}
            >
              <Popover
                placement="top"
                title={'Departamentos'}
                content={
                  <div style={{ maxWidth: '20vw' }}>
                    {Object.values(departments)
                      .filter(dept => dept.key !== '*')
                      .map(dept => (
                        <div key={dept.key} style={styles.help.paragraph}>
                          <span>
                            <span style={{ fontWeight: 500 }}>{dept.name}</span>
                            <span>: {dept.description}</span>
                          </span>
                        </div>
                      ))}
                  </div>
                }
                trigger="hover"
              >
                <Button type="secondary" icon="question" />
              </Popover>
            </Col>
            <Col span={7}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('role', {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório'
                    }
                  ]
                })(
                  <Select disabled={this.state.loading} placeholder="Cargo">
                    {Object.values(roles).map(option => (
                      <Option key={option.key}>{option.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col
              span={1}
              style={{
                ...g.layout.flexHorizontal,
                ...g.layout.flexCentert,
                ...g.layout.alignCenter,
                height: '40px'
              }}
            >
              <Popover
                placement="top"
                title={'Cargos'}
                content={
                  <div style={{ maxWidth: '20vw' }}>
                    {Object.values(roles).map(role => (
                      <div key={role.key} style={styles.help.paragraph}>
                        <span>
                          <span style={{ fontWeight: 500 }}>{role.name}</span>
                          <span>: {role.description}</span>
                        </span>
                      </div>
                    ))}
                  </div>
                }
                trigger="hover"
              >
                <Button type="secondary" icon="question" />
              </Popover>
            </Col>
            {/*<Col span={6}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('scope', {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório'
                    }
                  ]
                })(
                  <Select disabled={this.state.loading} placeholder="Scope">
                    {scopes.map(option => (
                      <Option key={option.toLowerCase()}>{option}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {this.context.checkPermission('menu:settings:users:groups') &&
              this.state.groups && (
                <Col span={6}>
                  <Form.Item style={styles.form.item}>
                    {getFieldDecorator('group')(
                      <Select
                        disabled={this.state.loading}
                        placeholder="User Group"
                      >
                        {this.state.groups.map(option => (
                          <Option key={option._id}>{option.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              )} */}
          </Row>
          <Row gutter={g.global.baseline} style={styles.card.body}>
            <Col style={styles.form.actions}>
              <Form.Item
                style={{
                  ...styles.form.item,
                  width: '100%',
                  marginBottom: 0,

                  ...g.layout.flexHorizontal,
                  ...g.layout.flexStart
                }}
              >
                <Button
                  style={{
                    marginBottom: g.global.baseline,
                    marginTop: g.global.baseline,
                    marginRight: g.global.baseline
                  }}
                  disabled={this.state.loading}
                  type="primary"
                  htmlType="submit"
                >
                  Enviar
                </Button>

                <Button
                  style={{
                    marginBottom: g.global.baseline,
                    marginTop: g.global.baseline,
                    marginRight: g.global.baseline
                  }}
                  disabled={this.state.loading}
                  type="secondary"
                  onClick={() => this.resetFields()}
                >
                  Limpar Campos
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const crmForm = Form.create({ name: 'create_user' })(CreateUser);

export default crmForm;
