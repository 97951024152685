import { cleanIncomeCode, parseCurrency } from './transforms';

const dataKeys = {
  '301': {
    lineSkip: 1,
    columns: [{ excelKey: 'Sujeito Passivo A', dataKey: 'Sujeito Passivo A' }]
  },
  '302': {
    lineSkip: 1,
    columns: [{ excelKey: 'Sujeito Passivo B', dataKey: 'Sujeito Passivo B' }]
  },
  '41': {
    lineSkip: 3,
    columns: [
      {
        excelKey: 'Freguesia',
        dataKey: 'Freguesia'
      },
      { excelKey: 'Tipo', dataKey: 'TipoPredio' },
      {
        excelKey: 'Artigo',
        dataKey: 'Artigo'
      },
      {
        excelKey: 'Fração/Secção',
        dataKey: 'Fraccao'
      },

      { excelKey: 'Titular', dataKey: 'Titular' },
      {
        excelKey: 'Valor Ilíquido',
        dataKey: 'ValorIliquidoRendas',
        transform: parseCurrency
      },
      {
        excelKey: 'Natureza',
        dataKey: 'NaturezaRendas'
      },
      {
        excelKey: 'Retenção na fonte',
        dataKey: 'RetencoesFonte',
        transform: parseCurrency
      },
      { excelKey: 'NIF do Arrendatário', dataKey: 'NIFArrendatario' },
      { excelKey: 'País', dataKey: 'CodPais' },
      {
        excelKey: 'Conservação e Manutenção ',
        dataKey: 'ConservacaoManutencao',
        transform: parseCurrency
      },
      {
        excelKey: 'Condominio',
        dataKey: 'Condominio',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto Municipal sobre imóveis',
        dataKey: 'IMI',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto Selo ',
        dataKey: 'ImpostoSelo',
        transform: parseCurrency
      },
      {
        excelKey: 'Taxas autárquicas',
        dataKey: 'TaxasAutarquicas',
        transform: parseCurrency
      },
      { excelKey: 'Outros ', dataKey: 'Outros' },
      { excelKey: 'Ano', dataKey: 'AnoInicioContrato' },
      { excelKey: 'Mês', dataKey: 'MesInicioContrato' },

      { excelKey: 'Ano', dataKey: 'AnoInicioGastos' },
      { excelKey: 'Mês', dataKey: 'MesInicioGastos' },
      { excelKey: 'Valor', dataKey: 'Valor', transform: parseCurrency }
    ]
  },
  '42': {
    // Note: Almost equal to 4.1, only diferences are commented
    lineSkip: 3,
    columns: [
      {
        // FIXME: Check dataKey names
        excelKey: 'Nº do contrato',
        dataKey: 'NContrato'
      },
      {
        excelKey: 'Freguesia',
        dataKey: 'Freguesia'
      },
      { excelKey: 'Tipo', dataKey: 'TipoPredio' },
      {
        excelKey: 'Artigo',
        dataKey: 'Artigo'
      },
      {
        excelKey: 'Fração/Secção',
        dataKey: 'Fraccao'
      },

      { excelKey: 'Titular', dataKey: 'Titular' },
      {
        excelKey: 'Valor Ilíquido',
        dataKey: 'ValorIliquidoRendas',
        transform: parseCurrency
      },
      {
        excelKey: 'Natureza',
        dataKey: 'NaturezaRendas'
      },
      {
        excelKey: 'Retenção na fonte',
        dataKey: 'RetencoesFonte',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF Português',
        dataKey: 'NIFArrendatario' // FIXME: Check dataKey names
      },
      {
        excelKey: 'País',
        dataKey: 'CodPais' // FIXME: Check dataKey names
      },
      {
        excelKey: 'Conservação e Manutenção ',
        dataKey: 'ConservacaoManutencao',
        transform: parseCurrency
      },
      {
        excelKey: 'Condominio',
        dataKey: 'Condominio',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto Municipal sobre imóveis',
        dataKey: 'IMI',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto Selo ',
        dataKey: 'ImpostoSelo',
        transform: parseCurrency
      },
      {
        excelKey: 'Taxas autárquicas',
        dataKey: 'TaxasAutarquicas',
        transform: parseCurrency
      },
      { excelKey: 'Outros ', dataKey: 'Outros' },
      { excelKey: 'Ano', dataKey: 'AnoInicioContrato' },
      { excelKey: 'Mês', dataKey: 'MesInicioContrato' },

      { excelKey: 'Ano', dataKey: 'AnoInicioGastos' },
      { excelKey: 'Mês', dataKey: 'MesInicioGastos' },
      { excelKey: 'Valor', dataKey: 'Valor', transform: parseCurrency }
    ]
  },
  '42A': {
    // FIXME: Check all dataKey names
    lineSkip: 3,
    columns: [
      { excelKey: 'Comunicação (Portaria ...)', dataKey: 'Comunicacao' },
      { excelKey: 'Ano', dataKey: 'AnoInicioContrato' },
      { excelKey: 'Mês', dataKey: 'MesInicioContrato' },
      { excelKey: 'Dia', dataKey: 'DiaInicioContrato' },
      { excelKey: 'Ano', dataKey: 'AnoTermoContrato' },
      { excelKey: 'Mês', dataKey: 'MesTermoContrato' },
      { excelKey: 'Dia', dataKey: 'DiaTermoContrato' },
      { excelKey: 'Ano', dataKey: 'AnoInicioUltimaRenovacaoContrato' },
      { excelKey: 'Mês', dataKey: 'MesInicioUltimaRenovacaoContrato' },
      { excelKey: 'Dia', dataKey: 'DiaInicioUltimaRenovacaoContrato' },
      { excelKey: 'Ano', dataKey: 'AnoFimUltimaRenovacaoContrato' },
      { excelKey: 'Mês', dataKey: 'MesFimUltimaRenovacaoContrato' },
      { excelKey: 'Dia', dataKey: 'DiaFimUltimaRenovacaoContrato' }
    ]
  },
  '43': {
    // FIXME: Check all dataKey names
    lineSkip: 4,
    columns: [
      {
        excelKey: 'Nº do contrato',
        dataKey: 'NContrato'
      },
      { excelKey: 'Ano', dataKey: 'AnoInicioContrato' },
      { excelKey: 'Mês', dataKey: 'MesInicioContrato' },
      { excelKey: 'Dia', dataKey: 'DiaInicioContrato' },
      { excelKey: 'Ano', dataKey: 'AnoCelebracaoEscritura' },
      { excelKey: 'Mês', dataKey: 'MesCelebracaoEscritura' },
      { excelKey: 'Dia', dataKey: 'DiaCelebracaoEscritura' },
      {
        excelKey: 'Valor da Caução',
        dataKey: 'ValorCaucaoEscritura',
        transform: parseCurrency
      },
      {
        excelKey: 'Freguesia',
        dataKey: 'Freguesia'
      },
      { excelKey: 'Tipo', dataKey: 'TipoPredio' },
      {
        excelKey: 'Artigo',
        dataKey: 'Artigo'
      },
      {
        excelKey: 'Fração/Secção',
        dataKey: 'Fraccao'
      },
      { excelKey: 'Titular', dataKey: 'Titular' },
      {
        excelKey: 'Rendimento Valor líquido',
        dataKey: 'ValorIliquidoRendimento',
        transform: parseCurrency
      },
      {
        excelKey: 'Natureza',
        dataKey: 'NaturezaRendimento'
      },
      {
        excelKey: 'Retenções na fonte',
        dataKey: 'RetencoesFonte',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF Moradores',
        dataKey: 'NIFMorador'
      },

      {
        excelKey: 'Conservação ',
        dataKey: 'ConservacaoExtraordinaria',
        transform: parseCurrency
      },
      {
        excelKey: 'Condominio',
        dataKey: 'Condominio',
        transform: parseCurrency
      },
      {
        excelKey: 'Taxas autárquicas',
        dataKey: 'TaxasAutarquicas',
        transform: parseCurrency
      },
      { excelKey: 'Outros ', dataKey: 'Outros' },
      { excelKey: 'Ano', dataKey: 'AnoCessacao' },
      { excelKey: 'Mês', dataKey: 'MesCessacao' },
      { excelKey: 'Dia', dataKey: 'DiaCessacao' },
      { excelKey: 'Motivo', dataKey: 'MotivoCessacao' }
    ]
  },
  '5': {
    // FIXME: Check all dataKey names
    lineSkip: 3,
    columns: [
      { excelKey: 'Titular', dataKey: 'Titular' },
      {
        excelKey: 'Renda Recebida (Valor ilíquido)',
        dataKey: 'RendaRecebida',
        transform: parseCurrency
      },
      {
        excelKey: 'Retenções na fonte',
        dataKey: 'Retencoes',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF Português',
        dataKey: 'NifSublocatario'
      },
      {
        excelKey: 'País',
        dataKey: 'CodPais'
      },
      {
        excelKey: 'Renda Paga ao Senhorio',
        dataKey: 'RendaPaga',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF do Senhorio',
        dataKey: 'NifSenhorio'
      }
    ]
  },
  // '6A': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 2,
  //   columns: [
  //     {
  //       excelKey: 'Indíce Imóveis Recuperados',
  //       dataKey: 'IndexImoveisRecuperados'
  //     },
  //     {
  //       excelKey: 'Imóveis Recuperados',
  //       dataKey: 'ImoveisRecuperados'
  //     }
  //   ]
  // },
  // '6B': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 1,
  //   columns: [
  //     {
  //       excelKey: 'Indíce Lojas Com História',
  //       dataKey: 'LojasComHistoria'
  //     },
  //     {
  //       excelKey: 'Lojas Com História',
  //       dataKey: 'LojasComHistoria'
  //     }
  //   ]
  // },
  // '6C': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 1,
  //   columns: [
  //     {
  //       excelKey: 'Indíce Imóveis Gestão Florestal',
  //       dataKey: 'IndexImoveisGestaoFlorestal'
  //     },
  //     {
  //       excelKey: 'Imóveis Gestão Florestal',
  //       dataKey: 'ImoveisGestaoFlorestal'
  //     }
  //   ]
  // },
  // '6D': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 2,
  //   columns: [
  //     {
  //       excelKey: 'Indíce Imóveis Arrendamento Acessível',
  //       dataKey: 'IndexImoveisArrendamentoAcessivel'
  //     },
  //     {
  //       excelKey: 'Nº Linha do Q4.1',
  //       dataKey: 'NLinhaQ41'
  //     },
  //     {
  //       excelKey: 'Nº do contrato',
  //       dataKey: 'NContrato'
  //     },
  //     {
  //       excelKey: 'Natureza do Programa',
  //       dataKey: 'NaturezaPrograma'
  //     }
  //   ]
  // },
  // '6E': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 3,
  //   columns: [
  //     {
  //       excelKey: 'Indíce Imóveis Subarrendamento Acessível',
  //       dataKey: 'IndexImoveisSubarrendamentoAcessivel'
  //     },
  //     {
  //       excelKey: 'Nº Linha do Q5',
  //       dataKey: 'NLinhaQ5'
  //     },
  //     {
  //       excelKey: 'Nº do contrato',
  //       dataKey: 'NContrato'
  //     },
  //     {
  //       excelKey: 'Freguesia',
  //       dataKey: 'Freguesia'
  //     },
  //     { excelKey: 'Tipo', dataKey: 'TipoPredio' },
  //     {
  //       excelKey: 'Artigo',
  //       dataKey: 'Artigo'
  //     },
  //     {
  //       excelKey: 'Fração/Secção',
  //       dataKey: 'Fraccao'
  //     },
  //     {
  //       excelKey: 'Natureza do Programa',
  //       dataKey: 'NaturezaPrograma'
  //     }
  //   ]
  // },
  '6F': {
    // FIXME: Check all dataKey names
    lineSkip: 2,
    columns: [
      { excelKey: 'Sim', dataKey: 'Sim' },
      { excelKey: 'Não', dataKey: 'Nao' }
    ]
  },
  '7': {
    lineSkip: 2,
    columns: [
      // {
      //   // FIXME: Check all dataKey names
      //   excelKey: 'Indíce Quadro 7',
      //   dataKey: 'IndexQ7'
      // },
      { excelKey: 'Titular', dataKey: 'Titular' },
      {
        excelKey: 'Rendimento',
        dataKey: 'RendimentoDistribuido',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF da Entidade Emitente',
        dataKey: 'NIFEntidadeEmitente'
      },
      {
        // FIXME: Check all dataKey names
        excelKey: 'Imposto Pago no Estrangeiro',
        dataKey: 'ImpostoPagoEstrangeiro',
        transform: parseCurrency
      },
      {
        excelKey: 'Retenções',
        dataKey: 'RetencoesFonte',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF da Entidade Retentora',
        dataKey: 'NIFEntidadeRetentora'
      },

      {
        // FIXME: Check all dataKey names
        excelKey: 'Imposto Retido em Portugal',
        dataKey: 'ImpostoRetidoPortugal',
        transform: parseCurrency
      }
    ]
  },
  // '8A': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 3,
  //   columns: [
  //     // Part 1: Rendimentos de Anos Anteriores Incluídos nos Quadros 4.1, 4.2, 4.3 e 5
  //     { excelKey: 'Quadro', dataKey: 'Quadro' },
  //     {
  //       excelKey: 'Nº Linha',
  //       dataKey: 'NLinha'
  //     },
  //     {
  //       excelKey: 'Rendimento',
  //       dataKey: 'Rendimento',
  //       transform: parseCurrency
  //     },
  //     {
  //       excelKey: 'Ano a que respeitam os rendimentos',
  //       dataKey: 'AnoRendimentos'
  //     },

  //     {
  //       excelKey: 'Nº Anos (2019 e anteriores)',
  //       dataKey: 'NAnos2019'
  //     }
  //   ]
  // },
  // '8B': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 3,
  //   columns: [
  //     // Part 2: Rendimentos de Anos Anteriores - Opção pelo Regime do n.º 3 do artigo 74.º do CIRS
  //     { excelKey: 'QuadroRegime3', dataKey: 'Quadro' },
  //     {
  //       excelKey: 'Nº Linha',
  //       dataKey: 'NLinhaRegime3'
  //     },
  //     {
  //       excelKey: 'Ano a que respeitam os rendimentos',
  //       dataKey: 'AnoRendimentosRegime3'
  //     },
  //     {
  //       excelKey: 'Rendimento',
  //       dataKey: 'RendimentoRegime3',
  //       transform: parseCurrency
  //     },
  //     {
  //       excelKey: 'Gastos Suportados e Pagos',
  //       dataKey: 'GastosSuportadosRegime3',
  //       transform: parseCurrency
  //     },
  //     {
  //       excelKey: 'Retenções',
  //       dataKey: 'RetencoesFonteRegime3',
  //       transform: parseCurrency
  //     }
  //   ]
  // },
  // '91': {
  //   // FIXME: Check all dataKey names
  //   lineSkip: 3,
  //   columns: [
  //     { excelKey: 'Quadro', dataKey: 'Quadro' },
  //     {
  //       excelKey: 'Nº Linha',
  //       dataKey: 'NLinha'
  //     },
  //     {
  //       excelKey: 'Valor patrimonial Tribútario',
  //       dataKey: 'ValorPatrimonial',
  //       transform: parseCurrency
  //     }
  //   ]
  // },
  '92': {
    // FIXME: Check all dataKey names
    lineSkip: 1,
    columns: [
      {
        excelKey: '2 - Valor total da liquidação do Adicional ao IMI',
        dataKey: 'ValorAdicionalImi',
        transform: parseCurrency
      }
    ]
  },
  '93': {
    // FIXME: Check all dataKey names
    lineSkip: 1,
    columns: [
      {
        excelKey:
          '3 - Valor tributável total de todos os prédios urbanos de que é proprietário e sobre os quais incidiu o Adicional ao IMI',
        dataKey: 'ValorTributavelAdicionalImi',
        transform: parseCurrency
      }
    ]
  },
  '10': {
    // FIXME: Check all dataKey names
    lineSkip: 3,
    columns: [
      {
        excelKey: 'Regime Fiscal',
        dataKey: 'RegimeFiscal'
      },
      {
        excelKey: 'Nº do contrato',
        dataKey: 'NContrato'
      },
      {
        excelKey: 'Ano de início do Benefício',
        dataKey: 'AnoInicioBeneficio'
      },
      { excelKey: 'Ano', dataKey: 'AnoCessacaoContrato' },
      { excelKey: 'Mês', dataKey: 'MesCessacaoContrato' },
      { excelKey: 'Dia', dataKey: 'DiaCessacaoContrato' },
      {
        excelKey: 'Motivo',
        dataKey: 'MotivoCessacaoContrato'
      },
      {
        excelKey: 'Freguesia',
        dataKey: 'Freguesia'
      },
      { excelKey: 'Tipo', dataKey: 'TipoPredio' },
      {
        excelKey: 'Artigo',
        dataKey: 'Artigo'
      },
      {
        excelKey: 'Fração/Secção',
        dataKey: 'Fraccao'
      },
      { excelKey: 'Titular', dataKey: 'Titular' },
      {
        excelKey: 'NIF Português',
        dataKey: 'NIFArrendatario' // FIXME: Check dataKey names
      },
      {
        excelKey: 'País',
        dataKey: 'CodPais' // FIXME: Check dataKey names
      }
    ]
  },
  '11': {
    // FIXME: Check all dataKey names
    lineSkip: 3,
    columns: [
      // {
      //   excelKey: 'Indíce Pagamentos por Conta',
      //   dataKey: 'IndexImoveisArrendamentoAcessivel'
      // },
      { excelKey: 'Titular', dataKey: 'Titular' },
      {
        excelKey: 'Valor',
        dataKey: 'Valor',
        transform: parseCurrency
      }
    ]
  }
};

const categoryKeysF = {
  // These may overlap with other annexes, careful when adding keys
  '301': '301',
  '302': '302',
  '41': '41',
  '42': '42',
  '42A': '42A',
  '43': '43',
  '5': '5',
  // '6A': '6A',
  // '6B': '6B',
  // '6C': '6C',
  // '6D': '6D',
  // '6E': '6E',
  '6F': '6F',
  '7': '7',
  // '8A': '8A',
  // '8B': '8B',
  // '91': '91',
  '92': '92',
  '93': '93',
  '10': '10',
  '11': '11'
};

const annexSheetF = { 'anexo f': { dataKey: 'f' } };

export default dataKeys;

export { categoryKeysF, annexSheetF };
