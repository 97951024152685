import { address } from '../common/variables';
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

const BE_ADDR = address;

export const ENDPOINTS = {
  saftUpload: 'clients/upload',
  dmrUpload: 'dmr/upload',
  dmrssUpload: 'dmrss/upload',
  ivaUpload: 'iva/upload'
};

export async function uploadZipFile(file, endpoint, filename = 'file') {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  // Fake form submission
  const data = new FormData();
  data.append(filename, file);
  console.log('Uploading', file);
  const response = await fetch(BE_ADDR + endpoint, {
    method: 'POST',
    headers: {
      // Accept: 'application/json',
      /* Explicitly omit content-type header, otherwise the browser sets the wrong boundary
       * https://stackoverflow.com/questions/35192841/fetch-post-with-multipart-form-data#comment91367674_40714217
       */
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: data
  });
  return await handleResponse(response);
}
