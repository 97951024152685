import ExcelJS from 'exceljs';
import moment from 'moment';

export async function exportDMRSS(scraperResult, report) {
  let workbook = new ExcelJS.Workbook();

  workbook.creator = 'Vicki Finpartner';
  workbook.lastModifiedBy = 'Vicki Finpartner';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible'
    }
  ];

  let worksheet = workbook.addWorksheet('Table Data');

  worksheet.sort = true;

  worksheet.columns = [
    {
      header: 'Index',
      key: 'index',
      width: 20,
      bgColor: { argb: 'FFFF0000' }
    },
    { header: 'Ficheiro', key: 'file', width: 20 },
    { header: 'Status', key: 'status', width: 20 },
    {
      header: 'Data de Substituição',
      key: 'substitutionDate',
      width: 20,
      style: { numFmt: '0.0%' }
    },
    { header: 'Erros', key: 'errors', width: 150 }
  ];

  formatColors(worksheet);

  // RESULT
  const scraperRow = worksheet.addRow({
    index: 'Resultado robot : ' + scraperResult?.status?.toUpperCase()
  });

  scraperRow.alignment = {
    vertical: 'middle',
    horizontal: 'left',
    wrapText: true
  };

  scraperRow.eachCell(cell => {
    cell.font = {
      bold: true
    };
  });

  worksheet.addRow({});
  worksheet.addRow({});

  // DMR LIST

  report?.forEach(item => {
    const row = worksheet.addRow({
      index: item.index,
      file: item.filename,
      status: item.status,
      substitutionDate: item.substitutionDate,
      errors: item.errors
        ?.map(
          err =>
            `Linha: ${err?.line} \nCódigo: ${err?.code} \nDescrição: ${err?.description} \nDescrição Linha: ${err?.lineDescription}`
        )
        ?.join('\n\n')
    });

    row.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

    // row.eachCell(cell => {
    //   cell.font = {
    //     bold: true
    //   };
    // });
  });

  await downloadExcel(workbook);
}

// const maybePrintMoment = (date, format = 'DD-MM-YYYY') => {
//   if (!date) {
//     return '';
//   }
//   const mmt = moment(date);
//   if (mmt.isValid()) {
//     return mmt.format(format);
//   }
//   return date;
// };

function formatColors(worksheet) {
  //Paint in gray from A1 to G1
  ['A1', 'B1', 'C1', 'D1'].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'cccccc' }
    };
    worksheet.getCell(key).font = {
      size: 13,
      underline: true,
      bold: true
    };
  });
  //create filters
  worksheet.autoFilter = {
    from: 'A1',
    to: 'G1'
  };
  //Paint E1 in *color*
  ['E1'].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FAAFA0' }
    };
    worksheet.getCell(key).font = {
      size: 13,
      underline: true,
      bold: false
    };
  });
}

async function downloadExcel(workbook) {
  try {
    let blob = await workbook2Blob(workbook);

    let filename =
      'DMRSS_Vicki_Report_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
    return anchorDownload(blob, filename);
  } catch (err) {
    console.error(err);
    return null;
  }
}

const workbook2Blob = async workbook => {
  let buffer = await workbook.xlsx.writeBuffer();
  let blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
};
const anchorDownload = (blob, filename) => {
  let a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
};
