import parser, { j2xParser as JSONparser } from 'fast-xml-parser';

export function processAnexoJ(anexoj, errors, finalJSONFile) {
  console.log('Found anexo J', anexoj);

  let quadros = Object.keys(anexoj);

  //first we must know who is the "Titular do Rendimento"

  let nifTitular = '';
  let sujeitoPassivoA = '';
  let sujeitoPassivoB = '';

  //CHANGE AnexoJq03C03 according to JSON
  if (anexoj?.['3A3']?.[0]?.['Titular']) {
    nifTitular = anexoj['3A3'][0]['Titular'];
  }

  if (anexoj?.['301']?.[0]?.['Sujeito Passivo A']) {
    //Sujeito passivo A
    sujeitoPassivoA = anexoj['301'][0]['Sujeito Passivo A'];
  }

  if (anexoj?.['302']?.[0]?.['Sujeito Passivo B']) {
    //Sujeito passivo B
    sujeitoPassivoB = anexoj['302'][0]['Sujeito Passivo B'];
  }

  let anexoJTemporaryData = {
    //this temporary obj will be filled with all data. in the end is pushed to "final" IRS
    '@_id': nifTitular,
    // Quadro02: [{ AnexoJq02C01: 2020 }],
    Quadro01: '',
    Quadro02: '',
    Quadro03: [
      {
        AnexoJq03C01: sujeitoPassivoA,
        AnexoJq03C02: sujeitoPassivoB,
        AnexoJq03C03: nifTitular
      }
    ],
    Quadro04: '',
    Quadro05: '',
    Quadro06: '',
    Quadro07: '',
    Quadro08: '',
    Quadro09: {
      AnexoJq091AT01: '',
      AnexoJq091BT01: '',
      AnexoJq092AT01: '',
      AnexoJq092BT01: ''
    },
    Quadro10: '',
    Quadro11: {}
  };

  //console.log('temporaryData', temporaryData);

  quadros.forEach(quadro => {
    if (quadro === '4A') {
      fillQuadro04(anexoj, anexoJTemporaryData);
    }

    if (quadro === '5A') {
      fillQuadro05(anexoj, anexoJTemporaryData);
    }

    if (quadro === '6A') {
      fillQuadro06(anexoj, anexoJTemporaryData);
    }

    if (quadro === '7A') {
      fillQuadro07(anexoj, anexoJTemporaryData);
    }

    if (quadro === '8A') {
      fillQuadro08(anexoj, anexoJTemporaryData);
    }

    if (quadro === '91A') {
      fillQuadro91A(anexoj, anexoJTemporaryData);
    }

    if (quadro === '91B') {
      fillQuadro91B(anexoj, anexoJTemporaryData);
    }
    if (quadro === '92A') {
      fillQuadro92A(anexoj, anexoJTemporaryData);
    }

    if (quadro === '92B') {
      fillQuadro92B(anexoj, anexoJTemporaryData);
    }

    if (quadro === '111') {
      fillQuadro11_1(anexoj, anexoJTemporaryData);
    }
    if (quadro === '112') {
      fillQuadro11_2(anexoj, anexoJTemporaryData);
    }

    //categoria are same as "quadro"

    // if (quadro === 'client') {
    // }
  });
  finalJSONFile.AnexoJ.push(anexoJTemporaryData);

  return {};
}

function fillQuadro04(anexoj, anexoJTemporaryData) {
  let somaRendimentoBruto = 0;
  let somaImpostoPagoEstrangeiro = 0;
  let somaContribSocial = 0;
  let somaRetencaoNaFonte = 0;
  let somaRetencaoSobretaxa = 0;

  let tableQuadro4A = anexoj['4A'].map((entry, index) => {
    let CodRendimento = entry['Código rendimento'] ?? '';
    let CodPais = entry['País da fonte'] ?? '';
    let RendimentoBruto = entry['Rendimento bruto'] ?? '';
    let ContribSocial = entry['Contribuições regimes proteção social'] ?? '';
    let ImpostoPagoEstrangeiro = entry['Imposto Pago Estrangeiro'] ?? '';
    let Nif = entry['NIF'] ?? '';
    let RetencaoFonte = entry['Retenção na fonte'] ?? '';
    let RetencaoSobretaxa = entry['Retenção da sobretaxa'] ?? '';

    if (RendimentoBruto) {
      somaRendimentoBruto += RendimentoBruto;
    }
    if (ImpostoPagoEstrangeiro) {
      somaImpostoPagoEstrangeiro += ImpostoPagoEstrangeiro;
    }
    if (ContribSocial) {
      somaContribSocial += ContribSocial;
    }
    if (RetencaoFonte) {
      somaRetencaoNaFonte += RetencaoFonte;
    }
    if (RetencaoSobretaxa) {
      somaRetencaoSobretaxa += RetencaoSobretaxa;
    }

    return {
      '@_numero': index + 1,
      NLinha: 401 + index,
      CodRendimento: CodRendimento,
      CodPais: CodPais,
      RendimentoBruto: RendimentoBruto,
      ContribSocial: ContribSocial,
      NIF: Nif,
      ImpostoPagoEstrangeiro: ImpostoPagoEstrangeiro,
      RetencaoFonte: RetencaoFonte,
      RetencaoSobretaxa: RetencaoSobretaxa
    };
  });

  anexoJTemporaryData.Quadro04 = [
    {
      AnexoJq04AT01: [
        {
          'AnexoJq04AT01-Linha': tableQuadro4A
        }
      ],
      AnexoJq04AT01SomaC01: [somaRendimentoBruto],
      AnexoJq04AT01SomaC02: [somaContribSocial],
      AnexoJq04AT01SomaC03: [somaImpostoPagoEstrangeiro],
      AnexoJq04AT01SomaC04: [somaRetencaoNaFonte],
      AnexoJq04AT01SomaC05: [somaRetencaoSobretaxa]
    }
  ];
}
function fillQuadro05(anexoj, anexoJTemporaryData) {
  let somaRendimentoBruto = 0;
  let somaImpostoPagoEstrangeiro = 0;
  let somaContribSocial = 0;

  let tableQuadro5 = anexoj['5A'].map((entry, index) => {
    let RendimentoBruto = entry['Rendimento bruto'] ?? '';
    let ImpostoPagoEstrangeiro = entry['Imposto pago no estrangeiro'] ?? '';
    let ContribSocial = entry['Contribuições regimes proteção social'] ?? '';
    let CodRendimento = entry['Código rendimento'] ?? '';
    let CodPais = entry['País da fonte'] ?? '';
    let Nif = entry['NIF'] ?? '';

    if (RendimentoBruto) {
      somaRendimentoBruto += RendimentoBruto;
    }
    if (ImpostoPagoEstrangeiro) {
      somaImpostoPagoEstrangeiro += ImpostoPagoEstrangeiro;
    }
    if (ContribSocial) {
      somaContribSocial += ContribSocial;
    }

    return {
      '@_numero': index + 1,
      NLinha: 501 + index,
      CodRendimento: CodRendimento,
      CodPais: CodPais,
      RendimentoBruto: RendimentoBruto,
      ContribSocial: ContribSocial,
      NIF: Nif,
      ImpostoPagoEstrangeiro: ImpostoPagoEstrangeiro
    };
  });

  anexoJTemporaryData.Quadro05 = [
    {
      AnexoJq05AT01: [
        {
          'AnexoJq05AT01-Linha': tableQuadro5
        }
      ],
      AnexoJq05AT01SomaC01: [somaRendimentoBruto],
      AnexoJq05AT01SomaC02: [somaContribSocial],
      AnexoJq05AT01SomaC03: [somaImpostoPagoEstrangeiro]
      // AnexoJq05AT01SomaC04: [somaRetencaoNaFonte],
      // AnexoJq05AT01SomaC05: [somaRetencaoSobretaxa]
    }
  ];
}
function fillQuadro06(anexoj, anexoJTemporaryData) {
  let somaRendimento = 0;
  let somaImpostoPagoEstrangeiro = 0;
  let somaContribSocial = 0;
  let somaRetencaoNaFonte = 0;

  let tableQuadro6 = anexoj['6A'].map((entry, index) => {
    let CodRendimento = entry['Código Rendimento'] ?? '';
    let CodPais = entry['País da fonte'] ?? '';
    let EstabelecimentoEstavelFixo = entry['Estabelecimento estável'] ?? '';
    let Rendimento = entry['Rendimento'] ?? '';
    let ContribSocial = entry['Contibuições regimes proteção social'] ?? '';
    let ImpostoPagoEstrangeiro = entry['Imposto pago no estrangeiro'] ?? '';

    let NIFEntRetentora = entry['NIF da entidade retentora'] ?? '';
    let RetencaoFonte = entry['Retenção na fonte'] ?? '';

    if (Rendimento) {
      somaRendimento += Rendimento;
    }
    if (ImpostoPagoEstrangeiro) {
      somaImpostoPagoEstrangeiro += ImpostoPagoEstrangeiro;
    }
    if (ContribSocial) {
      somaContribSocial += ContribSocial;
    }
    if (RetencaoFonte) {
      somaRetencaoNaFonte += RetencaoFonte;
    }

    return {
      '@_numero': index + 1,
      NLinha: 601 + index,
      CodRendimento: CodRendimento,
      CodPais: CodPais,
      EstabelecimentoEstavelFixo: EstabelecimentoEstavelFixo,
      Rendimento: Rendimento,
      ContribSocial: ContribSocial,
      NIFEntRetentora: NIFEntRetentora,
      ImpostoPagoEstrangeiro: ImpostoPagoEstrangeiro,
      RetencaoFonte: RetencaoFonte
    };
  });

  anexoJTemporaryData.Quadro06 = [
    {
      AnexoJq06AT01: [
        {
          'AnexoJq06AT01-Linha': tableQuadro6
        }
      ],
      AnexoJq06AT01SomaC01: [somaRendimento],
      AnexoJq06AT01SomaC02: [somaContribSocial],
      AnexoJq06AT01SomaC03: [somaImpostoPagoEstrangeiro],
      AnexoJq06AT01SomaC04: [somaRetencaoNaFonte]
      // AnexoJq04AT01SomaC05: [somaRetencaoSobretaxa]
    }
  ];
}
function fillQuadro07(anexoj, anexoJTemporaryData) {
  let somaRendimentoLiquido = 0;
  let somaImpostoPagoEstrangeiro = 0;

  let tableQuadro7 = anexoj['7A'].map((entry, index) => {
    let CodRendimento = entry['Código rendimento'] ?? '';
    let CodPais = entry['País da fonte'] ?? '';
    let RendimentoLiquido = entry['Rendimento Líquido'] ?? '';
    let ImpostoPagoEstrangeiro = entry['Imposto pago no estrangeiro'] ?? '';

    if (RendimentoLiquido) {
      somaRendimentoLiquido += RendimentoLiquido;
    }
    if (ImpostoPagoEstrangeiro) {
      somaImpostoPagoEstrangeiro += ImpostoPagoEstrangeiro;
    }

    return {
      '@_numero': index + 1,
      NLinha: 701 + index,
      CodRendimento: CodRendimento,
      CodPais: CodPais,
      RendimentoLiquido: RendimentoLiquido,
      ImpostoPagoEstrangeiro: ImpostoPagoEstrangeiro
    };
  });

  anexoJTemporaryData.Quadro07 = [
    {
      AnexoJq07AT01: [
        {
          'AnexoJq07AT01-Linha': tableQuadro7
        }
      ],
      AnexoJq07AT01SomaC01: [somaRendimentoLiquido],
      AnexoJq07AT01SomaC02: [somaImpostoPagoEstrangeiro]
    }
  ];
}
function fillQuadro08(anexoj, anexoJTemporaryData) {
  //initialize anexo J on JSON
  let somaRendimentoBruto = 0;
  let somaImpostoPagoEstrangeiroPaisFonte = 0;
  let somaImpostoPagoEstrangeiroImpostoRetido = 0;
  let somaRetencaoFonte = 0;

  //add table values
  let tableQuadro08 = anexoj['8A'].map((entry, index) => {
    //Código Rendimento	País da fonte	Rendimento Bruto	No país da Fonte	Cód. País	Imposto retido	NIF da entidade retentora	Retenção na fonte

    // a line of the table

    let CodRendimento = entry['Código rendim'] ?? '';
    let CodPais = entry['País da Fonte'] ?? '';
    let RendimentoBruto = entry['Rendimento Bruto'] ?? '';
    let ImpostoPagoEstrangeiroPaisFonte = entry['No país da Fonte'] ?? '';
    let ImpostoPagoEstrangeiroCodPaisPagador = entry['Cód. País'] ?? '';
    let ImpostoPagoEstrangeiroImpostoRetido = entry['Imposto retido'] ?? '';
    let NIFEntRetentora = entry['NIF da entidade retentora'] ?? '';
    let RetencaoFonte = entry['Retenção na fonte'] ?? '';

    if (RendimentoBruto) {
      somaRendimentoBruto += RendimentoBruto;
    }
    if (ImpostoPagoEstrangeiroPaisFonte) {
      somaImpostoPagoEstrangeiroPaisFonte += ImpostoPagoEstrangeiroPaisFonte;
    }
    if (ImpostoPagoEstrangeiroImpostoRetido) {
      somaImpostoPagoEstrangeiroImpostoRetido += ImpostoPagoEstrangeiroImpostoRetido;
    }
    if (RetencaoFonte) {
      somaRetencaoFonte += RetencaoFonte;
    }

    return {
      '@_numero': index + 1,
      NLinha: 801 + index,
      CodRendimento: CodRendimento,
      CodPais: CodPais,
      RendimentoBruto: RendimentoBruto,
      ImpostoPagoEstrangeiroPaisFonte: ImpostoPagoEstrangeiroPaisFonte,
      ImpostoPagoEstrangeiroCodPaisPagador: ImpostoPagoEstrangeiroCodPaisPagador,
      ImpostoPagoEstrangeiroImpostoRetido: ImpostoPagoEstrangeiroImpostoRetido,
      NIFEntRetentora: NIFEntRetentora,
      RetencaoFonte: RetencaoFonte
    };
  });

  anexoJTemporaryData.Quadro08 = [
    {
      AnexoJq08AT01: [
        {
          'AnexoJq08AT01-Linha': tableQuadro08
        }
      ],
      AnexoJq08AT01SomaC01: [somaRendimentoBruto],
      AnexoJq08AT01SomaC02: [somaImpostoPagoEstrangeiroPaisFonte],
      AnexoJq08AT01SomaC03: [somaImpostoPagoEstrangeiroImpostoRetido],
      AnexoJq08AT01SomaC04: [somaRetencaoFonte]
    }
  ];
  //console.log('finalJSONFile2', finalJSONFile);
}

function fillQuadro91A(anexoj, anexoJTemporaryData) {
  //initialize anexo J on JSON
  let somaValorAquisicao = 0;
  let somaValorRealizacao = 0;
  let somaDespesasEncargos = 0;
  let somaImpostoPagoNoEstrangeiro = 0;

  //add table values
  let tableQuadro08 = anexoj['91A'].map((entry, index) => {
    //Código Rendimento	País da fonte	Rendimento Bruto	No país da Fonte	Cód. País	Imposto retido	NIF da entidade retentora	Retenção na fonte
    // a line of the table

    let CodPais = entry['País da Fonte'] ?? '';
    //let CodRendimento = entry['Código'] ?? '';
    let AnoRealizacao = entry['Realização Ano'] ?? '';
    let MesRealizacao = entry['Realização Mês'] ?? '';
    let ValorRealizacao = entry['Realização Valor'] ?? '';
    let AnoAquisicao = entry['Aquisição Ano'] ?? '';
    let MesAquisicao = entry['Aquisição Mês'] ?? '';
    let ValorAquisicao = entry['Aquisição Valor'] ?? '';
    //let ValorAquisicao = entry['Aquisição Encargos'] ?? '';
    let DespesasEncargos = entry['Despesas e encargos'] ?? '';
    let ImpostoPagoNoEstrangeiro = entry['Imposto estrangeiro'] ?? '';

    if (ValorAquisicao) {
      somaValorAquisicao += ValorAquisicao;
    }
    if (ValorRealizacao) {
      somaValorRealizacao += ValorRealizacao;
    }
    if (DespesasEncargos) {
      somaDespesasEncargos += DespesasEncargos;
    }
    if (ImpostoPagoNoEstrangeiro) {
      somaImpostoPagoNoEstrangeiro += ImpostoPagoNoEstrangeiro;
    }

    return {
      '@_numero': index + 1,
      NLinha: 901 + index,
      CodPais: CodPais,
      AnoRealizacao: AnoRealizacao,
      MesRealizacao: MesRealizacao,
      ValorRealizacao: ValorRealizacao,
      AnoAquisicao: AnoAquisicao,
      MesAquisicao: MesAquisicao,
      ValorAquisicao: ValorAquisicao,
      DespesasEncargos: DespesasEncargos,
      ImpostoPagoNoEstrangeiro: ImpostoPagoNoEstrangeiro
    };
  });

  anexoJTemporaryData.Quadro09.AnexoJq091AT01 = [
    {
      'AnexoJq091AT01-Linha': tableQuadro08
    }
  ];

  anexoJTemporaryData.Quadro09.AnexoJq091AT01SomaC01 = [somaValorAquisicao];
  anexoJTemporaryData.Quadro09.AnexoJq091AT01SomaC02 = [somaValorRealizacao];
  anexoJTemporaryData.Quadro09.AnexoJq091AT01SomaC03 = [somaDespesasEncargos];
  anexoJTemporaryData.Quadro09.AnexoJq091AT01SomaC04 = [
    somaImpostoPagoNoEstrangeiro
  ];
  //console.log('finalJSONFile2', finalJSONFile);
}
function fillQuadro91B(anexoj, anexoJTemporaryData) {
  let somaRendimentoBruto = 0;
  let somaImpostoPagoEstrangeiro = 0;

  let tableQuadro7 = anexoj['91B'].map((entry, index) => {
    let CodRendimento = entry['Código rendimento'] ?? '';
    let CodPais = entry['País da fonte'] ?? '';
    let RendimentoBruto = entry['Rendimento bruto'] ?? '';
    let ImpostoPagoEstrangeiro = entry['Imposto pago no estrangeiro'] ?? '';

    if (RendimentoBruto) {
      somaRendimentoBruto += RendimentoBruto;
    }
    if (ImpostoPagoEstrangeiro) {
      somaImpostoPagoEstrangeiro += ImpostoPagoEstrangeiro;
    }

    return {
      '@_numero': index + 1,
      NLinha: 921 + index,
      CodRendimento: CodRendimento,
      CodPais: CodPais,
      RendimentoBruto: RendimentoBruto,
      ImpostoPagoEstrangeiro: ImpostoPagoEstrangeiro
    };
  });

  anexoJTemporaryData.Quadro09.AnexoJq091BT01 = [
    {
      'AnexoJq091BT01-Linha': tableQuadro7
    }
  ];
  anexoJTemporaryData.Quadro09.AnexoJq091BT01SomaC01 = [somaRendimentoBruto];
  anexoJTemporaryData.Quadro09.AnexoJq091BT01SomaC02 = [
    somaImpostoPagoEstrangeiro
  ];
}

function fillQuadro92A(anexoj, anexoJTemporaryData) {
  //initialize anexo J on JSON
  let somaValorAquisicao = 0;
  let somaValorRealizacao = 0;
  let somaDespesasEncargos = 0;
  let somaImpostoPagoNoEstrangeiro = 0;

  //add table values
  let tableQuadro92A = anexoj['92A'].map((entry, index) => {
    //Código Rendimento	País da fonte	Rendimento Bruto	No país da Fonte	Cód. País	Imposto retido	NIF da entidade retentora	Retenção na fonte
    // a line of the table

    let CodPais = entry['País da Fonte'] ?? '';
    let Codigo = entry['Código'] ?? '';
    let AnoRealizacao = entry['Realização Ano'] ?? '';
    let MesRealizacao = entry['Realização Mês'] ?? '';
    let DiaRealizacao = entry['Realização Dia'] ?? '';
    let ValorRealizacao = entry['Realização Valor'] ?? '';
    let AnoAquisicao = entry['Aquisição Ano'] ?? '';
    let MesAquisicao = entry['Aquisição Mês'] ?? '';
    let DiaAquisicao = entry['Aquisição Dia'] ?? '';
    let ValorAquisicao = entry['Aquisição Valor'] ?? '';
    let DespesasEncargos = entry['Despesas e Encargos'] ?? '';
    let ImpostoPagoNoEstrangeiro = entry['Imposto estrangeiro'] ?? '';
    let CodPaisContraparte = entry['País da contraparte'] ?? '';

    if (ValorAquisicao) {
      somaValorAquisicao += ValorAquisicao;
    }
    if (ValorRealizacao) {
      somaValorRealizacao += ValorRealizacao;
    }
    if (DespesasEncargos) {
      somaDespesasEncargos += DespesasEncargos;
    }
    if (ImpostoPagoNoEstrangeiro) {
      somaImpostoPagoNoEstrangeiro += ImpostoPagoNoEstrangeiro;
    }

    return {
      '@_numero': index + 1,
      NLinha: 951 + index,
      CodPais: CodPais,
      Codigo: Codigo,
      AnoRealizacao: AnoRealizacao,
      MesRealizacao: MesRealizacao,
      DiaRealizacao,
      ValorRealizacao: ValorRealizacao,
      AnoAquisicao: AnoAquisicao,
      MesAquisicao: MesAquisicao,
      DiaAquisicao,
      ValorAquisicao: ValorAquisicao,
      DespesasEncargos: DespesasEncargos,
      ImpostoPagoNoEstrangeiro: ImpostoPagoNoEstrangeiro,
      CodPaisContraparte: CodPaisContraparte
    };
  });

  anexoJTemporaryData.Quadro09.AnexoJq092AT01 = [
    {
      'AnexoJq092AT01-Linha': tableQuadro92A
    }
  ];

  anexoJTemporaryData.Quadro09.AnexoJq092AT01SomaC01 = [somaValorRealizacao];
  anexoJTemporaryData.Quadro09.AnexoJq092AT01SomaC02 = [somaValorAquisicao];
  anexoJTemporaryData.Quadro09.AnexoJq092AT01SomaC03 = [somaDespesasEncargos];
  anexoJTemporaryData.Quadro09.AnexoJq092AT01SomaC04 = [
    somaImpostoPagoNoEstrangeiro
  ];
  //console.log('finalJSONFile2', finalJSONFile);
}
function fillQuadro92B(anexoj, anexoJTemporaryData) {
  let somaRendimentoLiquido = 0;
  let somaImpostoPagoEstrangeiro = 0;

  let tableQuadro92B = anexoj['92B'].map((entry, index) => {
    let CodRendimento = entry['Código rendimento'] ?? '';
    let CodPais = entry['País da fonte'] ?? '';
    let RendimentoLiquido = entry['Rendimento liquido'] ?? '';
    let ImpostoPagoEstrangeiro = entry['Imposto pago no estrangeiro'] ?? '';
    let CodPaisContraparte = entry['País da contraparte'] ?? '';

    if (RendimentoLiquido) {
      somaRendimentoLiquido += RendimentoLiquido;
    }
    if (ImpostoPagoEstrangeiro) {
      somaImpostoPagoEstrangeiro += ImpostoPagoEstrangeiro;
    }

    return {
      '@_numero': index + 1,
      NLinha: 991 + index,
      CodRendimento: CodRendimento,
      CodPais: CodPais,
      RendimentoLiquido: RendimentoLiquido,
      ImpostoPagoEstrangeiro: ImpostoPagoEstrangeiro,
      CodPaisContraparte: CodPaisContraparte
    };
  });

  anexoJTemporaryData.Quadro09.AnexoJq092BT01 = [
    {
      'AnexoJq092BT01-Linha': tableQuadro92B
    }
  ];
  anexoJTemporaryData.Quadro09.AnexoJq092BT01SomaC01 = [somaRendimentoLiquido];
  anexoJTemporaryData.Quadro09.AnexoJq092BT01SomaC02 = [
    somaImpostoPagoEstrangeiro
  ];
}
function fillQuadro11_1(anexoj, anexoJTemporaryData) {
  let AnexoJq11T01 = anexoj['111'].map((entry, index) => {
    let Iban = entry['IBAN'] ?? '';
    let Bic = entry['BIC'] ?? '';

    return {
      '@_numero': index + 1,
      Iban: Iban,
      Bic: Bic
    };
  });

  anexoJTemporaryData.Quadro11.AnexoJq11T01 = [
    {
      'AnexoJq11T01-Linha': AnexoJq11T01
    }
  ];
}
function fillQuadro11_2(anexoj, anexoJTemporaryData) {
  let AnexoJq11T02 = anexoj['112'].map((entry, index) => {
    let OutrosNumIdentificacao = entry['Outros IDs'] ?? '';

    return {
      '@_numero': index + 1,
      OutrosNumIdentificacao: OutrosNumIdentificacao
    };
  });

  anexoJTemporaryData.Quadro11.AnexoJq11T02 = [
    {
      'AnexoJq11T02-Linha': AnexoJq11T02
    }
  ];
}
