import React from 'react';
import { Form, Button, Input, message } from 'antd';
import DetailedMessage from '../util/DetailedMessage';
import globalStyles from '../../styles/global';

const styles = {
  container: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline,
    marginTop: globalStyles.global.baseline
  },
  form: {
    body: {
      width: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    section: {
      width: '100%',
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    item: {
      paddingRight: globalStyles.global.baseline,
      lineHeight: globalStyles.global.baseline * 2.5
    }
  }
};

class InsertClient extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = { sending: false };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ sending: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          //console.log('Received values of form: ', values);
          if (this.props.action) {
            const request = await this.props.action(values);

            if (request?.result === 'OK') {
              if (this.props.done) {
                this.props.done();
              } else {
                message.success(<span>Sucesso</span>);
              }
              //clear form:
              this.props.form.resetFields();
            } else {
              throw request;
            }
          }
        } catch (err) {
          if (err.message?.includes?.('E11000')) {
            message.error(
              <span>NIF duplicado - criação de cliente abortada.</span>
            );
          } else {
            DetailedMessage.error(
              'Erro não especificado' +
                (err.message ? ' (' + err.message + ')' : '.') +
                ' Informação técnica:',
              err
            );
          }
        }
      }
      this.setState({ sending: false });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={styles.container}>
        <Form onSubmit={this.handleSubmit} style={styles.form.body}>
          <div style={styles.form.section}>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('nif', {
                rules: [{ required: true, message: 'Obrigatório' }]
              })(<Input disabled={this.state.sending} placeholder="NIF" />)}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('name', {
                rules: [{ required: false }]
              })(<Input disabled={this.state.sending} placeholder="Nome" />)}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('primavera', {
                rules: [{ required: false }]
              })(
                <Input
                  disabled={this.state.sending}
                  placeholder="Nome Primavera"
                />
              )}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('at_username', {
                rules: [{ required: true, message: 'Obrigatório' }]
              })(
                <Input
                  disabled={this.state.sending}
                  placeholder="Utilizador AT"
                />
              )}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('at_password', {
                rules: [{ required: true, message: 'Obrigatório' }]
              })(
                <Input.Password
                  disabled={this.state.sending}
                  placeholder="Password AT"
                />
              )}
            </Form.Item>

            <Form.Item style={{ ...styles.form.item, width: '45%' }}>
              {getFieldDecorator('notes', {
                rules: [{ required: false }]
              })(
                <Input.TextArea
                  autoSize
                  disabled={this.state.sending}
                  style={{ width: '100%' }}
                  placeholder="Notas"
                />
              )}
            </Form.Item>
            {/* <Form.Item style={styles.form.item}>
              {getFieldDecorator('info_to_send', {
                rules: [{ required: true, message: 'Inválido' }]
              })(
                <Select
                  disabled={this.state.sending}
                  style={{ minWidth: '10rem' }}
                  placeholder="Info a enviar"
                >
                  <Option value="just_report">Relatório</Option>
                  <Option value="just_invoices">Facturas</Option>
                  <Option value="send_both">Ambos</Option>
                </Select>
              )}
            </Form.Item> 

            <Form.Item style={{ ...styles.form.item, display: 'none' }}>
              {getFieldDecorator('client', {
                initialValue: null
              })(<Input />)}
            </Form.Item>*/}
            <Form.Item style={{ ...styles.form.item, width: '10%' }}>
              <Button
                loading={this.state.sending}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Submeter
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}
const InsertDossierForm = Form.create({ name: 'insert_client' })(InsertClient);
export default InsertDossierForm;
