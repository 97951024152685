import React from 'react';
import autobind from 'auto-bind/react';
import { Button, Modal, Icon } from 'antd';
import moment from 'moment';
import ClientTable, {
  scopes as clientScopes
} from '../../../components/table/ClientTable';

import { anchorDownload, mrg } from '../../../common/util';
import {
  prepareWorkbook,
  workbook2Blob,
  prepareExcelData,
  insertExcelData
} from '../excelReports';
import globalStyles from '../../../styles/global';

const { confirm } = Modal;

const styles = {
  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline
  },
  collapse: {
    position: 'relative',
    width: '100%'
  },
  pageHeaderBox: {
    color: globalStyles.colors.background.pink
  },
  pageHeaderButton: {
    width: 'auto',
    height: '46px',
    fontWeight: 100,
    fontSize: globalStyles.global.baseline * 1.5,
    marginLeft: globalStyles.global.baseline,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter,
    ...globalStyles.layout.alignCenter
  },
  result: {
    width: '100%',
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  modal: {
    body: {
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: globalStyles.global.baseline,
      paddingTop: globalStyles.global.baseline,
      paddingBottom: globalStyles.global.baseline * 2,
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      textAlign: 'left',
      fontSize: globalStyles.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: globalStyles.global.baseline
    },
    message: {
      marginBottom: globalStyles.global.baseline
    },
    error: {
      marginBottom: globalStyles.global.baseline,
      color: globalStyles.colors.feedback.error
    }
  },
  feedback: {
    title: {
      fontSize: globalStyles.global.baseline * 3,
      fontWeight: 100,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    },
    info: {
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none'
    },
    code: {
      fontSize: globalStyles.global.baseline,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none',
      fontFamily: 'monospace',
      textAlign: 'left'
    },
    all: {
      zIndex: 20,
      width: '100%',
      height: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    }
  }
};

const excelColumns = [
  { header: 'NIF', key: 'nif', dataPath: 'nif', width: 20 },
  {
    header: 'Notas',
    key: 'notes',
    dataPath: 'notes',
    width: 20
  },
  {
    header: 'Data de Criação',
    key: 'createdAt',
    dataPath: 'createdAt',
    width: 20
  },
  {
    header: 'Resultado',
    key: 'send_result',
    dataPath: 'send_result',
    width: 20
  },
  {
    header: 'Alertas',
    key: 'warning',
    dataPath: 'warning',
    width: 20
  }
];

export default class Consistency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasErrors: true
    };
    autobind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const hasErrors =
      props.report && props.report.filter(elem => elem.has_errors).length > 0;
    return { hasErrors: hasErrors };
  }

  async generateReportFile() {
    const { workbook, worksheet } = prepareWorkbook({
      worksheetColumns: excelColumns,
      sheetTitle: 'SAFT Delivery Report'
    });

    const reportData = !this.props.report
      ? []
      : prepareExcelData(this.props.report, excelColumns);

    insertExcelData(worksheet, reportData);

    try {
      const blob = await workbook2Blob(workbook);

      const filename =
        'saft_report_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
      anchorDownload(blob, filename);
    } catch (err) {
      console.error(err);
    }
  }

  confirmReset() {
    const self = this;
    confirm({
      okButtonProps: { ghost: true },
      title: (
        <span style={{ fontSize: globalStyles.global.baseline * 2 }}>
          Fechar processo de envio
        </span>
      ),
      icon: (
        <Icon
          type="warning"
          style={{
            fontSize: globalStyles.global.baseline * 2.5,
            color: globalStyles.colors.feedback.error
          }}
        />
      ),
      content: (
        <div style={styles.modal.body}>
          <span style={styles.modal.message}>
            Tem certeza de que pretende terminar o processo de envio?
          </span>
          <ul style={styles.modal.list}>
            <li style={styles.modal.error}>
              Todos os utilizadores serão redireccionados para o ecran inicial.
            </li>
            <li style={styles.modal.error}>
              Toda a informação, resultados e ficheiros do processo corrente
              serão apagados.
            </li>
            <li style={{ ...styles.modal.error, fontWeight: 700 }}>
              Esta operação é irreversível!
            </li>
          </ul>
        </div>
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        if (self.props.reset) {
          self.props.reset();
        }
      }
    });
  }

  render() {
    return (
      <>
        {this.props.report ? (
          <div style={styles.result}>
            <div
              style={mrg([
                styles.pageHeaderContainer,
                {
                  justifyContent: this.state.hasErrors
                    ? 'space-between'
                    : 'flex-end'
                }
              ])}
            >
              <div style={styles.pageHeaderContainer}>
                <Button
                  type="primary"
                  ghost
                  style={styles.pageHeaderButton}
                  onClick={this.generateReportFile}
                >
                  Descarregar Relatório
                </Button>
              </div>

              <Button
                type="danger"
                ghost
                style={styles.pageHeaderButton}
                onClick={this.confirmReset}
              >
                FECHAR PROCESSO
              </Button>
            </div>

            <ClientTable
              editable={false}
              scope={clientScopes.REPORT}
              clients={this.props.report}
            />
          </div>
        ) : (
          <div style={styles.feedback.all}>
            <h2 style={styles.feedback.title}>
              A compilar relatório de envios
            </h2>
            <span style={styles.feedback.info}>Aguarde</span>
          </div>
        )}
      </>
    );
  }
}
