import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';

const BE_ADDR = address;

export async function fetchFiscalRepresentations() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'fiscalrepresentations', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
