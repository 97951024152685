import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { AppContextProvider } from './context/AppContext';
import './index.css';

global.React = React;

window.addEventListener('pushstate', function(event) {
  console.log('pushstate fired');
});

ReactDOM.render(
  <HelmetProvider>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </HelmetProvider>,

  document.getElementById('root')
);
