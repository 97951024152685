import React from 'react';
import autobind from 'auto-bind';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SortDirection } from 'react-virtualized';
import { Spin, Drawer, DatePicker, Checkbox } from 'antd4';
import 'antd4/dist/antd.css';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import qs from 'qs';
import flatten from 'flat';
import op from 'object-path';
import deburr from 'lodash/deburr';

import TableHeader from './components/TableHeader';
import SortableTable from './components/SortableTable';
import FamilyTree from './components/FamilyTree';
import sort from 'fast-sort'; // eslint-disable-line

import AppToaster from './components/AppToaster';
import routes from './network/immigration';
import g from './styles/global';
import './Id.scss';
import Messaging from './components/Messaging';

export const FILTERTYPES = {
  string: 'string',
  daterange: 'daterange',
  number: 'number',
  boolean: 'boolean'
};
const { RangePicker } = DatePicker;
const moment = extendMoment(Moment);

const {
  getClients,
  postClient,
  postRelative,
  postArrayElementRelative,
  postArrayElementClient,
  deleteArrayElementClient,
  deleteArrayElementRelative,
  deleteClient,
  deleteRelative,
  putClient,
  putRelative
} = routes;

class Id extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sortBy: 'main.dossier',
      sortedList: [],
      sortDirection: SortDirection.DESC,
      showCreateForm: false,
      expandAll: false,
      showDrawer: false,

      filter: {
        nifCreationDate: { active: false, value: null },
        clientCreationDate: { active: false, value: null }
      }
    };

    autobind(this);
  }

  async componentDidMount() {
    if (!this.state.sortedList?.length) {
      await this.refresh();
    }

    await this.sort({
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection
    });
    this.setState({ loading: false });
  }

  async componentDidUpdate(prevProps, prevState) {}

  toggleCreateForm() {
    this.setState(state => ({
      showCreateForm: !state.showCreateForm
    }));
  }

  async createClient(values) {
    this.setState({ loading: true });

    const processedValues = prepareClientValues(values);
    let response = await postClient(processedValues);

    if (response.ok) {
      AppToaster.success({
        message: 'Cliente gravado'
      });

      const newClient = response.data;

      this.setState(
        state => {
          const sortedList = state.sortedList ? [...state.sortedList] : [];
          sortedList.unshift(newClient);
          this.clientCache = sortedList;
          return { sortedList, loading: false };
        },
        async () => {
          await this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        }
      );
    } else {
      AppToaster.error({
        message:
          'Erro na criação de cliente: ' +
          (response.message ?? 'Erro indeterminado')
      });
    }
    this.setState({ loading: false });
    return response; // must return result to children
  }

  async createRelative(values, parentId) {
    this.setState({ loading: true });

    const processedValues = { main: values, parentId };
    let response = await postRelative(processedValues);

    if (response.ok) {
      AppToaster.success({ message: 'Familiar gravado' });

      const newRelative = response.data;

      this.setState(
        state => {
          const sortedList = state.sortedList ? [...state.sortedList] : [];
          const target = sortedList.find(x => x._id === parentId);

          if (target && !Array.isArray(target.family)) {
            target.family = [];
          }

          target?.family?.push?.(newRelative); //eslint-disable-line no-unused-expressions

          this.clientCache = sortedList;
          return { sortedList, loading: false };
        },
        async () => {
          await this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        }
      );
    } else {
      AppToaster.error({ message: 'Erro a criar familiar' });
    }

    this.setState({ loading: false });
    return response;
  }

  async updateField(path, value, clientId, relativeId = null) {
    let response;

    if (relativeId) {
      response = await putRelative(relativeId, path, value);
    } else {
      response = await putClient(clientId, path, value);
    }

    if (response.ok) {
      if (relativeId) {
        this.updateRelative(path, response.data, clientId, relativeId);
      } else {
        this.updateClient(path, response.data, clientId, relativeId);
      }
    } else {
      AppToaster.error({ message: 'Erro a gravar campo!', timeout: 1 });
    }

    return response; // must return result to children
  }

  async addArrayElement(
    path,
    clientId,
    relativeId = undefined,
    value = undefined
  ) {
    let response;

    // console.log({ path, clientId, relativeId, value });
    if (relativeId) {
      response = await postArrayElementRelative(path, relativeId, value);
    } else {
      response = await postArrayElementClient(path, clientId, value);
    }

    if (response.ok) {
      if (relativeId) {
        this.pushToRelativeArray(
          path,
          response.data ?? value,
          clientId,
          relativeId
        );
      } else {
        this.pushToClientArray(path, response.data ?? value, clientId);
      }
      AppToaster.success({ message: 'Elemento criado', timeout: 0.8 });
    } else {
      AppToaster.error({ message: 'Erro a gravar campo!', timeout: 1 });
    }

    return response; // must return result to children
  }

  async deleteArrayElement(path, _id, clientId, relativeId = null) {
    let response;

    if (relativeId) {
      response = await deleteArrayElementRelative(path, _id, relativeId);
    } else {
      response = await deleteArrayElementClient(path, _id, clientId);
    }

    if (response.ok) {
      if (relativeId) {
        this.deleteFromRelativeArray(path, _id, clientId, relativeId);
      } else {
        this.deleteFromClientArray(path, _id, clientId);
      }
      AppToaster.success({ message: 'Elemento removido', timeout: 0.8 });
    } else {
      AppToaster.error({ message: 'Erro a gravar campo!', timeout: 1 });
    }

    return response; // must return result to children
  }

  pushToClientArray(path, element, clientId) {
    this.setState(state => {
      let clientData;
      try {
        clientData = getFieldById(this.state.sortedList, path, clientId);
      } catch (err) {
        console.error(err);
        AppToaster.info({
          message: 'Erro a actualizar familiar. Por favor recarregue a página'
        });
        return {};
      }

      const { client, clientIndex, field } = clientData;

      field.push(element);
      op.set(client, path, field);
      const result = [...state.sortedList];
      result[clientIndex] = { ...client };
      let clientCacheIndex = this.clientCache.findIndex(
        clientFound => client._id === clientFound._id
      );
      this.clientCache[clientCacheIndex] = client;

      return { sortedList: result };
    });
  }

  deleteFromClientArray(path, _id, clientId) {
    // console.log({ path, _id, clientId });
    this.setState(state => {
      let clientData;
      try {
        clientData = getFieldById(this.state.sortedList, path, clientId);
      } catch (err) {
        console.error(err);
        AppToaster.info({
          message: 'Erro a actualizar familiar. Por favor recarregue a página'
        });
        return {};
      }

      let { client, clientIndex, field } = clientData;

      field = field.filter(x => x._id !== _id);
      op.set(client, path, field);
      const result = [...state.sortedList];
      result[clientIndex] = { ...client };
      let clientCacheIndex = this.clientCache.findIndex(
        clientFound => client._id === clientFound._id
      );
      this.clientCache[clientCacheIndex] = client;

      return { sortedList: result };
    });
  }

  pushToRelativeArray(path, element, clientId, relativeId) {
    this.setState(state => {
      let clientData;
      try {
        clientData = getFieldById(
          this.state.sortedList,
          path,
          clientId,
          relativeId
        );
      } catch (err) {
        console.error(err);
        AppToaster.info({
          message: 'Erro a actualizar familiar. Por favor recarregue a página'
        });
        return {};
      }

      const {
        client,
        clientIndex,
        relative,
        relativeIndex,
        field
      } = clientData;

      field.push(element);

      client.family[relativeIndex] = { ...relative };

      const result = [...state.sortedList];
      result[clientIndex] = { ...client };
      let clientCacheIndex = this.clientCache.findIndex(
        clientFound => client._id === clientFound._id
      );
      this.clientCache[clientCacheIndex] = client;

      return { sortedList: result };
    });
  }

  deleteFromRelativeArray(path, _id, clientId, relativeId) {
    this.setState(state => {
      let clientData;
      try {
        clientData = getFieldById(
          this.state.sortedList,
          path,
          clientId,
          relativeId
        );
      } catch (err) {
        console.error(err);
        AppToaster.info({
          message: 'Erro a actualizar familiar. Por favor recarregue a página'
        });
        return {};
      }

      let { client, clientIndex, relative, relativeIndex, field } = clientData;

      // console.log({
      //   path,
      //   client,
      //   clientIndex,
      //   relative,
      //   relativeIndex,
      //   field
      // });
      field = field.filter(x => x._id !== _id);

      op.set(relative, path, field);

      client.family[relativeIndex] = { ...relative };

      const result = [...state.sortedList];
      result[clientIndex] = { ...client };
      let clientCacheIndex = this.clientCache.findIndex(
        clientFound => client._id === clientFound._id
      );
      this.clientCache[clientCacheIndex] = client;

      return { sortedList: result };
    });
  }

  updateRelative(path, value, clientId, relativeId) {
    this.setState(state => {
      let clientData;
      try {
        clientData = getFieldById(
          this.state.sortedList,
          path,
          clientId,
          relativeId
        );
      } catch (err) {
        console.error(err);
        AppToaster.info({
          message: 'Erro a actualizar familiar. Por favor recarregue a página'
        });
        return {};
      }

      let { client, clientIndex, relative, relativeIndex } = clientData;
      op.set(relative, path, value);
      client.family[relativeIndex] = { ...relative };

      const result = [...state.sortedList];
      result[clientIndex] = { ...client };
      let clientCacheIndex = this.clientCache.findIndex(
        clientFound => client._id === clientFound._id
      );
      this.clientCache[clientCacheIndex] = client;

      return { sortedList: result };
    });
  }

  updateClient(path, value, clientId) {
    this.setState(state => {
      let clientData;
      try {
        clientData = getFieldById(this.state.sortedList, path, clientId);
      } catch (err) {
        console.error(err);
        AppToaster.info({
          message: 'Erro a actualizar familiar. Por favor recarregue a página'
        });
        return {};
      }

      let { client, clientIndex } = clientData;
      op.set(client, path, value);

      // const result = [...state.sortedList];
      // result[clientIndex] = { ...client };
      // this.clientCache = result;

      const result = [...state.sortedList];
      result[clientIndex] = { ...client };
      let clientCacheIndex = this.clientCache.findIndex(
        clientFound => client._id === clientFound._id
      );
      this.clientCache[clientCacheIndex] = client;

      return { sortedList: result };
    });
  }

  async deleteClient(clientId, relativeId = null) {
    let response;
    if (relativeId) {
      response = await deleteRelative(relativeId);
    } else {
      response = await deleteClient(clientId);
    }

    if (response.ok) {
      if (relativeId) {
        this.setState(state => {
          let target = state.sortedList.find(x => x._id === clientId);
          target.family = target?.family?.filter?.(x => x._id !== relativeId);

          const result = [...state.sortedList];
          this.clientCache = result;
          return { sortedList: result };
        });
      } else {
        this.setState(state => {
          const result = state.sortedList.filter(x => x._id !== clientId);
          this.clientCache = result;
          return {
            sortedList: result
          };
        });
      }

      AppToaster.success({
        message: (relativeId ? 'Familiar' : 'Cliente') + ' eliminado'
      });
    } else {
      AppToaster.error({
        message: 'Erro a eliminar ' + (relativeId ? 'Familiar' : 'Cliente'),
        timeout: 1
      });
    }
  }

  setShowDrawer(showing) {
    this.setState({ showDrawer: showing });
  }

  filterClients(field, value) {
    //First we update the "filter object"
    let filterObject = this.state.filter;
    filterObject[field].value = value;
    this.setState({ filter: filterObject, someFilterIsActive: false });
    console.log('filterObject', filterObject);

    //activate filter flag
    Object.values(this.state.filter).forEach(value => {
      if (value.active) {
        this.setState({ someFilterIsActive: true });
      }
    });

    let filteredClients = this.clientCache;

    // console.log('filterObject', filterObject);
    //Filter Clients By client Creation Date
    if (
      this.state.filter.clientCreationDate.active &&
      filterObject.clientCreationDate.value
    ) {
      filteredClients = filteredClients.filter(client => {
        let startDate = filterObject.clientCreationDate.value[0];
        let endDate = filterObject.clientCreationDate.value[1];
        return moment(client.createdAt).isBetween(startDate, endDate);
      });
    }
    //Filter Clients By NIF Date
    if (
      this.state.filter.nifCreationDate.active &&
      filterObject.nifCreationDate.value
    ) {
      filteredClients = filteredClients.filter(client => {
        let startDate = filterObject.nifCreationDate.value[0];
        let endDate = filterObject.nifCreationDate.value[1];
        return moment(client.main.nif_data_pedido).isBetween(
          startDate,
          endDate
        );
      });
    }

    this.setState({ sortedList: filteredClients });

    console.log('filteredClients', filteredClients);
  }

  filterStyles(filter) {
    return {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      border: this.state.filter[filter].active
        ? '1px solid #1890ff'
        : '1px solid #ccc',
      backgroundColor: this.state.filter[filter].active ? '#fff' : '#f5f5f5',
      minHeight: 150,
      padding: g.global.baseline,
      marginTop: g.global.baseline
    };
  }

  renderDrawer() {
    return (
      <Drawer
        width={'40%'}
        title="Filtros de Clientes"
        placement="right"
        closable={true}
        onClose={() => this.setShowDrawer(false)}
        visible={this.state.showDrawer}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',

              justifyContent: 'center',
              alignItems: 'center',

              border: this.state.someFilterIsActive
                ? '1px solid #1890ff'
                : '1px solid #ccc',
              backgroundColor: this.state.someFilterIsActive
                ? '#fff'
                : '#f5f5f5',
              //minHeight: 150,
              padding: g.global.baseline,
              marginTop: g.global.baseline,
              cursor: 'pointer'
            }}
            onClick={() => {
              if (this.state.someFilterIsActive) {
                //reset all filters (dynamic/future proof)
                Object.keys(this.state.filter).forEach(key => {
                  this.setState(
                    state => {
                      state.filter[key] = { active: false, value: null };

                      state.someFilterIsActive = false;

                      return state;
                    },
                    () => this.filterClients(key, null)
                  );
                });
              }
            }}
          >
            Limpar todos os filtros
          </div>
          {this.renderFilterNifDate()}
          {this.renderFilterClientCreationDate()}
        </div>
      </Drawer>
    );
  }

  renderFilterNifDate() {
    return (
      <div style={this.filterStyles('nifCreationDate')}>
        {/* sorry, dynamic styles depending on active filters */}
        <div>Filtro de criação do NIF de cliente:</div>
        <RangePicker
          disabled={!this.state.filter.nifCreationDate.active}
          value={this.state.filter.nifCreationDate.value}
          onChange={rangeDate => {
            this.filterClients('nifCreationDate', rangeDate);
          }}
        />
        {this.renderFilterCheckbox('nifCreationDate')}
        {/* //just to avoid code repetition. filterCheckbox  is universal  (filter active or not active)*/}
      </div>
    );
  }

  renderFilterClientCreationDate() {
    return (
      <div style={this.filterStyles('clientCreationDate')}>
        {/* sorry, dynamic styles depending on active filters */}
        <div>Mês de criação de clientes:</div>
        <RangePicker
          disabled={!this.state.filter.clientCreationDate.active}
          picker="month"
          value={this.state.filter.clientCreationDate.value}
          onChange={rangeDate => {
            this.filterClients('clientCreationDate', rangeDate); //filter name , value to filter
          }}
        />
        {this.renderFilterCheckbox('clientCreationDate')}
        {/* //just to avoid code repetition. filterCheckbox  is universal  (filter active or not active)*/}
      </div>
    );
  }

  renderFilterCheckbox(filter) {
    return (
      <Checkbox
        checked={this.state.filter[filter].active}
        onChange={
          e => {
            if (e.target.checked) {
              this.setState(
                //set filter as active
                state => {
                  let newState = state;
                  newState.filter[filter].active = true;
                  return newState;
                },
                //and run filter
                () => this.filterClients(filter, null)
              );
            } else {
              let newFilters = this.state.filter;
              newFilters[filter].active = false;
              this.setState({ filter: newFilters }, () =>
                this.filterClients(filter, null)
              );
            }
          } //console.log(e.target.checked)
        }
      >
        Activar/Desactivar Filtro
      </Checkbox>
    );
  }

  render() {
    return (
      <div id="fp-id">
        <div className="id-meta"></div>
        <div className="id-routes d-flex flex-col justify-content-center align-items-start">
          {this.state.loading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spin size="large"></Spin>
            </div>
          ) : (
            <Switch>
              <Route
                exact
                path={'/id/'}
                render={() => (
                  <>
                    <Helmet>
                      <title>Immigration Desk</title>
                    </Helmet>
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        overflowX: 'scroll'
                      }}
                    >
                      <TableHeader
                        sortedList={this.state.sortedList}
                        expandAll={this.state.expandAll}
                        setShowDrawer={this.setShowDrawer}
                        showCreateForm={this.state.showCreateForm}
                        toggleCreateForm={this.toggleCreateForm}
                        onCreate={this.createClient}
                        searching={this.state.searching}
                        toggleExpandAll={() => {
                          this.setState(state => ({
                            expandAll: !state.expandAll
                          }));
                        }}
                        refresh={this.refresh}
                        search={this.search}
                      ></TableHeader>
                      <SortableTable
                        data={this.state.sortedList}
                        onSort={this.sort}
                        onFilter={this.filter}
                        expandAll={this.state.expandAll}
                        toggleExpandAll={() => {
                          this.setState(state => ({
                            expandAll: !state.expandAll
                          }));
                        }}
                        sortDirection={{
                          value: this.state.sortDirection,
                          toggle: () =>
                            this.setState(state => ({
                              sortDirection:
                                state.sortDirection === SortDirection.DESC
                                  ? SortDirection.ASC
                                  : SortDirection.DESC
                            }))
                        }}
                        sortBy={{
                          value: this.state.sortBy,
                          set: sortBy => this.setState({ sortBy })
                        }}
                      ></SortableTable>
                      {/* {this.renderDrawer()} */}
                    </div>
                  </>
                )}
              ></Route>
              <Route
                path={'/id/client/:parent/:descendant?'}
                render={props => {
                  const parentId = props.match?.params?.parent;
                  const target = this.state.sortedList.find(
                    x => x._id === parentId
                  );

                  const descendantId = props.match?.params?.descendant;

                  const tab = qs.parse(props.location.search, {
                    ignoreQueryPrefix: true
                  })?.tab;

                  if (!tab) {
                    return (
                      <Redirect
                        to={props.location.pathname + '?tab=1'}
                      ></Redirect>
                    );
                  }

                  if (target) {
                    return (
                      <>
                        <FamilyTree
                          parent={target}
                          descendantId={descendantId}
                          tab={tab ?? 1}
                          onDelete={this.deleteClient}
                          onCreateRelative={this.createRelative}
                          updateField={this.updateField}
                          addArrayElement={this.addArrayElement}
                          deleteArrayElement={this.deleteArrayElement}
                          search={this.search}
                        ></FamilyTree>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Helmet>
                          <title>
                            Cliente {props.match?.params?.parent ?? ''}
                          </title>
                        </Helmet>
                        <div>
                          Cliente {props.match?.params?.parent ?? ''} não
                          encontrado
                        </div>
                        <Redirect to="../.."></Redirect>
                      </>
                    );
                  }
                }}
              ></Route>

              <Route
                path={'/id/messaging'}
                render={props => (
                  <Messaging
                    something={null}
                    clients={this.clientCache}
                  ></Messaging>
                )}
              />
            </Switch>
          )}
        </div>
      </div>
    );
  }

  async refresh() {
    this.setState({ loading: true });
    let response = await getClients();

    if (!response?.ok) {
      AppToaster.error({ message: 'Erro a carregar dados!', timeout: 1 });
      this.setState({
        sortedList: []
      });
      this.clientCache = [];
    } else {
      const data = response?.data ?? [];
      AppToaster.info({
        message: `Foram obtidos ${data.length} clientes`
      });

      this.setState({
        sortedList: data
      });
      this.clientCache = data;
    }

    this.setState({ loading: false });
  }

  async sort({ sortBy, sortDirection }) {
    const sortedClients = this.sortList({
      list: this.state.sortedList,
      sortBy,
      sortDirection
    });

    this.setState({ sortBy, sortDirection, sortedList: [...sortedClients] });
  }

  filter(filters) {
    this.setState(state => {
      let result = [...this.clientCache];
      if (!result) {
        return {};
      }

      for (const dataKey in filters) {
        const filter = filters[dataKey];
        if (filter === null || typeof filter === 'undefined') {
          continue;
        }
        switch (filter.type) {
          case FILTERTYPES.string:
          //intentional fallthrough
          case FILTERTYPES.number:
            result = result.filter(
              x =>
                cleanValue(op.get(x, dataKey)).indexOf(
                  cleanValue(filter.value)
                ) !== -1
            );
            break;
          case FILTERTYPES.boolean:
            result = result.filter(x => op.get(x, dataKey) === filter.value);
            break;
          case FILTERTYPES.daterange:
            const [start, end] = filter.value;
            const range = moment.range(start, end);

            result = result.filter(x => {
              let matches = false;
              matches = matches || testDateRage(x, dataKey, range); // test the guy
              const fam = x?.family ?? [];
              fam.forEach(f => {
                // test family
                matches = matches || testDateRage(f, dataKey, range);
              });
              return matches;
            });
            break;
          default:
            break;
        }
      }
      return { sortedList: result };
    });
  }

  sortList({ list, sortBy, sortDirection }) {
    const toSort = sort(list);

    switch (sortDirection) {
      case SortDirection.ASC:
        return toSort.asc(client => cleanValue(op.get(client, sortBy) ?? ''));
      case SortDirection.DESC:
        return toSort.desc(client => cleanValue(op.get(client, sortBy) ?? ''));
      default:
        return list;
    }
  }

  search(value) {
    this.setState({ loading: true });

    if (!value) {
      this.setState({
        sortedList: this.clientCache, //NOT HAPPENING!
        searching: '',
        loading: false
      });
      return;
    }

    const result = this.clientCache.filter(
      client => cleanValue(client).indexOf(cleanValue(value)) !== -1
    );

    this.setState({
      sortedList: [...result],
      searching: value,
      loading: false
    });
  }
}

const stringify = value => {
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(Object.values(flatten(value)));
};

const cleanValue = value => {
  return deburr(stringify(value)).toLowerCase();
};

const prepareClientValues = originalValues => {
  const values = JSON.parse(JSON.stringify({ main: originalValues }));
  if (values.main?.portugal_fiscal_residence) {
    values.contact = {
      portugal_fiscal_residence: values.main.portugal_fiscal_residence
    };
    delete values.main.portugal_fiscal_residence;
  }

  if (
    values.main.serviceType ||
    values.main.serviceValue ||
    values.main.serviceContractDate
  ) {
    values.service = [
      {
        type: values.main.serviceType,
        value: values.main.serviceValue,
        contract_date: values.main.serviceContractDate
      }
    ];
    delete values.main.serviceType;
    delete values.main.serviceValue;
    delete values.main.serviceContractDate;
  }

  if (values.main.agency) {
    const agencyName = values.main.agency;
    values.agency = { name: agencyName };
    delete values.main.agency;
  }
  return values;
};

const getFieldById = (clientList, path, clientId, relativeId = null) => {
  const clientIndex = clientList.findIndex(x => x._id === clientId);

  if (clientIndex < 0) {
    throw new Error('Cliente não encontrado');
  }
  const client = clientList[clientIndex];

  let target = client;
  let relativeIndex;
  let relative;

  if (relativeId) {
    relativeIndex = client?.family?.findIndex?.(x => x._id === relativeId);

    if (clientIndex < 0) {
      throw new Error('Familiar nnão encontrado');
    }

    relative = client?.family?.[relativeIndex];
    target = relative;
  }

  const field = op.get(target, path);
  if (typeof field === 'undefined') {
    throw new Error('Unexistant field');
  }

  return { client, clientIndex, relative, relativeIndex, field };
};

export default Id;

const testDateRage = (doc, dataKey, dateRange) => {
  const docValue = op.get(doc, dataKey);
  if (!docValue) {
    return false;
  }
  return dateRange.contains(moment(docValue));
};
