import React from 'react';
import { mrg } from '../../common/util';
import { Empty } from 'antd';
import globalStyles from '../../styles/global';

const styles = {
  full: {
    width: '100%',
    height: '100%',
    padding: globalStyles.global.baseline
  }
};

const NoData = () => (
  <div
    style={mrg([
      styles.full,
      globalStyles.layout.flexVertical,
      globalStyles.layout.flexCenter,
      globalStyles.layout.alignCenter
    ])}
  >
    <Empty description={'No Data'} />
  </div>
);

export default NoData;
