import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';

const BE_ADDR = address;

export async function getTocs() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'tocs', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postToc(client) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'tocs', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(client)
  });
  return await handleResponse(response);
}

export async function getFullToc(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'tocs/full/' + _id, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function updateToc(_id, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const endpoint = BE_ADDR + 'tocs/' + _id;

  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function removeToc(client_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const endpoint = BE_ADDR + 'tocs/' + client_id;

  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
