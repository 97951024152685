import ExcelJS from 'exceljs';
import moment from 'moment';
import { notification } from 'antd4';
import { fetchFiscalRepresentations } from '../../network/fiscalRepresentations';

export default async function exportFiscalRepresentations() {
  let response;
  try {
    response = await fetchFiscalRepresentations();
    if (!response?.ok) {
      throw new Error(response);
    }
  } catch (error) {
    console.error('Error getting fiscal representations');
    console.error(error);
    notification.error('Erro a obter dados');
  }

  const representations = response?.data ?? [];

  console.log(representations);

  let workbook = new ExcelJS.Workbook();

  workbook.creator = 'Vicki Finpartner';
  workbook.lastModifiedBy = 'Vicki Finpartner';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible'
    }
  ];

  let worksheet = workbook.addWorksheet('Representações Fiscais');

  worksheet.columns = [
    { header: 'NIF', key: 'nif', width: 15, bgColor: { argb: 'FFFF0000' } },
    { header: 'Actualizado em', key: 'updatedAt', width: 15 },
    { header: 'Residente PT', key: 'isResident', width: 10 },
    { header: 'Desistente', key: 'isDropOut', width: 10 },
    { header: 'Representante', key: 'representative', width: 50 },
    { header: 'Erros Robot', key: 'error', width: 60 }
  ];

  formatColors(worksheet);

  representations.forEach(r => {
    let row = worksheet.addRow({
      nif: r.nif,
      updatedAt: maybePrintMoment(r.updatedAt),
      isResident: r.isResident === true ? 'SIM' : 'NÃO',
      isDropOut:
        r.isDropOut === true ? 'SIM' : r.isDropOut === false ? 'NÃO' : '',
      representative: r.representative,
      error: r.error
    });

    row.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    row.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: r.error
            ? 'FFF0F0F0'
            : r.isResident === false && r.isDropOut === false
            ? 'DDE6FFED'
            : r.isDropOut === true
            ? 'DDFFE9E6'
            : 'DDE6E7FF'
        }
      };
    });
  });

  return await downloadExcel(workbook);
}

const maybePrintMoment = (date, format = 'DD-MM-YYYY') => {
  if (!date) {
    return '';
  }
  const mmt = moment(date);
  if (mmt.isValid()) {
    return mmt.format(format);
  }
  return date;
};

function formatColors(worksheet) {
  //Paint in gray from A1 to L1
  ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'cccccc' }
    };
    worksheet.getCell(key).font = {
      size: 13
    };
  });
  //create filters
  worksheet.autoFilter = {
    from: 'A1',
    to: 'F1'
  };
}

async function downloadExcel(workbook) {
  try {
    let blob = await workbook2Blob(workbook);

    let filename =
      'id_clients_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
    return anchorDownload(blob, filename);
  } catch (err) {
    console.error(err);
    return null;
  }
}

const workbook2Blob = async workbook => {
  let buffer = await workbook.xlsx.writeBuffer();
  let blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
};
const anchorDownload = (blob, filename) => {
  let a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
};
