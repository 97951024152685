import React from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { message } from 'antd';
import { fetchSystemState, sendDmrs, reset } from '../../../network/dmrss';
import DetailedMessage from '../../../components/util/DetailedMessage';
import { exportDMRSS } from './exportExcel';

const STEPS = { idle: 0, sending: 1, sent: 2 };

const STATES = {
  idle: 'idle',
  sending: 'sending',
  sent: 'sent',
  error: 'error'
};

const Home = () => {
  const [step, setStep] = React.useState(STEPS.idle);
  const [files, setFiles] = React.useState(null);
  const [dmrResult, setDmrResult] = React.useState(null);
  const [isConfirmReset, setConfirmReset] = React.useState(false);

  const [pollTimer, setPollTimer] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const canUpload = !files?.length && !files?.length;
  const canClear = !canUpload;
  const canDeliver = !loading && !!files?.length;
  console.log({ canDeliver, loading, dmrLen: files?.length });

  React.useEffect(() => {
    checkState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readFiles = async uploadEvent => {
    setLoading(true);
    uploadEvent.preventDefault();
    setFiles(
      uploadEvent.target?.files?.length ? [...uploadEvent.target?.files] : []
    );
    setLoading(false);
  };

  const doSendDmrs = async () => {
    setLoading(true);
    setDmrResult(null);
    try {
      const data = new FormData();

      for (const file of files) {
        data.append('files', file, file.name);
      }

      console.log(data);

      const result = await sendDmrs(data);

      setStep(STEPS.sending);

      pollState();

      return result;
    } catch (error) {
      DetailedMessage.error('Erro a enviar ficheiro', error);
    } finally {
      setLoading(false);
    }
  };

  const pollState = async () => {
    // are we polling already ?
    if (pollTimer) {
      return;
    }

    const timer = setInterval(checkState, 10000);
    setPollTimer(timer);
  };

  const checkState = async () => {
    let response = await fetchSystemState();
    console.log('DMRSS polled server: ', response);
    if (!(response && response?.ok && response?.data)) {
      return;
    }
    // if so, get the results
    if ([STATES.sent, STATES.error].includes(response?.data?.currentState)) {
      console.log(response);

      setDmrResult(response?.data ?? []);
      setStep(STEPS.sent);
      if (!pollTimer) {
        return;
      }
      console.log('DMRSS stopped polling server state');
      clearInterval(pollTimer);
      setPollTimer(null);
    }

    if ([STATES.sending].includes(response?.data?.currentState)) {
      setStep(STEPS.sending);
    }

    if ([STATES.idle].includes(response?.data?.currentState)) {
      clearInterval(pollTimer);
      setPollTimer(null);
      setStep(STEPS.idle);
    }
  };

  const downloadReport = async () => {
    await exportDMRSS(dmrResult.result, dmrResult.report);
  };

  const downloadDucZip = async zipBase64 => {
    const byteString = window.atob(zipBase64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);

    var downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'Vicki DMRSS Delivery DUCs.zip';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const resetDmrSS = async () => {
    try {
      const res = await reset();

      if (!res?.ok) {
        throw new Error('Erro a limpar estado DMR SS!');
      }

      setDmrResult(null);
      setConfirmReset(false);
      setFiles(null);
      setLoading(false);
      setStep(STEPS.idle);
    } catch (error) {
      message.error(error.message);
      console.error('Erro!', error);
    }
  };

  const StepIdle = () => {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center">
        <div className="w-1/2 flex items-center justify-start pb-4">
          <div className="w-auto h-auto flex justify-center pr-4 rounded-lg">
            <div className="cursor-pointer overflow-hidden relative w-auto group">
              <button
                disabled={loading}
                onClick={() => {
                  if (!canClear) {
                    return;
                  }
                  setFiles(null);
                }}
                className={`cursor-pointer bg-white border border-blue-600 rounded-lg text-blue-600 group-hover:bg-blue-600 group-hover:text-white transition font-medium flex justify-center py-2 px-4 items-center`}
              >
                {canUpload ? (
                  <div className="cursor-pointer flex justify-center items-center">
                    Carregar ficheiros DMR (.txt, aceita múltiplos)
                  </div>
                ) : (
                  <div className="cursor-pointer flex justify-center items-center">
                    <XMarkIcon className="w-5 h-5 stroke-current stroke-1"></XMarkIcon>
                    Cancelar
                  </div>
                )}
              </button>

              {canUpload && !loading && (
                <input
                  multiple
                  accept="text/plain"
                  className="cursor-pointer absolute block w-full h-full left-0 top-0 opacity-0 "
                  type="file"
                  name="finpartnerUploadDmrSS"
                  onChange={e => {
                    readFiles(e);
                  }}
                />
              )}
            </div>
          </div>

          <button
            onClick={doSendDmrs}
            disabled={!canDeliver || loading}
            className={`rounded-lg whitespace-normal break-normal transition border font-medium px-4 py-2 ${
              canDeliver
                ? 'border-blue-600 bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500'
                : 'bg-gray-200 border-gray-400 text-gray-600 cursor-not-allowed'
            } `}
          >
            Enviar DMRs SS
          </button>
        </div>
        <div
          className={`flex flex-col w-1/2 h-auto px-2 py-2 items-start justify-start text-gray-600 rounded-lg bg-gray-200 min-h-16`}
        >
          <div className="w-full text-md border-b border-gray-300">
            {files?.length ? `Ficheiros` : 'Lista de ficheiros DMR SS (.txt)'}
          </div>
          <div className="w-full font-light text-sm flex flex-col items-start justify-start flex-wrap">
            {!!files?.length &&
              files.map((file, index) => {
                return <div key={file.name + '' + index}>{file.name}</div>;
              })}
          </div>
        </div>
      </div>
    );
  };

  const StepSending = () => {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center">
        <div
          onClick={() => {
            const result = files.map(f => ({
              name: f.name,
              ...(Math.random > 0.5
                ? { status: 'sent', message: undefined }
                : {
                    status: 'error',
                    message:
                      'Error sending DMR: Lorem Ipsum dolor sit amet adisciping elit sum Lorem Ipsum dolor sit amet adisciping elit sum'
                  })
            }));
            console.log(result);
            setDmrResult(result);
            setStep(STEPS.sent);
          }}
          className="w-1/2 bg-gray-200 animate-pulse rounded-xl flex items-center justify-center py-8 px-8"
        >
          A enviar DMRs SS. Por favor aguarde, este processo poderá demorar
          vários minutos.
        </div>
      </div>
    );
  };

  const StepReport = () => {
    return (
      <div className="w-2/3 bg-gray-200 rounded-xl flex flex-col items-start justify-start my-4 py-8 px-8">
        <div className="w-full flex items-center justify-between pb-2 border-b border-gray-300">
          <div className="w-full text-md ">Resultado da entrega DMR SS</div>
          <button
            onClick={() => {
              console.log('click');
              setConfirmReset(true);
            }}
            disabled={isConfirmReset || loading}
            className={`rounded-lg whitespace-normal break-normal transition border-2 font-medium px-4 py-2 ${
              !isConfirmReset
                ? 'border-red-600 bg-red-600 text-white hover:bg-red-500 hover:border-red-500'
                : 'bg-transparent border-gray-600 text-gray-600 cursor-not-allowed'
            } `}
          >
            Reset
          </button>
        </div>

        {isConfirmReset ? (
          <div className="flex flex-col items-center justify-center py-4">
            <div className="mb-4 flex items-center text-center justify-center text-lg text-red-600">
              Ao limpar o estado da entrega, o relatório e quaisquer dados da
              entrega serão perdidos. Confirme esta acção:
            </div>
            <button
              onClick={resetDmrSS}
              disabled={loading}
              className={`rounded-lg mb-4 whitespace-normal break-normal transition border font-medium px-4 py-2 border-red-600 bg-red-600 text-white hover:bg-red-500 hover:border-red-500`}
            >
              Confirmar reset
            </button>
            <button
              onClick={() => setConfirmReset(false)}
              disabled={loading}
              className={`rounded-lg whitespace-normal break-normal transition border font-medium px-4 py-2 bg-white text-gray-800 hover:bg-gray-800 hover:text-white`}
            >
              Cancelar
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-start justify-center py-4">
            <div className="py-2 flex items-center">
              Resultado geral: {dmrResult?.result?.status}
            </div>
            <div className="pb-2 text-sm flex items-center">
              Entregas de DMR: {dmrResult?.report?.length ?? 0}
            </div>
            {dmrResult?.result?.errorList?.map?.(errorMessage => (
              <div className="pb-2 text-sm text-red-600 flex items-center">
                Erro: {errorMessage}
              </div>
            ))}
            {dmrResult?.report?.length ? (
              <button
                onClick={downloadReport}
                disabled={loading}
                className={`rounded-lg mt-8 mb-2 whitespace-normal break-normal transition border font-medium px-4 py-2 ${
                  !loading
                    ? 'bg-blue-600 text-white hover:bg-blue-500 '
                    : 'bg-gray-400 text-gray-600 cursor-not-allowed'
                } `}
              >
                Download Relatório
              </button>
            ) : (
              <div className="mb-2 text-gray-600">
                Não existem dados de entregas para apresentar
              </div>
            )}
            {dmrResult?.ducZipPath?.length ? (
              <button
                onClick={() => downloadDucZip(dmrResult?.ducZipPath)}
                disabled={loading}
                className={`rounded-lg mb-2 whitespace-normal break-normal transition border font-medium px-4 py-2 ${
                  !loading
                    ? 'bg-blue-600 text-white hover:bg-blue-500 '
                    : 'bg-gray-400 text-gray-600 cursor-not-allowed'
                } `}
              >
                Download DUC Zip
              </button>
            ) : (
              <div className="mb-2 text-gray-600">
                Não existem dados de DUC para apresentar
              </div>
            )}
          </div>
        )}
        {/* <div className="w-full font-light text-sm flex flex-col items-start justify-start flex-wrap">
          {!!dmrResult?.length &&
            dmrResult.map((dmr, index) => {
              return (
                <div
                  className="w-full flex items-center justify-start border-b border-gray-300"
                  key={dmr.name + '' + index}
                >
                  <div className="w-1/3 flex items-center justify-start border-r border-gray-300 py-1 px-2">
                    {dmr.name}
                  </div>
                  <div className="w-1/6 flex items-center justify-start border-r border-gray-300 py-1 px-2">
                    {dmr.status}
                  </div>
                  <div className="w-1/2 flex items-center justify-start px-2 py-1">
                    {dmr.message}
                  </div>
                </div>
              );
            })}
        </div> */}
      </div>
    );
  };

  return (
    <div className="flex-1 w-full flex flex-col justify-start items-center">
      {step === STEPS.idle && <StepIdle></StepIdle>}
      {step === STEPS.sending && <StepSending></StepSending>}
      {step === STEPS.sent && <StepReport></StepReport>}
      {step === STEPS.error && <div className="text-lg ">Erro!</div>}
    </div>
  );
};

export default Home;
