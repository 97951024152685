import React from 'react';
import {
  ArraySection,
  ArrayTitle,
  ActionAddArrayElement,
  ActionDeleteArrayElement
} from '../array';

import {
  Field,
  DateField,
  SelectField,
  NumberField,
  Hr,
  Placeholder
} from '../../../fields';

import '../../../fields/Fields.scss';

const dataPath = 'service';

let dataCache; //eslint-disable-line no-unused-vars

const Services = ({
  data,
  addArrayElement,
  deleteArrayElement,
  updateField
}) => {
  const [filteredData, setFilteredData] = React.useState(data);
  dataCache = data;

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    setFilteredData(data);
    dataCache = data;
  }, [data]);

  return (
    <ArraySection
      data={filteredData}
      dataPath={dataPath}
      deleteArrayElement={deleteArrayElement}
      updateField={updateField}
      renderToolbar={props => {
        return (
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-start align-items-center">
              <ArrayTitle title="Serviços"></ArrayTitle>
            </div>
            <div className="d-flex justify-content-end align-items-center fg-1">
              <ActionAddArrayElement
                onClick={() => addArrayElement(dataPath)}
              ></ActionAddArrayElement>
            </div>
          </div>
        );
      }}
    >
      <SingleService></SingleService>
    </ArraySection>
  );
};

export default Services;

const SingleService = ({
  data,
  dataIndex,
  updateField,
  deleteArrayElement
}) => {
  const element = data?.[dataPath]?.[dataIndex] ?? {};
  return (
    <div
      key={'' + dataPath + dataIndex}
      className={`fields-container ${
        element.is_paid ? 'array-item-green' : ''
      } array-item array-item-${
        dataIndex % 2 === 0 ? 'even' : 'odd'
      } w-100 border-radius`}
    >
      <div className="array-index d-flex justify-content-start w-100">
        <span className="d-flex justify-content-center align-items-center border-radius-50 mr-3">
          {dataIndex}
        </span>
        <ActionDeleteArrayElement
          onClick={() => deleteArrayElement(dataPath, element?._id)}
        ></ActionDeleteArrayElement>
      </div>
      <Field
        key={'type'}
        width={50}
        label={'Tipo de Serviço'}
        path={`service.${dataIndex}.type`}
        data={data}
        updateField={updateField}
      ></Field>
      <NumberField
        key={'value'}
        label={'Valor'}
        width={25}
        path={`service.${dataIndex}.value`}
        data={data}
        updateField={updateField}
      ></NumberField>
      <Placeholder width={25}></Placeholder>
      <DateField
        showTime={false}
        key={'contract_date'}
        label={'Data Contrato'}
        width={25}
        path={`service.${dataIndex}.contract_date`}
        data={data}
        updateField={updateField}
      ></DateField>
      <DateField
        showTime={false}
        key={'end_date'}
        label={'Data Cessão'}
        width={25}
        path={`service.${dataIndex}.end_date`}
        data={data}
        updateField={updateField}
      ></DateField>
      <Hr width={100} />
      <SelectField
        key={'invoicing_type'}
        width={20}
        label={'Facturação'}
        options={[
          { label: 'Mensal', value: 'monthly' },
          { label: 'Anual', value: 'yearly' }
        ]}
        path={`service.${dataIndex}.invoicing_type`}
        data={data}
        updateField={updateField}
      ></SelectField>
      {data?.service?.[dataIndex]?.invoicing_type === 'yearly' ? (
        <SelectField
          key={'invoicing_month'}
          width={20}
          label={'Mês'}
          options={[
            { label: 'Janeiro', value: 1 },
            { label: 'Fevereiro', value: 2 },
            { label: 'Março', value: 3 },
            { label: 'Abril', value: 4 },
            { label: 'Maio', value: 5 },
            { label: 'Junho', value: 6 },
            { label: 'Julho', value: 7 },
            { label: 'Agosto', value: 8 },
            { label: 'Setembro', value: 9 },
            { label: 'Outubro', value: 10 },
            { label: 'Novembro', value: 11 },
            { label: 'Dezembro', value: 12 }
          ]}
          path={`service.${dataIndex}.invoicing_month`}
          data={data}
          updateField={updateField}
        ></SelectField>
      ) : data?.service?.[dataIndex]?.invoicing_type === 'monthly' ? (
        <NumberField
          key={'invoicing_day'}
          label={'Dia'}
          width={20}
          min={1}
          max={31}
          path={`service.${dataIndex}.invoicing_day`}
          data={data}
          updateField={updateField}
        ></NumberField>
      ) : null}
      <SelectField
        key={'has_direct_debit'}
        width={20}
        label={'Débito Directo'}
        options={[
          { label: 'Sim', value: true },
          { label: 'Não', value: false }
        ]}
        path={`service.${dataIndex}.has_direct_debit`}
        data={data}
        updateField={updateField}
      ></SelectField>
      <SelectField
        key={'tax_type'}
        width={20}
        label={'Imposto'}
        multiple={true}
        options={[
          { label: 'IMI', value: 'IMI' },
          { label: 'AIMI', value: 'AIMI' },
          { label: 'IRS', value: 'IRS' },
          { label: 'IVA', value: 'IVA' },
          { label: 'IUC', value: 'IUC' }
        ]}
        path={`service.${dataIndex}.tax_type`}
        data={data}
        updateField={updateField}
      ></SelectField>
      <Hr width={100}></Hr>
      <SelectField
        key={'couple_individual'}
        width={25}
        label={'Casal/Individual'}
        options={[
          { label: 'Casal', value: 'Casal' },
          { label: 'Individual', value: 'Individual' }
        ]}
        path={`service.${dataIndex}.couple_individual`}
        data={data}
        updateField={updateField}
      ></SelectField>
      <Field
        key={'bank'}
        width={25}
        label={'Banco'}
        path={`service.${dataIndex}.bank`}
        data={data}
        updateField={updateField}
      ></Field>
      <Field
        key={'bank_account_number'}
        width={50}
        label={'Nº Conta'}
        path={`service.${dataIndex}.bank_account_number`}
        data={data}
        updateField={updateField}
      ></Field>
    </div>
  );
};
