import React from 'react';

import { Button } from 'antd4';
import { PlusOutlined } from '@ant-design/icons';

import '../../../fields/Fields.scss';

const ActionAddArrayElement = ({ onClick }) => {
  return (
    <Button
      className="add-relative mr-3"
      size="small"
      type="primary"
      ghost
      icon={<PlusOutlined></PlusOutlined>}
      shape="circle"
      onClick={onClick}
    ></Button>
  );
};

export default ActionAddArrayElement;
