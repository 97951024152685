import React from 'react';

import { Helmet } from 'react-helmet';
import { Layout, Steps, Icon, message } from 'antd';

import AppContext from '../../../context/AppContextBase';
import { AntUpload } from '../../../components/Upload';
import Loading from '../../../components/Loading';
import Report from './Report';

import {
  fetchSystemState,
  setSystemState,
  sendDmrs,
  fetchReport,
  reset
} from '../../../network/dmr';
import { scopes } from '../../../components/table/dmr/ReportTable';
import { ENDPOINTS } from '../../../network/uploadFile';
import globalStyles from '../../../styles/global';
import { mrg } from '../../../common/util';
import DetailedMessage from '../../../components/util/DetailedMessage';

const { Content } = Layout;
const Step = Steps.Step;

const styles = {
  fullWidth: {
    width: '100%'
  },
  content: {
    padding: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline * 2,
    paddingBottom: globalStyles.global.baseline * 2
  },
  table: {
    width: '100%',
    fontSize: globalStyles.global.baseline * 1.2,
    fontWeight: 100
  },
  column: {
    fontSize: globalStyles.global.baseline * 1.2,
    fontWeight: 100,
    padding: 0,
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    width: '100%',
    marginBottom: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  tag: {
    marginBottom: globalStyles.global.baseline * 0.5,
    fontSize: globalStyles.global.baseline * 0.8,
    padding: '0 ' + globalStyles.global.baseline * 0.5 + 'px'
  },
  radio: {
    button: {
      height: 'auto'
    },
    group: {
      marginBottom: globalStyles.global.baseline
    },
    title: { marginBottom: globalStyles.global.baseline * 0.5 }
  },
  pageHeaderBox: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: globalStyles.global.baseline
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: globalStyles.global.baseline
  },
  client: {
    title: {
      marginBottom: globalStyles.global.baseline * 0.5,
      fontSize: globalStyles.global.baseline * 1.6,
      fontWeight: 700,
      color: '#888888',
      width: '100%'
    },
    infoBox: {
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      alignItems: 'center',
      width: '100%'
    },
    description: {
      marginRight: globalStyles.global.baseline * 1.2,
      fontSize: globalStyles.global.baseline,
      color: '#666666'
    }
  },
  timeline: {
    item: {
      padding: 0,
      margin: 0,
      minHeight: 0
    }
  },
  steps: {
    content: {
      width: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter,
      borderRadius: globalStyles.global.baseline * 2,
      height: '100%'
    },
    action: {
      width: '100%',
      marginTop: '24px'
    },
    feedback: {
      title: {
        fontSize: globalStyles.global.baseline * 4,
        fontWeight: 100,
        color: '#666666',
        textTransform: 'uppercase',
        paddingTop: globalStyles.global.baseline,
        textAlign: 'center'
      },
      info: {
        fontSize: globalStyles.global.baseline * 2,
        fontWeight: 100,
        color: '#444444',
        textTransform: 'none'
      },
      main: {
        zIndex: 20,
        width: '100%',
        height: '100%',
        ...globalStyles.layout.flexVertical,
        ...globalStyles.layout.flexCenter,
        ...globalStyles.layout.alignCenter,
        fontSize: globalStyles.global.baseline * 2,
        fontWeight: 700,
        color: '#666666',
        textTransform: 'uppercase',
        paddingTop: globalStyles.global.baseline,
        textAlign: 'center'
      }
    }
  }
};

const states = {
  idle: 'idle',
  uploading: 'uploading',
  processing: 'processing',
  consistency: 'consistency',
  sending: 'sending',
  sent: 'sent'
};

const getStepFromState = stateName => {
  switch (stateName) {
    case states.idle:
      return { current: 0, waiting: false };
    case states.uploading:
      return { current: 0, waiting: true };
    case states.processing:
      return { current: 1, waiting: true };
    case states.consistency:
      return { current: 1, waiting: false };
    case states.sending:
      return { current: 2, waiting: true };
    case states.sent:
      return { current: 2, waiting: false };
    default:
      return { current: 0, waiting: true };
  }
};

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      current: 0,
      waiting: false,
      consistency: null,
      report: null,
      pollTimer: null
    };
    this.checkPermission = this.context.checkPermission;
    this.getStepIcon = this.getStepIcon.bind(this);
    this.gotoStep = this.gotoStep.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.getContent = this.getContent.bind(this);
    this.pollState = this.pollState.bind(this);
    this.stopPollState = this.stopPollState.bind(this);
    this.takeStateAction = this.takeStateAction.bind(this);
    this.send = this.send.bind(this);
    this.reset = this.reset.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.stopPollState();
  }

  async pollState() {
    // are we polling already ?
    if (this.state.pollTimer) {
      return;
    }
    // otherwise, create the polling function
    const checkState = async () => {
      // are we there yet?
      let req = await fetchSystemState();
      console.log('DMR polling server: ', req);
      if (!(req && req.result === 'OK' && req.data)) {
        return;
      }
      // if so, get the results
      if (req.data === states.idle) {
        this.setState({ consistency: null, report: null });
        this.stopPollState();
      }
      if (req.data === states.consistency) {
        req = await fetchReport();

        console.log({ report: req.data });

        this.setState({ report: null, consistency: req.data });
        this.stopPollState();
      }
      if (req.data === states.sent) {
        req = await fetchReport();

        console.log({ report: req.data });

        this.setState({ consistency: null, report: req.data });
        this.stopPollState();
      }
    };

    const pollTimer = setInterval(checkState, 10000);
    this.setState({ pollTimer: pollTimer });
  }

  async stopPollState() {
    if (!this.state.pollTimer) {
      return;
    }
    console.log('DMR stopped polling server state');
    clearInterval(this.state.pollTimer);
    this.setState({ pollTimer: null });
  }

  async refreshState(forceState) {
    let state = {};
    if (forceState) {
      state.result = 'OK';
      state.data = forceState;
    } else {
      state = await fetchSystemState();
    }

    if (state && state.result === 'OK' && state.data) {
      const currentObj = getStepFromState(state.data);
      this.setState({ ...currentObj });
      this.takeStateAction(state.data);
    }
  }

  takeStateAction(stateName) {
    // intentionnal fallthrough
    switch (stateName) {
      case 'processing':
      case 'consistency':
      case 'sending':
      case 'sent':
        this.pollState();
        break;
      default:
        break;
    }
  }

  getContent(step) {
    switch (step) {
      case 0:
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              ...globalStyles.layout.flexHorizontal,
              ...globalStyles.layout.flexCenter,
              ...globalStyles.layout.alignCenter
            }}
          >
            <AntUpload
              endpoint={ENDPOINTS.dmrUpload}
              accept=".zip"
              selectLabel="Seleccionar ficheiro ZIP"
              uploadingLabel="A enviar e validar"
              startLabel="Validar"
              onSuccess={this.refreshState}
              onError={err =>
                DetailedMessage.error('Erro a enviar ficheiro', err)
              }
            ></AntUpload>
          </div>
        );
      case 1:
        return (
          <Report
            reset={this.reset}
            onSend={this.send}
            scope={scopes.CONSISTENCY}
            data={this.state.consistency}
          />
        );
      case 2:
        return (
          <Report
            scope={scopes.REPORT}
            data={this.state.report}
            reset={this.reset}
          />
        );
      default:
        return <Loading />;
    }
  }

  async send() {
    const req = sendDmrs();

    if (!(req?.result === 'OK')) {
      console.log('refreshing');
      await this.refreshState();
    } else {
      message.error(
        'Ocorreu um erro. Verifique a sua ligação à internet e tente novamente.'
      );
    }
    setTimeout(async () => await this.refreshState(), 300);
  }

  async reset() {
    const req = await reset();
    if (req?.result === 'OK') {
      this.setState({
        loading: false,
        current: 0,
        waiting: false,
        consistency: null,
        report: null,
        availableVars: null
      });
      this.refreshState();
    } else {
      message.error(
        'Ocorreu um erro. Verifique a sua ligação à internet e tente novamente.'
      );
    }
  }

  getStepIcon(step) {
    switch (step) {
      case states.idle:
      case states.uploading:
        return <Icon type="cloud-upload-o" />;
      case states.consistency:
        return <Icon type="check-circle-o" />;
      case states.sending:
        return <Icon type="mail" />;
      default:
        return <Icon type="question-circle-o" />;
    }
  }

  async gotoStep(step) {
    if (!this.checkPermission('base:reports:step')) {
      return;
    }
    await setSystemState(step);
    this.refreshState();
  }

  render() {
    const { current } = this.state;
    return (
      <>
        <Helmet>
          <title>Finpartner - SAFT</title>
        </Helmet>

        <Content
          style={mrg([
            styles.fullWidth,
            globalStyles.layout.flexVertical,
            globalStyles.layout.flexStart,
            { maxHeight: '100%', overflowY: 'scroll' },
            styles.content
          ])}
        >
          {this.state.loading ? (
            <div style={styles.steps.feedback.main}>
              <span style={styles.steps.feedback.info}>A carregar</span>
              <Loading style={{ margin: 0 }} />
            </div>
          ) : (
            <>
              <Steps current={current}>
                <Step
                  key="idle"
                  title={
                    <span onClick={() => this.gotoStep(states.idle)}>
                      Upload
                    </span>
                  }
                  icon={this.getStepIcon(states.idle)}
                />
                <Step
                  key="consistency"
                  title={
                    <span onClick={() => this.gotoStep(states.consistency)}>
                      Verificação
                    </span>
                  }
                  icon={this.getStepIcon(states.consistency)}
                />
                <Step
                  key="send"
                  title={
                    <span onClick={() => this.gotoStep(states.sent)}>
                      Envio
                    </span>
                  }
                  icon={this.getStepIcon(states.sending)}
                />
              </Steps>
              <div style={styles.steps.content}>
                {this.getContent(this.state.current)}
              </div>
            </>
          )}
        </Content>
      </>
    );
  }
}

export default Home;
