import React, { useCallback, useEffect, useState } from 'react';
import { Tag, Button, Upload } from 'antd4';
import { FileAddOutlined } from '@ant-design/icons';
import op from 'object-path';

import routes from '../network/immigration';
import AppToaster from '../components/AppToaster';

import './Fields.scss';

const { uploadFile, getFileLink, removeFile } = routes;

const UploadField = ({ path, label, width, data, updateField }) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);

  useEffect(
    value => {
      const updateValue = op.get(data, path);
      setValue(updateValue);
    },
    [data, path]
  );
  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async file => {
      const previousS3Key = value?.s3key;

      // Upload to S3
      const s3Result = await uploadFile(file);

      if (!s3Result.ok) {
        // Could not upload. Bail
        setStatusClass('save-error');
        return;
      }
      const s3key = s3Result?.data?.key;
      const toSave = {
        s3key,
        originalName: file.name,
        fileType: ''
      };

      // Save to backend
      const backendResponse = await updateField(path, toSave);

      if (!backendResponse?.ok) {
        try {
          // could not save in backend, cancel upload (remove file)
          await removeFile(s3key);
        } catch (err) {
          // ignore? we can't do anything else here if deleting fails
          // we would need a "cleaner" to clean dangling files regularly
          console.error(err);
        }
        return;
      } else {
        //console.log('response ok, setting save-success');
        if (previousS3Key) {
          try {
            const removeFileResponse = await removeFile(previousS3Key); // eslint-disable-line no-unused-vars
          } catch (err) {
            // nothing
          }
        }
        setValue(toSave);
        setStatusClass('save-success');

        setTimeout(() => {
          setStatusClass('');
        }, 3000);
      }
    },
    [path, updateField, value.s3key]
  );

  return (
    <div
      key={path}
      className={`input-container d-flex align-items-center w-${width ?? '25'}`}
    >
      <Tag className="input-prefix border-radius">{label}</Tag>
      <Upload
        name="file"
        className="ant-upload-row d-flex justify-content-lg-start align-items-center"
        beforeUpload={file => {
          onSave(file);
          return false;
        }}
        showUploadList={false}
        listType="text"
      >
        <Button
          className="border-radius mr-2"
          size="small"
          type={value ? 'default' : 'primary'}
          ghost={!value}
        >
          <FileAddOutlined style={{ fontSize: 12 }} />
        </Button>
      </Upload>
      {value?.originalName && (
        <Button
          className={`pl-0 border-radius ${statusClass}`}
          type="link"
          onClick={async () => {
            let fileLink;
            try {
              fileLink = await getFileLink(value?.s3key);
            } catch (err) {
              console.error(err);
              AppToaster.error('Erro a obter documento');
              return;
            }
            window.open(fileLink, '_blank');
          }}
        >
          <span>{value?.originalName ?? ''}</span>
        </Button>
      )}
    </div>
  );
};

export default UploadField;
