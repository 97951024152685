import React from 'react';

import autobind from 'auto-bind/react';
import { Helmet } from 'react-helmet';
import { message, Button } from 'antd4';
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import AppContext from '../../../context/AppContextBase';
import { TransformFile } from '../../../components/Upload';
import { fillIRS } from './xmlParser';
import parseWorkbook from './workbookParser';

//import dummyParsedJSON from './dummyJSON.json';
//import dummyParsedJSON from './dummyJSONfromFakeExcel.json';

import './Irs.scss';

const NODE_DEV = process.env.NODE_ENV === 'development';

class IRSReport extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      workbookUploaded: false,
      atXmlUploaded: false,
      parsedWorkbook: null,
      //workbookUploaded: true, //delete me
      //parsedWorkbook: dummyParsedJSON, //delete me
      excelErrors: [],
      xmlErrors: []
    };
    autobind(this);
  }

  // async componentDidMount() {
  //   const { xml, errors } = await fillIRS(
  //     this.state.parsedWorkbook
  //     // this.state.xml
  //   );

  //   this.setState({
  //     resultXml: xml,
  //     xmlErrors: errors.length ? errors : [],
  //     loading: false
  //   });
  // }

  async componentDidUpdate(prevProps, prevState) {
    const excelChanged = prevState.parsedWorkbook !== this.state.parsedWorkbook;
    //const xmlChanged = prevState.xml !== this.state.xml;
    //const bothFilesExist = this.state.xml && this.state.parsedWorkbook;
    const xmlChanged = excelChanged;
    const bothFilesExist = this.state.parsedWorkbook;
    if (bothFilesExist && (excelChanged || xmlChanged)) {
      this.setState({
        loading: true
      });
      const { xml, errors } = await fillIRS(
        this.state.parsedWorkbook
        // this.state.xml
      );

      this.setState({
        resultXml: xml,
        xmlErrors: errors.length ? errors : [],
        loading: false
      });
    }
  }

  downloadResult() {
    const xml = this.state.resultXml;

    const parsedWorkbook = this.state.parsedWorkbook;
    console.log(parsedWorkbook);
    const clientNif =
      parsedWorkbook?.j?.[0]?.['3A3']?.[0]?.Titular ?? 'nif_desconhecido';

    const filename = `irs_${clientNif}.xml`;
    const pom = document.createElement('a');
    const bb = new Blob([xml], { type: 'text/plain' });

    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', filename);

    pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
    pom.draggable = true;
    pom.classList.add('dragout');

    pom.click();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Finpartner - IRS</title>
        </Helmet>

        <div
          id="irs"
          className="w-100 h-100 p-5 d-flex flex-col justify-content-start align-items-start"
        >
          <div className="w-100 d-flex justify-content-start align-items-start">
            <div className="w-33 d-flex flex-col justify-content-start align-items-start">
              <h3>1. Ficheiro Excel</h3>
              <TransformFile
                loading={this.state.loading}
                accept=".xlsx"
                selectLabel="Seleccionar ficheiro Excel"
                startLabel="Enviar"
                onUpload={file => {
                  const reader = new FileReader();
                  reader.onload = async e => {
                    try {
                      const data = new Uint8Array(e.target.result);
                      this.setState({
                        loading: true,
                        workbookUploaded: file.name
                      });
                      const { json, errors } = await parseWorkbook(data);
                      this.setState({
                        loading: false,
                        parsedWorkbook: json,
                        excelErrors: errors.length ? errors : []
                      });
                    } catch (err) {
                      console.log(err);
                      message.error(
                        <div className="d-flex flex-col justify-content-start align-items-center">
                          <span>
                            Ocorreu um erro a processar o ficheiro Excel:
                          </span>
                          <pre>{err.message}</pre>
                        </div>
                      );
                      this.setState({
                        loading: false,
                        parsedWorkbook: null,
                        workbookUploaded: file.name
                      });
                    }
                  };
                  reader.readAsArrayBuffer(file);
                }}
              ></TransformFile>
              <div className="w-100 d-flex justify-content-start align-items-center pt-3">
                {this.state.workbookUploaded && (
                  <>
                    <CheckOutlined className="mr-3"></CheckOutlined>
                    <span>{this.state.workbookUploaded}</span>
                  </>
                )}
              </div>
            </div>
            {/* <div className="w-33 d-flex flex-col justify-content-start align-items-start">
              <h3>2. Geração do ficheiro XML</h3>
              <TransformFile
                loading={this.state.loading}
                accept=".xml"
                selectLabel="Seleccionar ficheiro XML"
                startLabel="Enviar"
                onUpload={file => {
                  var reader = new FileReader();
                  reader.onload = async e => {
                    this.setState({
                      xml: e.target.result,
                      loading: false,
                      atXmlUploaded: file.name
                    });
                  };
                  reader.readAsText(file);
                }}
              ></TransformFile> 
              <div className="w-100 d-flex justify-content-start align-items-center pt-3">
                {this.state.atXmlUploaded && (
                  <>
                    <CheckOutlined className="mr-3"></CheckOutlined>
                    <span>{this.state.atXmlUploaded}</span>
                  </>
                )}
                </div>
            </div>*/}
            <div className="w-33 h-100 d-flex flex-col justify-content-start align-items-start">
              <h3>2. Geração de ficheiro IRS</h3>
              <div className="w-100 d-flex justify-content-start align-items-center">
                <Button
                  type="primary"
                  className="mr-3"
                  onClick={this.downloadResult}
                  disabled={!this.state.resultXml}
                >
                  <CloudDownloadOutlined className="mr-3" />
                  Descarregar XML
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    this.setState({
                      loading: false,
                      workbookUploaded: false,
                      //atXmlUploaded: false,
                      parsedWorkbook: null,
                      resultXml: null,
                      excelErrors: [],
                      xmlErrors: []
                    });
                  }}
                  disabled={
                    !(this.state.resultXml || this.state.workbookUploaded)
                  }
                >
                  <DeleteOutlined className="mr-3" />
                  Recomeçar
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{ overflow: 'visible' }}
            className="w-100 h-50 d-flex justify-content-start align-items-start"
          >
            <div
              style={{ overflow: 'visible' }}
              className="w-33 h-100 d-flex flex-col justify-content-start align-items-start"
            >
              {NODE_DEV && this.state.parsedWorkbook && (
                <div
                  style={{ overflow: 'visible' }}
                  className="w-100 h-auto d-flex justify-content-start align-items-start"
                >
                  <pre className="w-100" style={{ overflow: 'visible' }}>
                    {JSON.stringify(this.state.parsedWorkbook, null, 2)}
                  </pre>
                </div>
              )}
              {!!this.state.excelErrors?.length && (
                <div className="w-100 d-flex flex-col justify-content-start align-items-start">
                  <h4>Erros na leitura do ficheiro Excel</h4>
                  {this.state.excelErrors.map((error, index) => {
                    return (
                      <span
                        key={error + index}
                        className="font-weight-300 color-red"
                      >
                        <ExclamationCircleOutlined className="mr-2" />
                        {error}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
            {/* <div className="w-33 h-100 d-flex flex-col justify-content-start align-items-start">
              {!!this.state.xmlErrors?.length && (
                <div className="w-100 d-flex flex-col justify-content-start align-items-start">
                  <h4>Erros XML</h4>
                  {this.state.xmlErrors.map((error, index) => {
                    return (
                      <span className="font-weight-300 color-red" key={error + index}>
                        <ExclamationCircleOutlined className="mr-2 color-red" />
                        {error}
                      </span>
                    );
                  })}
                </div>
              )}
            </div> */}
            <div className="w-33 h-100 d-flex flex-col justify-content-start align-items-start">
              {!!(
                this.state.excelErrors?.length || this.state.xmlErrors?.length
              ) && (
                <>
                  <h4>Resultado</h4>
                  <span className="font-2 font-weight-300 color-red">
                    <ExclamationCircleOutlined className="mr-2 color-red" />
                    Não é possível gerar o XML IRS final quando existem erros
                  </span>
                  {NODE_DEV ? (
                    <span className="font-2 font-weight-300 color-red">
                      [ Mas como estás em Dev até podes, vá. Se ele existir. ]
                    </span>
                  ) : null}
                </>
              )}
              {!!this.state.xmlErrors?.length && (
                <div className="w-100 d-flex flex-col justify-content-start align-items-start mt-3">
                  <h4>Erros na criação do ficheiro XML</h4>
                  {this.state.xmlErrors.map((error, index) => {
                    return (
                      <span
                        className="font-weight-300 color-red"
                        key={error + index}
                      >
                        <ExclamationCircleOutlined className="mr-2 color-red" />
                        {error}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default IRSReport;
