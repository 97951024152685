import React from 'react';

import './Fields.scss';

const TitleField = ({ width, title }) => {
  return (
    <div
      key={title}
      className={`d-flex justify-content-start align-items-center w-${width ??
        '100'}`}
    >
      <h4>{title}</h4>
    </div>
  );
};

export default TitleField;
