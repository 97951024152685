import React from 'react';
import { Col, Input, Icon } from 'antd';

import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';

const styles = {
  statistic: {
    container: {
      fontSize: globalStyles.global.baseline,
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter,
      marginBottom: globalStyles.global.baseline
    },
    title: {
      fontWeight: 700,
      color: '#666666',
      marginRight: globalStyles.global.baseline
    },
    value: {
      fontWeight: 300
    }
  }
};

export default class EditableField extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dirty: false,
      saving: false,
      value: props.editable ? props.value : undefined
    };
  }

  save = async () => {
    if (!this.state.dirty) {
      return;
    }
    this.setState({ saving: true });
    let value = this.state.value?.trim() ?? this.state.value;
    let result = null;
    try {
      result = await this.props.onSave(value);
    } catch (err) {
      this.setState({ saving: false, value });
      return await this.props?.onSaveError?.(err);
    }
    this.setState({ dirty: false, saving: false, value });
    return await this.props?.onSaveSuccess?.(result);
  };

  render() {
    if (this.props.editable) {
      return (
        <Col
          style={{
            ...globalStyles.layout.flexVertical,
            ...globalStyles.layout.flexBetween,
            ...globalStyles.layout.alignStart,
            paddingRight: globalStyles.global.baseline,
            ...this.props.style
          }}
          key={this.props.title}
          span={this.props.span || 8}
        >
          <div style={mrg([styles.statistic.container, { width: '100%' }])}>
            {/* <span style={mrg([styles.statistic.title, { minWidth: '6rem' }])}>
              {title}{' '}
            </span> */}
            <span style={mrg([styles.statistic.value, { width: '100%' }])}>
              <Input
                style={{
                  width: '100%'
                }}
                onPressEnter={async e => await this.save(e.target.value)}
                onBlur={async e => await this.save(e.target.value)}
                disabled={this.state.saving}
                onChange={e => {
                  this.setState({ value: e.target.value, dirty: true });
                }}
                value={
                  this.props.editable ? this.state.value : this.props.value
                }
                addonBefore={
                  <div style={{ minWidth: '6rem' }}>{this.props.title}</div>
                }
                addonAfter={
                  <Icon
                    type={this.state.saving ? 'loading' : 'save'}
                    style={{ color: '#40a9ff' }}
                    theme={this.state.dirty ? 'filled' : 'outlined'}
                    onClick={async e => await this.save(e.target.value)}
                  />
                }
              />
            </span>
          </div>
        </Col>
      );
    } else {
      return (
        <Col
          style={{
            ...globalStyles.layout.flexVertical,
            ...globalStyles.layout.flexBetween,
            ...globalStyles.layout.alignStart,
            paddingRight: globalStyles.global.baseline,
            ...this.props.style
          }}
          key={this.props.title}
          span={this.props.span || 8}
        >
          {this.props.value && (
            <Input
              style={{
                maxWidth: '100%',
                ...styles.statistic.container
              }}
              value={this.props.value}
              addonBefore={
                <div style={{ minWidth: '6rem' }}>{this.props.title}</div>
              }
            />
          )}
        </Col>
      );
    }
  }
}
