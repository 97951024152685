import React from 'react';

import { Select, Tag } from 'antd4';

import flatten from 'flat';
import deburr from 'lodash/deburr';

import '../../../fields/Fields.scss';

const { Option } = Select;

const FilterPaymentType = ({
  options,
  dataCache,
  dataPath,
  onFilter,
  onClear = () => {
    onFilter(dataCache);
  }
}) => {
  const [value, setValue] = React.useState([]);
  return (
    <div className="d-flex justify-content-start align-items-center w-100">
      <Tag className="input-prefix border-radius">Tipo de pagamento</Tag>
      <Select
        className={`input input-select mw-33`}
        showSearch={true}
        value={value}
        mode="tags"
        placeholder=""
        filterOption={(input, option) => {
          return (
            option?.value?.toLowerCase?.()?.indexOf?.(input?.toLowerCase?.()) >=
            0
          );
        }}
        onSelect={(newValue, option) => {
          setValue([newValue]);
          const newData = { ...dataCache };
          newData[dataPath] = dataCache[dataPath].filter(item => {
            const target = item?.payment_type ?? [];
            for (const paymentType of target) {
              // payment_type is an array. Iterate all its values and do a "clean" comparison to the filter value. Return true on any match
              if (
                cleanValue(paymentType).indexOf(cleanValue(newValue)) !== -1
              ) {
                return true;
              }
            }
            return false;
          });

          return onFilter(newData);
        }}
        onDeselect={(value, option) => {
          setValue([]);
          return onClear();
        }}
        onClear={() => {
          setValue([]);
          return onClear();
        }}
      >
        {options.map(opt => (
          <Option key={opt.value} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default FilterPaymentType;

const stringify = value => {
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(Object.values(flatten(value)));
};

const cleanValue = value => {
  return deburr(stringify(value)).toLowerCase();
};
