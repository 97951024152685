import React from 'react';
import { getSheetJson, getWorkbook } from '../../../excel/xlsParser';
import dataKeysJ, { categoryKeysJ, annexSheetJ } from './excelKeys/anexoJ';
import dataKeysF, { categoryKeysF, annexSheetF } from './excelKeys/anexoF';

const annexSheets = {
  ...annexSheetJ,
  ...annexSheetF
};
const sheetIdentifierCell = 'A1';

// No longer used, leave for alter if needed
const annexFilledCode = 'preenchido';

const categoryKeys = {
  ...categoryKeysJ,
  ...categoryKeysF
};

export const tableColumns = {
  j: dataKeysJ,
  f: dataKeysF
};

const parseWorkBook = async uint8Array => {
  console.group('Excel Parse');

  const workbook = await getWorkbook(uint8Array);

  const result = {};
  const errors = [];

  for (const sheetName of workbook.SheetNames) {
    const sheet = workbook.Sheets[sheetName];
    const firstCell = sheet[sheetIdentifierCell];
    const identifier = firstCell?.w?.toLowerCase?.().trim();
    const annexInfo = annexSheets[identifier];
    if (!identifier || !annexInfo) {
      errors.push(
        `Folha inválida: ${sheetName}. A primeira célula de cada folha (A1) deverá conter o texto "Anexo X".`
      );
      continue;
    }

    const annexKey = annexInfo.dataKey;
    const rowArray = getSheetJson(workbook.Sheets[sheetName], {
      raw: false,
      blankrows: false,
      defval: null,
      header: 1
    });

    const annexResult = await processAnnex(annexKey, rowArray, errors);
    if (annexResult) {
      if (!result[annexKey]) {
        result[annexKey] = [];
      }
      result[annexKey].push(annexResult);
    }
  }

  // for (const annexKey in annexSheets) {
  //   const annexInfo = annexSheets[annexKey];
  //   const rowArray = getSheetJson(workbook.Sheets[annexInfo.excelKey], {
  //     raw: false,
  //     blankrows: false,
  //     defval: null,
  //     header: 1
  //   });

  //   const annexResult = await processAnnex(annexKey, rowArray, errors);
  //   if (annexResult) {
  //     result[annexKey] = annexResult;
  //   }
  // }

  console.groupEnd('Excel Parse');

  debug(result);

  const json = result;

  return { json, errors };
};

const processAnnex = (annexKey, rowArray, errors) => {
  const result = {};
  let currentCategory = null;

  for (let i = 0; i < rowArray.length; i++) {
    const row = rowArray[i];
    console.log(`Excel row ${i + 1}`);
    const newCategory = isCategoryStart(row);

    if (newCategory) {
      const categoryKey = categoryKeys[newCategory];
      if (!categoryKey) {
        currentCategory = null;
        console.log(`SKIP unknown category "${newCategory}"`);
        continue;
      }
      result[categoryKey] = [];
      currentCategory = categoryKey;
      const lineSkip = tableColumns[annexKey]?.[categoryKey]?.lineSkip;
      if (!lineSkip) {
        throw new Error(`LineSkip not found for category "${currentCategory}"`);
      }
      console.log(`Starting new category "${currentCategory}"`);
      i += lineSkip; // Skip <lineSkip> rows: we're not interested in any headers
    } else {
      if (!currentCategory) {
        continue;
      }
      const columnMap = tableColumns?.[annexKey]?.[currentCategory]?.columns;
      const record = createRecord(row, columnMap, errors, i + 1);
      result[currentCategory].push(record);
    }
  }
  console.groupEnd(`Annex ${annexKey} is filled`);
  return result;
};

const createRecord = (array, keyMap, errors, lineNumber = -1) => {
  if (!Array.isArray(array)) {
    throw new Error('Linha inválida: não foram encontradas células');
  }
  const result = {};
  for (let i = 0; i < array.length; i++) {
    let cell = array[i];
    if (cell?.trim) {
      cell = cell.trim();
    }
    const key = keyMap[i]?.dataKey;
    if (!key) {
      continue;
    }
    const transform = keyMap[i]?.transform;
    if (transform) {
      try {
        result[key] = transform(cell);
      } catch (error) {
        errors.push(
          <>
            <span>{`Linha ${lineNumber}`}</span>
            <br />
            <span>{`Coluna "${keyMap[i]?.excelKey}":`}</span>
            <br />
            <span style={{ fontWeight: 400 }}>{`${error.message}`}</span>
          </>
        );
        result[key] = null;
      }
    } else {
      result[key] = cell;
    }
  }
  return result;
};

// No longer used, leave for alter if needed
// always mutates rowArray
// eslint-disable-next-line no-unused-vars
const isAnnexFilled = rowArray => {
  const firstRow = rowArray?.shift?.();
  const identifier = firstRow?.[1]?.toLowerCase?.();

  return annexFilledCode === identifier;
};

const isCategoryStart = row => {
  const firstValue = row?.[0];

  const isStart = firstValue?.startsWith?.('##');
  if (isStart) {
    const category = firstValue
      .replace(/^## */, '')
      .replace(/\./g, '')
      .trim();
    return category;
  }
  return false;
};

// eslint-disable-next-line
const debug = result => {
  console.group('Parsed JSON');
  console.log(result);
  console.groupEnd('Parsed JSON');
};

export default parseWorkBook;
