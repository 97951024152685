import React from 'react';
import autobind from 'auto-bind';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Tag } from 'antd4';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import './CreateRelative.scss';

class SingleClient extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    autobind(this);
  }

  async onFinish(values) {
    await this.props.onCreate?.(values);
    await this.formRef.current?.resetFields?.();
  }

  render() {
    return (
      <div
        id="create-relative"
        className={`form-container ${
          this.props.showCreateForm ? ' visible' : ''
        } d-flex flex-col justify-content-center align-items-start w-100`}
      >
        <div className="form-inner w-100">
          <h4>Dados</h4>
          <Form
            className="d-flex flex-wrap flex-row justify-content-start align-items-center w-100"
            ref={this.formRef}
            onFinish={this.props.onCreate ?? (() => {})}
          >
            <Form.Item
              className="form-item w-33"
              name="nome"
              rules={[{ required: true, message: 'Obrigatório' }]}
            >
              <Input
                prefix={<Tag className="input-prefix border-radius">Nome</Tag>}
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              className="form-item w-33"
              name="dossier"
              rules={[{ required: false }]}
            >
              <Input
                prefix={
                  <Tag className="input-prefix border-radius">Dossier</Tag>
                }
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              className="form-item w-33"
              name="juris"
              rules={[{ required: false }]}
            >
              <Input
                prefix={<Tag className="input-prefix border-radius">Juris</Tag>}
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              className="form-item w-33"
              name="nif"
              rules={[{ required: false }]}
            >
              <Input
                prefix={<Tag className="input-prefix border-radius">NIF</Tag>}
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              className="form-item w-33"
              name="passaporte"
              rules={[{ required: false }]}
            >
              <Input
                prefix={
                  <Tag className="input-prefix border-radius">Passaporte</Tag>
                }
                className="form-input"
              />
            </Form.Item>
            <Form.Item
              className="form-item w-33"
              name="nacionalidade"
              rules={[{ required: false }]}
            >
              <Input
                prefix={
                  <Tag className="input-prefix border-radius">
                    Nacionalidade
                  </Tag>
                }
                className="form-input"
              />
            </Form.Item>
            <Form.Item className="form-item w-100">
              <div className=" w-100 d-flex flex-row align-items-center justify-content-end">
                <Button
                  size="small"
                  type="danger"
                  className="mr-3"
                  ghost
                  icon={<CloseOutlined></CloseOutlined>}
                  shape="circle"
                  onClick={async () => {
                    await this.props.onCancel?.();
                  }}
                ></Button>
                <Button
                  size="small"
                  type="primary"
                  className="mr-3"
                  htmlType="submit"
                  icon={<CheckOutlined></CheckOutlined>}
                  shape="circle"
                ></Button>
              </div>
            </Form.Item>
            <div className="form-flex"></div>
          </Form>
        </div>
      </div>
    );
  }
}

SingleClient.TYPES = { descendant: 'descendant', parent: 'parent' };

export default withRouter(SingleClient);
