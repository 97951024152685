import { get } from '../common/util';
import { departments, roles, scopes } from '../common/variables';

/* Every property translated to integer here follows ascending order.
 * Eg, Dev > Admin > User, therefore { Dev: 2 Admin: 1 User: 0 }
 *
 * This is done for quick comparisons without IFs or switch
 */
const debug = false;
export const log = debug ? console.log.bind(window.console) : () => {};
export const group = debug ? console.group.bind(window.console) : () => {};
export const groupEnd = debug
  ? console.groupEnd.bind(window.console)
  : () => {};

const permissions = {
  menu: {
    send: {
      _requirements: {
        department: [departments.base.key, departments.iva.key],
        scope: scopes.internal.numeric,
        role: roles.user.numeric
      },
      dmr: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      },
      dmrss: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      },
      saft: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      },
      iva: {
        _requirements: {
          department: [departments.base.key, departments.iva.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      },
      irs: {
        _requirements: {
          department: [departments.base.key, departments.irs.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      }
    },
    id: {
      _requirements: {
        department: [departments.base.key, departments.id.key],
        scope: scopes.internal.numeric,
        role: roles.user.numeric
      }
    },
    tocs: {
      _requirements: {
        department: [departments.base.key],
        scope: scopes.internal.numeric,
        role: roles.user.numeric
      }
    },
    clients: {
      _requirements: {
        department: [departments.base.key, departments.iva.key],
        scope: scopes.external.numeric,
        role: roles.user.numeric
      }
    },
    journey: {
      _requirements: {
        department: [departments.base.key],
        scope: scopes.internal.numeric,
        role: roles.user.numeric
      }
    },
    settings: {
      _requirements: {
        department: [departments.base.key],
        scope: scopes.external.numeric,
        role: roles.admin.numeric
      },
      users: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.admin.numeric
        },
        groups: {
          _requirements: {
            department: [departments.base.key],
            scope: scopes.external.numeric,
            role: roles.dev.numeric
          }
        }
      },
      groups: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.dev.numeric
        }
      }
    }
  },
  base: {
    reports: {
      _requirements: {
        department: [departments.base.key],
        scope: scopes.external.numeric,
        role: roles.user.numeric
      },
      step: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.dev.numeric
        }
      }
    },
    tocs: {
      // toc editing and deleting is limited server-side to its owner only.
      list: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      },
      edit: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.user.numeric
        }
      },
      remove: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.user.numeric
        }
      }
    },
    clients: {
      create: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      },
      list: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.user.numeric
        }
      },
      download: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.internal.numeric,
          role: roles.user.numeric
        }
      },
      edit: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.user.numeric
        }
      },
      remove: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.admin.numeric
        }
      },
      view_secrets: {
        _requirements: {
          department: [departments.base.key],
          scope: scopes.external.numeric,
          role: roles.admin.numeric
        }
      }
    }
  }
};

export default class Permissions {
  static check(scope, userData) {
    if (!userData) {
      console.warn('Permissions: userData not valid ');
      return false;
    }

    group(scope);
    const translatedUserPermissions = {
      role: roles[userData.role].numeric,
      scope: scopes[userData.permissions.scope].numeric,
      department: userData.permissions.department.toLowerCase()
    };

    if (
      !translatedUserPermissions.role ||
      !translatedUserPermissions.scope ||
      !translatedUserPermissions.department
    ) {
      log(
        'Permissions: User data is missing role or permissions: ',
        translatedUserPermissions
      );
      groupEnd(scope);
      throw new Error(
        'Permissions: User data is missing role or permissions: ',
        translatedUserPermissions
      );
    }

    const coordinates = scope.replace(/:/g, '.');

    const needed = get(coordinates, permissions);

    if (!needed) {
      log('Permissions: Missing user permissions: ', scope);
      groupEnd(scope);
      throw new Error('Permissions: Missing user permissions: ' + scope);
    }

    const requirements = needed._requirements;
    log('permissions - user :', translatedUserPermissions);
    log('permissions - needed :', needed._requirements);

    const isDev = false; //translated.role >= roles.dev;

    if (isDev) {
      log('Permissions: is dev,returning true');
      groupEnd(scope);
      return true;
    } else {
      // conditions for department
      const featureIsOpen = requirements.department.includes(
        departments.all.key
      );
      const userHasAllDepartments =
        translatedUserPermissions.department === departments.all.key;

      const userHasCorrectDepartment = requirements.department.includes(
        translatedUserPermissions.department
      );

      const roleRequirementsOk =
        translatedUserPermissions.role >= requirements.role;
      const scopeRequirementsOk =
        translatedUserPermissions.scope >= requirements.scope;
      const departmentRequirementsOk =
        featureIsOpen || userHasAllDepartments || userHasCorrectDepartment;

      log('Permissions: not dev, returning: ', {
        roleRequirementsOk,
        scopeRequirementsOk,
        featureIsOpen,
        userHasAllDepartments,
        userHasCorrectDepartment
      });
      groupEnd(scope);
      return (
        roleRequirementsOk && scopeRequirementsOk && departmentRequirementsOk
      );
    }
  }
}
