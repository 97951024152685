import React from 'react';
import { Button } from 'antd';

export default class ConfirmDelete extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.onDelete = this.props.onDelete || (() => {});
    this.onConfirm = this.props.onConfirm || (() => {});
  }

  static getDerivedStateFromProps(props, state) {
    return { confirm: props.isConfirm };
  }

  render() {
    return this.state.confirm ? (
      <Button
        type={'danger'}
        disabled={this.props.disabled}
        style={this.props.buttonStyle}
        onClick={async () => {
          try {
            return await this.onDelete();
          } catch (err) {
            this.setState({ confirm: false });
          }
        }}
      >
        {this.props.confirmLabel || 'Confirm!'}
      </Button>
    ) : (
      <Button
        disabled={this.props.disabled}
        type={'danger'}
        ghost
        style={this.props.buttonStyle}
        onClick={() => {
          this.setState({ confirm: true });
          return this.onConfirm();
        }}
      >
        {this.props.deleteLabel || 'Delete'}
      </Button>
    );
  }
}
