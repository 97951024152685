import React from 'react';
import { Icon } from 'antd';
import op from 'object-path';

import {
  renderDateColumn,
  textSorter,
  dateSorter
} from '../../../common/table';

import g from '../../../styles/global';

const styles = {
  actionContainer: {
    ...g.layout.flexVertical,
    ...g.layout.flexBetween,
    ...g.layout.alignStart
  },
  action: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    width: '100%',
    maxWidth: '150px',
    marginBottom: g.global.baseline,
    fontSize: g.global.baseline * 1.2
  },
  client: {
    title: {
      marginBottom: g.global.baseline * 0.5,
      fontSize: g.global.baseline * 1.6,
      fontWeight: 700,
      color: '#888888',
      width: '100%'
    },
    infoBox: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      alignItems: 'center',
      width: '100%'
    },
    description: {
      paddingRight: g.global.baseline * 1.2,
      fontSize: g.global.baseline,
      color: '#666666'
    }
  }
};

// const getStatusIcon = record => {
//   let title;
//   let type;
//   let color;
//   if (record.status === 'not-sent') {
//     title = 'Não enviado';
//     type = 'minus-circle';
//     color = g.colors.feedback.error;
//   } else if (record?.consistency?.errorList?.length) {
//     title = 'Erro!';
//     type = 'close-circle';
//     color = g.colors.feedback.error;
//   } else if (record?.consistency?.warningList?.length) {
//     title = 'Aviso!';
//     type = 'warning';
//     color = g.colors.feedback.warning;
//   } else {
//     title = 'OK';
//     type = 'check';
//     color = g.colors.feedback.success;
//   }
//   return (
//     <Tooltip title={title}>
//       <Icon
//         type={type}
//         style={{
//           fontSize: g.global.baseline * 1.5,
//           color: color
//         }}
//       />
//     </Tooltip>
//   );
// };

const renderObject = obj => obj?.toString?.() || obj;
const renderArray = array => array.map(item => '- ' + item).join(',\n');
const renderRecord = record =>
  Array.isArray(record) ? renderArray(record) : renderObject(record);

const textColumn = (width, fieldPath, title, containerStyle = {}) => {
  return {
    title: title,
    dataIndex: fieldPath,
    key: fieldPath,
    width: width,
    render: (possiblyUndefinedObject, record) => {
      const obj = op.get(record, fieldPath);
      return {
        props: { colSpan: 1 },
        children: (
          <div style={containerStyle}>
            <span>{renderRecord(obj)}</span>
          </div>
        )
      };
    },
    sorter: textSorter(fieldPath)
  };
};

const nifColumn = (width, title, containerStyleFn) => {
  return {
    title: title,
    dataIndex: 'nif',
    key: 'nif',
    width: width,
    render: (obj, record) => {
      const error = !obj?.trim?.()?.match?.(/^\d{9}$/);

      return {
        props: { colSpan: 1 },
        children: (
          <div style={containerStyleFn(error)}>
            <span>{renderRecord(obj)}</span>
          </div>
        )
      };
    },
    sorter: textSorter('nif')
  };
};

const createdAt = width => {
  return {
    title: 'Criado em',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: width,
    render: renderDateColumn(1),
    sorter: dateSorter('createdAt')
  };
};

// const errors = width => {
//   return {
//     title: (
//       <Tooltip title={'Estado'}>
//         <Icon
//           type={'check'}
//           style={{
//             fontSize: g.global.baseline * 1.5
//           }}
//         />
//       </Tooltip>
//     ),
//     dataIndex: 'consistency.errorList',
//     key: 'consistency.errorList',
//     width: width,
//     render: (obj, record) => getStatusIcon(record)
//   };
// };
const state = (width, key) => {
  return {
    title: 'Status',
    dataIndex: key,
    key: key,
    width: width,
    render: (obj, record) => {
      let errors = [];
      let warnings = [];
      let ok = true;
      if (record?.consistency?.errorList?.length) {
        errors = errors.concat(record.consistency.errorList);
        ok = false;
      }
      if (record?.report?.errorList?.length) {
        errors = errors.concat(record.report.errorList);
        ok = false;
      }
      if (record?.consistency?.warningList?.length) {
        warnings = warnings.concat(record.consistency.warningList);
        ok = false;
      }
      if (record?.report?.warningList?.length) {
        warnings = warnings.concat(record.report.warningList);
        ok = false;
      }

      errors = errors.map(error => (
        <div style={{ marginBottom: g.global.baseline / 2 }} key={error}>
          <Icon
            type={'close'}
            style={{
              marginRight: g.global.baseline,
              color: g.colors.feedback.error
            }}
          ></Icon>
          {error}
        </div>
      ));

      warnings = warnings.map(error => (
        <div style={{ marginBottom: g.global.baseline / 2 }} key={error}>
          <Icon
            type={'warning'}
            style={{
              marginRight: g.global.baseline,
              color: g.colors.feedback.warning
            }}
          ></Icon>
          {error}
        </div>
      ));

      let content = [
        <div
          key="header"
          style={{
            marginBottom: g.global.baseline / 2,
            fontWeight: 700
          }}
        >
          <Icon
            type={
              errors.length ? 'close' : warnings.length ? 'warning' : 'check'
            }
            style={{
              marginRight: g.global.baseline,
              color: errors.length
                ? g.colors.feedback.error
                : warnings.length
                ? g.colors.feedback.warning
                : g.colors.feedback.success
            }}
          ></Icon>
          {op.get(record, key)}
        </div>
      ];
      if (!ok) {
        content = content.concat(errors).concat(warnings);
      }

      return (
        <span
          style={{
            fontWeight: 300
          }}
        >
          {content}
        </span>
      );
    }
  };
};

const consistencyColumns = [
  nifColumn('5%', 'NIF', error =>
    error
      ? {
          ...styles.client.title,
          color: g.colors.feedback.error,
          fontSize: g.global.baseline
        }
      : styles.client.title
  ),
  textColumn('5%', 'consistency.primavera', 'Nome Primavera'),
  textColumn('5%', 'consistency.name', 'Nome VICKI'),
  createdAt('10%'),
  state('15%', 'consistency.status')
];
const reportColumns = [
  nifColumn('5%', 'NIF', error =>
    error
      ? {
          ...styles.client.title,
          color: g.colors.feedback.error,
          fontSize: g.global.baseline
        }
      : styles.client.title
  ),
  textColumn('5%', 'consistency.primavera', 'Nome Primavera'),
  textColumn('10%', 'consistency.name', 'Nome VICKI'),
  createdAt('5%'),
  state('15%', 'report.status')
];

export { consistencyColumns, reportColumns };
