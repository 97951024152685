import React from 'react';

import { Button, Tooltip, Input } from 'antd4';
import { ClearOutlined } from '@ant-design/icons';

import flatten from 'flat';
import deburr from 'lodash/deburr';

import '../../../fields/Fields.scss';

const { Search } = Input;

const ArraySearch = ({
  dataCache,
  dataPath,
  onSearch,
  onClear = () => {
    onSearch(dataCache);
  },
  showClear = true
}) => {
  return (
    <div className="d-flex justify-content-start align-items-center">
      <Search
        placeholder="Procurar"
        size="small"
        onChange={async e => {
          const value = e.target?.value;
          if (!value) {
            return await onClear();
          }
        }}
        onSearch={async value => {
          const result = await doSearch(dataCache, dataPath, value);
          return await onSearch(result);
        }}
        className="search-input pr-3"
      />
      {showClear && (
        <Tooltip title={'Limpar pesquisa'}>
          <Button
            className="clear-search mr-3"
            size="small"
            type={'default'}
            icon={<ClearOutlined></ClearOutlined>}
            shape="circle"
            onClick={async () => {
              return await onClear();
            }}
          ></Button>
        </Tooltip>
      )}
    </div>
  );
};

export default ArraySearch;

const doSearch = (dataCache, dataPath, value) => {
  if (!value) {
    return dataCache;
  }

  const newData = { ...dataCache };

  newData[dataPath] = dataCache[dataPath].filter(
    item => cleanValue(item).indexOf(cleanValue(value)) !== -1
  );

  return newData;
};

const stringify = value => {
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(Object.values(flatten(value)));
};

const cleanValue = value => {
  return deburr(stringify(value)).toLowerCase();
};
