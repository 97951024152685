import React from 'react';
import { Form, Button, Input, message, Tooltip } from 'antd';
import g from '../../styles/global';
import DetailedMessage from '../util/DetailedMessage';

const styles = {
  container: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    marginBottom: g.global.baseline,
    marginTop: g.global.baseline
  },
  form: {
    body: {
      width: 'auto',
      padding: g.global.baseline * 2,
      border: '1px solid #e8e8e8',
      borderRadius: 4,
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart
    },
    section: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignStart
    },
    item: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      paddingRight: g.global.baseline,
      lineHeight: g.global.baseline * 2.5,
      marginBottom: 0
    }
  },
  actions: {
    container: {
      marginTop: 4,
      ...g.layout.flexHorizontal,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter
    },
    action: {
      width: '32px',
      ...g.layout.flexHorizontal,
      ...g.layout.flexCenter,
      marginRight: g.global.baseline,
      fontSize: g.global.baseline * 1.2
    }
  }
};

class InsertToc extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = { sending: false };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ sending: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          // trim
          values.at_username = values?.at_username?.trim?.();

          // transform
          values.owner = { id: this.props?.owner?.id };
          values.nif = values?.at_username;
          values.at = {};
          values.at.username = values?.at_username;
          values.at.password = values?.at_password;
          delete values?.at_username;
          delete values?.at_password;

          // send

          console.log(values);
          if (this.props.action) {
            const request = await this.props.action(values);

            if (request && request.result === 'OK') {
              if (this.props.done) {
                this.props.done();
              } else {
                message.success(<span>Sucesso</span>);
              }
              //clear form:
              this.props.form.resetFields();
            } else {
              message.error(
                <span>
                  Erro na inserção. Verifique a sua ligação à internet e tente
                  de novo.
                </span>
              );
            }
          }
        } catch (err) {
          DetailedMessage.error('Erro na inserção: ' + err.message, err);
        }
      }
      this.setState({ sending: false });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={styles.container}>
        <Form onSubmit={this.handleSubmit} style={styles.form.body}>
          <div style={styles.form.section}>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Obrigatório' }]
              })(<Input disabled={this.state.sending} placeholder="Nome" />)}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('at_username', {
                rules: [
                  { required: true, message: 'Obrigatório' },
                  {
                    validator: async (rule, value, callback) => {
                      if (value.trim().includes(' ')) {
                        callback('Não são permitidos caracteres de espaço');
                      } else {
                        callback();
                      }
                    }
                  }
                ]
              })(
                <Input
                  disabled={this.state.sending}
                  placeholder="Utilizador AT"
                />
              )}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('at_password', {
                rules: [{ required: true, message: 'Obrigatório' }]
              })(
                <Input.Password
                  disabled={this.state.sending}
                  placeholder="Password AT"
                  onPressEnter={this.handleSubmit}
                />
              )}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              <div style={styles.actions.container}>
                <Tooltip title={'Guardar'}>
                  <Button
                    style={styles.actions.action}
                    loading={this.state.sending}
                    icon="check"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  />
                </Tooltip>
                <Tooltip title={'Cancelar'}>
                  <Button
                    style={styles.actions.action}
                    ghost
                    icon="close"
                    type="danger"
                    onClick={() => this.props?.cancel?.()}
                  ></Button>
                </Tooltip>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}
const InsertDossierForm = Form.create({ name: 'insert_client' })(InsertToc);
export default InsertDossierForm;
