import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Icon, Input, Button } from 'antd';

import globalStyles from '../../styles/global';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    minHeight: '33vh',
    padding: globalStyles.global.baseline * 2,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter,
    ...globalStyles.layout.alignCenter
  }
};
class ConfirmLogin extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      password: '',
      loading: false
    };

    this.confirm = this.confirm.bind(this);
  }

  confirm = async () => {
    this.setState({ loading: true });
    await Auth.signIn(this.props.username, this.state.password)

      .then(async user => {
        if (this.props.success) {
          try {
            await this.props.success(user);
          } catch (err) {
            if (this.props.error) {
              await this.props.error(err);
            }
            this.setState({ loading: false });
          }
        }
      })
      .catch(async e => {
        if (this.props.error) {
          await this.props.error(e);
        }
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div style={styles.container}>
        <h2 style={{ marginBottom: globalStyles.global.baseline * 2 }}>
          Confirme a sua password
        </h2>
        <div>
          <Input.Password
            id="signin-password"
            prefix={
              <Icon
                type="lock"
                style={{
                  color: 'rgba(0,0,0,.25)'
                }}
              />
            }
            disabled={this.state.loading}
            style={{ marginBottom: globalStyles.global.baseline * 2 }}
            placeholder="Password"
            value={this.state.password}
            onChange={e => {
              //log(e.target.value);
              return this.setState({
                password: e.target.value.trim()
              });
            }}
            onPressEnter={this.confirm}
          />

          <Button
            type="secondary"
            htmlType="submit"
            className="login-form-button"
            loading={this.state.loading}
            //setFieldsValue={this.state.username}
            onClick={this.confirm}
          >
            Confirmar Password
          </Button>
        </div>
      </div>
    );
  }
}

export default ConfirmLogin;
