import React from 'react';
import AppContext from '../../context/AppContextBase';
import { Helmet } from 'react-helmet';
import { Layout, message, Button } from 'antd';

import TocTable from '../../components/table/TocTable';
import InsertToc from '../../components/form/InsertToc';
import { getTocs, postToc } from '../../network/tocs';
import g from '../../styles/global';
import { mrg } from '../../common/util';
import DetailedMessage from '../../components/util/DetailedMessage';

const { Content } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  content: {
    paddingLeft: g.global.baseline,
    paddingRight: g.global.baseline
  },
  pageHeaderBox: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: g.global.baseline
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  pageHeaderButton: {
    marginLeft: g.global.baseline,
    ...g.layout.flexVertical,
    ...g.layout.flexCenter,
    ...g.layout.alignCenter
  },
  pageHeaderContainer: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    marginBottom: g.global.baseline,
    marginTop: g.global.baseline
  }
};

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      tocs: null,
      show: false
    };
    this.refresh = this.refresh.bind(this);
  }

  async componentDidMount() {
    await this.refresh();
  }

  async refresh() {
    let response = await getTocs();
    if (response?.result === 'OK' && response?.data) {
      this.setState({
        tocs: response.data
      });
    } else {
      DetailedMessage.error('Erro no pedido de dados ao servidor', response);
    }
  }

  render() {
    const separatorHeight = g.global.baseline;
    return (
      <>
        <Helmet>
          <title>Finpartner - TOCs</title>
        </Helmet>
        <div
          style={{
            backgroundColor: g.colors.background.queenBlue,
            width: '100%',
            height: separatorHeight
          }}
        ></div>
        <Content
          id="tocs"
          style={mrg([
            styles.fullWidth,
            g.layout.flexVertical,
            g.layout.flexStart,
            {
              maxHeight: `calc(100% - ${separatorHeight}px)`,
              overflowY: 'hidden'
            },
            styles.content
          ])}
        >
          <div
            style={mrg([
              g.layout.flexVertical,
              g.layout.flexStart,
              g.layout.alignStart,
              styles.fullWidth,
              { height: '100%', overflowY: 'scroll' }
            ])}
          >
            <div style={styles.pageHeaderContainer}>
              <span style={styles.pageHeaderContent}>TOCs</span>

              {!this.state.show && (
                <Button
                  style={styles.pageHeaderButton}
                  ghost
                  type="primary"
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  Inserir TOC
                </Button>
              )}
            </div>
            {this.state.show && (
              <InsertToc
                owner={{ id: this.context.state.userData.id }}
                action={client => postToc(client)}
                done={async () => {
                  await this.refresh();
                  this.setState({ show: false });
                  message.success(<span>Sucesso</span>);
                  return;
                }}
                cancel={() => this.setState({ show: false })}
              />
            )}

            {this.state.tocs && <TocTable data={this.state.tocs} />}
          </div>
        </Content>
      </>
    );
  }
}

export default Home;
