import { message as AntMessage } from 'antd4';

/** Singleton toaster instance. Create separate instances for different options. */

const AppToaster = {
  info: ({ message, timeout = 0.8 }) => {
    AntMessage.info(message, timeout);
  },
  error: ({ message, timeout = 0.8 }) => {
    AntMessage.error(message, timeout);
  },
  success: ({ message, timeout = 0.8 }) => {
    AntMessage.success(message, timeout);
  }
};

export default AppToaster;
