import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import Helmet from 'react-helmet';
import MediaQuery from 'react-responsive';
import { Layout, Menu, Icon } from 'antd';
import 'react-virtualized/styles.css';
import AppContext from './context/AppContextBase';
import Login from './pages/Login';
import breakpoints from './styles/breakpoints';
import { address } from './common/variables';
import ReportsHome from './pages/reports/Home';
import Clients from './pages/clients/Clients';
import Tocs from './pages/tocs/Tocs';
import Id from './pages/id/Id';
import DigitalJourney from './pages/DigitalJourney';
import Settings from './pages/settings';
import Profile from './components/Profile';
import globalStyles from './styles/global';
import './styles/override/index.scss';
import { mrg, log } from './common/util';
//import { fetchAllCases } from './network/citius';

import { notification } from 'antd';
import Amplify, { Hub } from 'aws-amplify';
import awsConfig from './aws-exports';
Amplify.configure(awsConfig);

const { Header, Sider, Content } = Layout;

let styles = {
  header: {
    height: globalStyles.global.baseline * 6,
    backgroundColor: globalStyles.colors.background.neutral,
    padding: 0,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  logoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: globalStyles.global.baseline * 2,
    minHeight: globalStyles.global.baseline * 2,
    borderRight: '1px solid #f0f2f5'
  },
  logo: {
    color: globalStyles.colors.background.caiadoBlue,
    fontSize: globalStyles.typography.size.base * 4,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    lineHeight: 1
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    fontWeight: 100,
    backgroundColor: 'transparent',
    width: '100%'
  },
  menuItem: {
    // minWidth: '10%',
    fontSize: globalStyles.typography.size.base * 2,
    fontWeight: 100,
    height: globalStyles.global.baseline * 6,
    padding: 0,
    paddingLeft: 24,
    paddingRight: 24,
    margin: 0
  },
  menuIconContainer: {
    minWidth: globalStyles.global.baseline * 5
  },
  menuIcon: {
    fontSize: globalStyles.global.baseline * 3,
    fontWeight: 100,
    lineHeight: 1,
    svg: {
      height: '100%'
    }
  },
  mobileMenuSider: {
    zIndex: 10,
    minWidth: '100vh',
    position: 'fixed',
    top: 0,
    left: 0
  },
  mobileMenuContainer: {
    minHeight: '100vh',
    padding: '24vmin',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    backgroundColor: 'white'
  },
  mobileMenu: {
    borderRight: 'unset'
  },
  mobileMenuToggle: {
    position: 'fixed',
    right: globalStyles.global.baseline * 2,
    top: globalStyles.global.baseline * 2,
    fontSize: globalStyles.typography.size.base * 2,
    transform: 'translateY(-50%)',
    zIndex: 20,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter
  },
  mobileMenuHeader: {
    height: globalStyles.global.baseline * 4,
    paddingRight: globalStyles.global.baseline * 2,
    marginBottom: globalStyles.global.baseline * 2,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter
  },
  mobileMenuItem: {
    fontSize: globalStyles.typography.size.base * 2,
    fontWeight: 100,
    minHeight: globalStyles.global.baseline * 6,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  link: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    height: '100%'
  },
  icon: {
    fontSize: globalStyles.typography.size.base * 2,
    alignSelf: 'center',
    fontWeight: 100,
    margin: 0
  }
};

styles = mrg([globalStyles, styles]);
class AppLogo extends React.Component {
  render() {
    const link = (
      <Link
        style={mrg([
          styles.logo,
          styles.link,
          { height: '100%', width: '100%', paddingRight: 48 }
        ])}
        onClick={this.props.onClick}
        key="logo"
        to="/"
      >
        Finpartner
        {/* <img height="100%" width="100%" src={logo} alt="logo" /> */}
      </Link>
    );
    return (
      <>
        <MediaQuery maxWidth={breakpoints.mdMax}>
          <div
            key="logo"
            style={mrg([
              styles.logoContainer,
              this.props.style,
              {
                minWidth: '20%',
                width: 'unset',
                height: '100%',
                fontSize: 'unset'
              }
            ])}
          >
            {link}
          </div>
        </MediaQuery>

        <MediaQuery minWidth={breakpoints.lgMin}>
          <div
            key="logo"
            style={mrg([
              styles.logoContainer,
              this.props.style,
              {
                minnWidth: '20%',
                width: 'unset',
                height: '100%',
                fontSize: 'unset'
              }
            ])}
          >
            {link}
          </div>
        </MediaQuery>
      </>
    );
  }
}

class AppMenu extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { collapsed: true, showProfile: false };

    this.toggle = this.toggle.bind(this);
    this.menu = this.menu.bind(this);
    this.checkPermission = this.context.checkPermission;
  }

  menu(isMobile) {
    return (
      <Menu
        theme="light"
        mode={isMobile ? 'inline' : 'horizontal'}
        defaultSelectedKeys={[this.props.currentMenuItem]}
        style={isMobile ? styles.mobileMenu : styles.menu}
      >
        {isMobile && (
          <div key="toggle" style={styles.mobileMenuHeader}>
            {<AppLogo style={{ width: 'auto' }} />}
          </div>
        )}
        {this.checkPermission('menu:clients') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="clients"
          >
            <Link style={styles.link} to="/clients">
              Clientes
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:tocs') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="tocs"
          >
            <Link style={styles.link} to="/tocs">
              TOCs
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:send') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="send"
          >
            <Link style={styles.link} to="/delivery">
              Envios
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:id') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="id"
          >
            <Link style={styles.link} to="/id">
              ID
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:journey') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="journey"
          >
            <Link style={styles.link} to="/journey">
              Digital Journey
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:settings') && (
          <Menu.Item
            style={
              isMobile
                ? styles.mobileMenuItem
                : mrg([
                    styles.menuItem,
                    styles.menuIconContainer,
                    { fontSize: 'unset', float: 'right' }
                  ])
            }
            key="menu5"
          >
            <Link style={styles.link} to="/settings">
              <Icon
                style={{
                  fontSize: globalStyles.global.baseline * 1.5
                }}
                type="setting"
              />
            </Link>
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            this.setState((prevState, props) => ({
              ...prevState,
              showProfile: !prevState.showProfile
            }));
          }}
          style={
            isMobile
              ? styles.mobileMenuItem
              : mrg([
                  styles.menuItem,
                  styles.menuIconContainer,
                  { fontSize: 'unset', float: 'right' }
                ])
          }
          key="profile"
        >
          <Profile
            visible={this.state.showProfile}
            handleClick={false}
            user={this.props.user}
            drawerWidth={isMobile ? '100vw' : '50vw'}
          />
        </Menu.Item>
      </Menu>
    );
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <>
        <MediaQuery maxWidth={breakpoints.mdMax}>
          <Layout>
            <Icon
              className="trigger"
              style={styles.mobileMenuToggle}
              type={this.state.collapsed ? 'menu' : 'close'}
              onClick={this.toggle}
            />
            <Sider
              trigger={null}
              reverseArrow={true}
              collapsible={true}
              collapsedWidth="0"
              collapsed={this.state.collapsed}
              width={'100vw'}
              onCollapse={(collapsed, type) => {
                log(collapsed, type);
              }}
              style={styles.mobileMenuSider}
            >
              <div style={styles.mobileMenuContainer}>{this.menu(true)}</div>
            </Sider>
          </Layout>
        </MediaQuery>
        <MediaQuery minWidth={breakpoints.lgMin}>
          <div
            key="menu"
            style={{ width: '80%', height: '100%', position: 'relative' }}
          >
            {this.menu(false)}
          </div>
        </MediaQuery>
      </>
    );
  }
}

AppMenu.contextType = AppContext;

class Root extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { currentMenuItem: 'home', authenticated: false };
    this.handleClick = this.handleClick.bind(this);

    this.handleHubAuthCapsule = this.handleHubAuthCapsule.bind(this);
    Hub.listen('auth', this.handleHubAuthCapsule, 'signOut');

    console.log('app context', this.context);

    this.authenticate = this.context.authenticate;
    this.logOut = this.context.logOut;
    this.checkPermission = this.context.checkPermission;
    this.auth = this.auth.bind(this);
  }

  handleHubAuthCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      if (payload.event === 'signOut') {
        this.logOut();
      } else if (payload.errors) {
        let msg = '';
        for (let outer in payload.errors) {
          const innerErrors = payload.errors[outer].errors;
          if (innerErrors) {
            for (let inner in innerErrors) {
              msg += innerErrors[inner];
            }
            msg += '\n';
          }
        }
        notification.warning('AWS Hub Error: \n' + msg, 8);
      }
    } else {
      log('hub: ', payload);
    }
  }

  async auth(data) {
    console.log('ROOT AUTHENTICATING');
    if (data) {
      try {
        await this.authenticate(data);
      } catch (err) {
        notification.error({
          message: 'Authentication Error',
          description: (
            <div>
              <span>Something went wrong. Error details:</span>
              <br />
              <span style={{ color: '#999' }}>{err.toString()}</span>
            </div>
          ),
          onClick: () => {}
        });
        console.error(err);
      }
    } else {
      this.logOut();
    }
  }

  handleClick = e => {
    this.setState({
      currentMenuItem: e.key
    });
  };

  render() {
    return !this.context.state.authenticated ? (
      <Login setAuthenticated={this.auth} />
    ) : (
      <div className="app">
        {console.log('LOGGEDF IN CONTET')}
        <Helmet>
          <meta charSet="utf-8" />
          <title>Finpartner</title>
          <link rel="canonical" href={address} />
        </Helmet>
        <>
          <Router>
            <Layout style={{ maxWidth: '100%', height: '100%' }}>
              <Header style={styles.header}>
                <AppLogo onClick={this.handleClick} />
                <AppMenu
                  user={this.context.state.userData}
                  handleClick={this.handleClick}
                  currentMenuItem={this.state.currentMenuItem}
                />
              </Header>
              <Content style={{}}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          ...globalStyles.layout.flexVertical,
                          ...globalStyles.layout.flexCenter
                        }}
                      >
                        <Icon
                          style={{
                            fontSize: 580,
                            color: '#EAEDF4'
                          }}
                          type="fund"
                        />
                      </div>
                    )}
                  />
                  {this.checkPermission('menu:clients') && (
                    <Route path="/clients" component={Clients} />
                  )}
                  {this.checkPermission('menu:tocs') && (
                    <Route path="/tocs" component={Tocs} />
                  )}
                  {this.checkPermission('menu:send') && (
                    <Route path="/delivery" component={ReportsHome} />
                  )}
                  {this.checkPermission('menu:id') && (
                    <Route path="/id" component={Id} />
                  )}
                  {this.checkPermission('menu:journey') && (
                    <Route path="/journey" component={DigitalJourney} />
                  )}
                  {this.checkPermission('menu:settings') && (
                    <Route path="/settings" component={Settings} />
                  )}
                  <Route
                    render={() => (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          ...globalStyles.layout.flexVertical,
                          ...globalStyles.layout.flexCenter
                        }}
                      >
                        <Redirect to="/" />
                      </div>
                    )}
                  />
                </Switch>
              </Content>
            </Layout>
          </Router>
        </>
      </div>
    );
  }
}

Root.contextType = AppContext;

export default Root;
