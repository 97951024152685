import React, { Component } from 'react';

import {
  Form,
  Icon,
  Input,
  Button,
  /*Checkbox,*/ Row,
  Col,
  message
} from 'antd';
import AppContext from '../context/AppContextBase';

import './Login.css';
import { Auth } from 'aws-amplify';
//import { TopContext } from '../Context';

const debug = false;
//eslint-disable-next-line
const log = debug ? console.log.bind(window.console) : () => {};

//config
// var logo =
//   'https://www.finpartner.pt/wp-content/uploads/2018/10/Logo_Finpartner_300x110.png';

const FormItem = Form.Item;

class Login extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.state = {
      signState: 'signIn',
      username: 'test',
      password: '',
      securityCode: '',
      submitRecover: false,
      renewPassword: false,
      confirmDirty: false
    };
  }

  componentDidMount() {
    //Auth.signOut();
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(data => {
        //log(data);
        this.props.setAuthenticated(data);
      })
      .catch(err => {
        //log(err);
        this.props.setAuthenticated(false);
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // Whaaaat the hell is this?
        //this.props.setAuthenticated(true);
        //log('Received values of form: ', values);
      }
    });
  };
  renderLogo() {
    return (
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 6, offset: 6 }}>
        <div
          style={{
            // display: 'flex',
            // backgroundImage: 'url(' + logo + ')',
            width: '260px',
            height: '140px',
            //backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
          }}
        />
      </Col>
    );
  }

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('renew-password')) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['renew-confirm'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    switch (this.state.signState) {
      case 'signIn':
        return this.renderSignIn();
      case 'forgotRecover':
        return this.renderForgotRecover();
      case 'renewPassword':
        return this.renderRenewPassword();
      //break;
      default:
        this.renderSignIn();
    }
  }

  renderSignIn() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Login-page">
        <Row type="flex" align="middle" style={{ height: '300px' }}>
          {this.renderLogo()}
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6 }}>
            {/*<Form onSubmit={this.handleSubmit} className="login-form">*/}
            <Form className="login-form">
              <FormItem>
                {getFieldDecorator('signin-username', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your username!'
                    }
                  ]
                })(
                  <Input
                    id="signin-username"
                    prefix={
                      <Icon
                        type="user"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    placeholder="Username (email)"
                    onChange={e => {
                      //log(e.target.value);
                      return this.setState({
                        username: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('signin-password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Password!'
                    }
                  ]
                })(
                  <Input
                    id="signin-password"
                    prefix={
                      <Icon
                        type="lock"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    type="password"
                    placeholder="Password"
                    onChange={e => {
                      //log(e.target.value);
                      return this.setState({
                        password: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>
              <FormItem>
                {/* {getFieldDecorator('signin-remember', {
                  initialValue: true
                })(<Checkbox id="signin-remember">Remember me</Checkbox>)} */}
                <a
                  href="#!"
                  className="login-form-forgot"
                  onClick={() =>
                    this.setState({
                      signState: 'forgotRecover'
                    })
                  }
                >
                  Recover password
                </a>
                <Button
                  type="secondary"
                  htmlType="submit"
                  className="login-form-button"
                  //setFieldsValue={this.state.username}
                  onClick={() => {
                    this.props.form.validateFields(async (err, values) => {
                      if (!err) {
                        await Auth.signIn(
                          this.state.username,
                          this.state.password
                        )

                          .then(async user => {
                            if (
                              user.challengeName === 'NEW_PASSWORD_REQUIRED'
                            ) {
                              message.warning(
                                'First time? Please renew you password'
                              );
                              this.setState({
                                password: '',
                                signState: 'renewPassword',
                                userParams: user
                              });
                            } else {
                              this.props.setAuthenticated(user);
                            }
                          })
                          .catch(e => {
                            if (e.message) {
                              message.error(e.message);
                            } else {
                              message.error(
                                'Unexpected Error. Please tryagain'
                              );
                              log(e);
                            }
                          });
                      }
                    });
                  }}
                >
                  Log in
                </Button>
                Or{' '}
                <a href="mailto:hello@perfectorange.pt?Subject=Caiado RPA Contact">
                  contacte o Suporte.
                </a>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
  renderForgotRecover() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Login-page">
        <Row type="flex" align="middle" style={{ height: '300px' }}>
          {this.renderLogo()}
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6 }}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              {!this.state.submitRecover ? (
                <>
                  <FormItem>
                    {getFieldDecorator('recover-username', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your email!'
                        }
                      ]
                    })(
                      <Input
                        id="recover-username"
                        prefix={
                          <Icon
                            type="user"
                            style={{
                              color: 'rgba(0,0,0,.25)'
                            }}
                          />
                        }
                        placeholder="Email"
                        onChange={e => {
                          //log(e.target.value);
                          return this.setState({
                            username: e.target.value.trim()
                          });
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      onClick={() => {
                        this.props.form.validateFields(async (err, values) => {
                          if (!err) {
                            await Auth.forgotPassword(this.state.username)
                              .then(data => {
                                //log(data);
                                this.setState({
                                  submitRecover: true
                                });
                              })
                              .catch(e => {
                                if (e.message) {
                                  message.error(e.message);
                                } else {
                                  message.error(
                                    'Unexpected Error. Please try again'
                                  );
                                  log(e);
                                }
                              });
                          }
                        });
                      }}
                    >
                      Send security code
                    </Button>
                    Or{' '}
                    <a
                      href="#!"
                      onClick={() =>
                        this.setState({
                          signState: 'signIn'
                        })
                      }
                    >
                      back to login.
                    </a>
                  </FormItem>{' '}
                </>
              ) : (
                <>
                  <FormItem>
                    {getFieldDecorator('recover-code', {
                      rules: [
                        {
                          required: true,
                          message: 'Type the code received on your email.'
                        }
                      ]
                    })(
                      <Input
                        id="recover-code"
                        prefix={
                          <Icon
                            type="lock"
                            style={{
                              color: 'rgba(0,0,0,.25)'
                            }}
                          />
                        }
                        type="username"
                        placeholder="Security Code"
                        onChange={e => {
                          //log(e.target.value);
                          return this.setState({
                            securityCode: e.target.value.trim()
                          });
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('recover-password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please type your new password!'
                        }
                      ]
                    })(
                      <Input
                        id="recover-password"
                        prefix={
                          <Icon
                            type="lock"
                            style={{
                              color: 'rgba(0,0,0,.25)'
                            }}
                          />
                        }
                        type="password"
                        placeholder="Password"
                        onChange={e => {
                          return this.setState({
                            password: e.target.value.trim()
                          });
                        }}
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    <Button
                      style={{
                        backgroundColor: '#3D9970',
                        borderColor: 'white',
                        color: 'white'
                      }}
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      onClick={() => {
                        this.props.form.validateFields(async (err, values) => {
                          if (!err) {
                            await Auth.forgotPasswordSubmit(
                              this.state.username,
                              this.state.securityCode,
                              this.state.password
                            )
                              .then(data => {
                                log(data, 'logged IN!');
                                this.props.setAuthenticated(data);
                                // console.log(data, 'logged IN!');
                                //"data" comes undefined (???)

                                //HACK below - user is recovered but must login again
                                this.setState({
                                  signState: 'signIn'
                                });
                              })
                              .catch(e => {
                                if (e.message) {
                                  message.error(e.message);
                                } else {
                                  message.error(
                                    'Unexpected Error. Please tryagain'
                                  );
                                  log(e);
                                }
                              });
                          }
                        });
                      }}
                    >
                      Confirm new password
                    </Button>
                  </FormItem>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  renderRenewPassword() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Login-page">
        <Row type="flex" align="middle" style={{ height: '300px' }}>
          {this.renderLogo()}
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6 }}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem key="renew-first-name">
                {getFieldDecorator('renew-first-name', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please type your first name'
                    }
                  ]
                })(
                  <Input
                    id="renew-first-name"
                    autoComplete="off"
                    prefix={
                      <Icon
                        type="user"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    placeholder="Confirm First Name"
                    onChange={e => {
                      log(e.target.value);
                      return this.setState({
                        firstName: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>

              <FormItem key="renew-last-name">
                {getFieldDecorator('renew-last-name', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please type your last name'
                    }
                  ]
                })(
                  <Input
                    id="renew-last-name"
                    prefix={
                      <Icon
                        type="user"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    autoComplete="off"
                    placeholder="Confirm Last Name"
                    onBlur={this.handleConfirmBlur}
                    onChange={e => {
                      log(e.target.value);
                      return this.setState({
                        lastName: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>

              <FormItem key="renew-password">
                {getFieldDecorator('renew-password', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please choose your password'
                    },
                    {
                      validator: this.checkConfirm
                    }
                  ]
                })(
                  <Input
                    id="renew-password"
                    prefix={
                      <Icon
                        type="lock"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    type="password"
                    placeholder="Password"
                    onBlur={this.handleConfirmBlur}
                    onChange={e => {
                      log(e.target.value);
                      return this.setState({
                        password: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>
              <FormItem key="renew-confirm">
                {getFieldDecorator('renew-confirm', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password'
                    },
                    {
                      validator: this.checkPassword
                    }
                  ]
                })(
                  <Input
                    id="renew-confirm"
                    prefix={
                      <Icon
                        type="lock"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    type="password"
                    placeholder="Confirm password"
                  />
                )}
              </FormItem>
              <FormItem>
                <Button
                  style={{
                    backgroundColor: '#3D9970',
                    borderColor: 'white',
                    color: 'white'
                  }}
                  type="default"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => {
                    this.props.form.validateFields(async (err, values) => {
                      if (!err) {
                        await Auth.completeNewPassword(
                          this.state.userParams, // the Cognito User Object - comes from Signin function
                          this.state.password, // the new password
                          {
                            given_name: this.state.firstName,
                            family_name: this.state.lastName
                          }
                        )
                          .then(user => {
                            log(user, 'logged IN!');
                            this.props.setAuthenticated(user);
                          })
                          .catch(e => {
                            if (e.message) {
                              message.error(e.message);
                            } else {
                              message.error(
                                'Unexpected Error. Please tryagain'
                              );
                              log(e);
                            }
                          });
                      }
                    });
                  }}
                >
                  Renew Password
                </Button>
                Or{' '}
                <a
                  href="#!"
                  onClick={() => this.setState({ signState: 'signIn' })}
                >
                  back to Login.
                </a>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

Login.contextType = AppContext;

const WrappedNormalLoginForm = Form.create()(Login);

export default WrappedNormalLoginForm;
