import React from 'react';
import AppContext from '../../../context/AppContextBase';
import globalStyles from '../../../styles/global';
import { getClientsWithDebt } from '../data/processClientDebt';
import { Transfer } from 'antd';
import autobind from 'auto-bind/react';
const styles = {
  mainRow: {
    height: '100%',
    overflowY: 'hidden',
    width: '100%'
  },
  col: {
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter,
    ...globalStyles.layout.alignCenter,
    height: '100%'
  },
  leftCol: {
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    height: '100%',
    backgroundColor: '#f0f2f5',
    borderRight: '1px solid #ddd',
    padding: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.5,
    fontWeight: 100,
    overflowX: 'hidden'
  },
  rightCol: {
    height: '100%'
  },
  list: {
    item: {
      title: {},
      subtitle: {
        fontWeight: 100,
        fontSize: globalStyles.global.baseline * 0.75,
        textTransform: 'uppercase'
      },
      subsubtitle: {
        fontWeight: 100,
        fontSize: globalStyles.global.baseline * 0.5,
        textTransform: 'uppercase'
      }
    }
  }
};

class Messaging extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    autobind(this);
    this.state = {
      clientList: [],
      clientsToSendMessage: [],
      lighterClientList: [] //working with a lighter array for search and moving
    };
  }

  componentDidMount() {
    let clientList = getClientsWithDebt(this.props.clients);
    clientList.sort((a, b) =>
      Number(a.totalOwned) > Number(b.totalOwned) ? 1 : -1
    );

    // console.log('clientList', clientList);
    let lighterClientList = clientList.map(client => {
      let lightClientInfo = {
        _id: client._id,
        name: client.main.nome,
        wechat: client.contact.wechat,
        telephone: client.contact.telephone,
        email: { isFilled: client.contact.email },
        totalOwned: client.totalOwned
      };

      return lightClientInfo;
    });

    this.setState({
      clientList,
      lighterClientList
    });
  }

  async componentDidUpdate(prevProps, prevState) {}

  handleClientChanges(nextTargetKeys, direction, moveKeys) {
    console.log('changes:', nextTargetKeys, direction, moveKeys);

    let clientsToSendMessage = this.state.lighterClientList.filter(client =>
      nextTargetKeys.includes(client._id)
    );

    this.setState({ clientsToSendMessage });
    console.log('clientsToSendMessage', clientsToSendMessage);
  }
  // filterOption(inputValue, option) {
  //   console.log('inputValue', inputValue, 'option', option);
  //   return option.nome.toLowerCase().includes(inputValue.toLowerCase());
  // }

  handleSearch(input, option) {
    console.log('search not working yet', input);

    // //mechanism to wait all the letters to be written before execute "search"

    // let timeoutHandle = setTimeout(() => {
    //   console.log('SEARCHING!!', input);
    // }, 3000);

    // // in your click function, call clearTimeout
    // clearTimeout(timeoutHandle);

    // // then call setTimeout again to reset the timer
    // timeoutHandle = setTimeout(() => {
    //   console.log('SEARCHING22!!', input);
    // }, 3000);
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Transfer
          //style={{ width: '100%', height: ' }}
          key={1}
          listStyle={{
            width: 'calc(50% - 20px)',
            height: 'calc(70vh )'
          }}
          titles={['Not sending message', 'Sending message']}
          rowKey={item => item._id}
          dataSource={this.state.lighterClientList}
          // filterOption={this.handleSearch}
          filterOption={(input, option) => {
            // console.log('input, option', input, option);
            // return option?.name?.toLowerCase().includes(input?.toLowerCase());
            return option?.name
              ?.toLowerCase()
              .indexOf(input?.toLowerCase() !== -1);
          }}
          showSearch
          // onSearch={this.handleSearch}
          targetKeys={this.state.clientsToSendMessage.map(item => item._id)}
          //onSelectChange={this.onSelectChange}
          // filterOption={this.filterAttachmentOption}
          onChange={this.handleClientChanges}
          render={item => ({
            value: item._id,
            label: item.name + '   |   Dívida: ' + item.totalOwned + '€'
          })}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: 12,
            borderRadius: 4,
            marginTop: 12,
            border: '1px solid #d9d9d9',
            backgroundColor: '#f5f8fa',
            fontSize: 14
          }}
        >
          Future Send Buttons
        </div>
      </div>
    );
  }
}

Messaging.contextType = AppContext;

export default Messaging;
