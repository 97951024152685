let address;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  address = 'http://localhost:4000/';
} else {
  // FIXME: add this after first deploy
  address = 'https://vicki.finpartner.pt/api/';
}

const departments = {
  base: {
    key: 'base',
    name: 'Finpartner Geral',
    description:
      'Acesso a todas as funcionalidades da plataforma, incluindo clientes, envios e TOCs.'
  },
  iva: {
    key: 'iva',
    name: 'IVA',
    description:
      'Acesso ao envio de declarações IVA em lote, bem como acesso de consulta à tabela de TOCs da plataforma (apenas nome e NIF parcial).'
  },
  irs: {
    key: 'iva',
    name: 'IVA',
    description: 'Acesso à criação de ficheiros XML IRS.'
  },
  id: {
    key: 'id',
    name: 'ID',
    description: 'Acesso à secção ID.'
  },
  all: {
    key: '*',
    name: 'Todos',
    description: 'Super grupo que inclui permissões de todos os departamentos'
  }
};
const roles = {
  dev: {
    key: 'dev',
    numeric: 3,
    name: 'Developer',
    description: 'Developer'
  },
  admin: {
    key: 'admin',
    numeric: 2,
    name: 'Administrador',
    description:
      'Plenos poderes na plataforma, com especial atenção à alteração e remoção de dados, entidades, e utilizadores.'
  },
  user: {
    key: 'user',
    numeric: 1,
    name: 'Utilizador',
    description:
      'Consulta de dados em modo leitura e envios em lote (quando aplicável ao departamento do utilizador). Não é permitida a criação nem remoção de utilizadores.'
  }
};

const scopes = {
  external: { key: 'external', name: 'external', numeric: 1 },
  internal: { key: 'internal', name: 'internal', numeric: 2 }
};

export { address, departments, roles, scopes };
