import React, { Fragment } from 'react';

import '../../../fields/Fields.scss';

class ArraySection extends React.Component {
  render() {
    const arrayData = this.props.data?.[this.props.dataPath] ?? [];
    return (
      <Fragment key={'service_payments'}>
        <div className="array-container w-100">
          <div className="d-flex justify-content-start align-items-center w-100 pt-2 pb-3">
            {this.props.renderToolbar?.()}
          </div>
          <div className="array-container-inner w-100">
            {arrayData.map?.((element, dataIndex) => {
              return React.cloneElement(this.props.children, {
                dataIndex,
                ...this.props,
                key: '' + element?._id + dataIndex
              });
            })}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ArraySection;
