import React from 'react';
import { Upload, Button, message, Icon } from 'antd';

import { uploadZipFile, ENDPOINTS } from '../network/uploadFile';

import Loading from './Loading';
import useFileHandlers from './useFileHandlers';

import globalStyles from '../styles/global';
import { mrg } from '../common/util';

import Dropzone from 'react-dropzone';

const styles = {
  form: {
    width: '100%',
    height: '100%'
  },
  uploader: {
    display: 'flex',
    width: '100%',
    height: '100%',
    zIndex: 10
  },
  uploaderInput: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: '3px dashed rgb(180,185,196)',
    backgroundColor: 'rgb(234,237,244)',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hiddenInput: {
    position: 'absolute',
    top: '-9999px'
  },
  feedback: {
    idle: {
      backgroundColor: 'transparent'
    },
    loaded: { backgroundColor: 'transparent' },
    pending: { backgroundColor: 'transparent' },
    success: { backgroundColor: 'transparent' },
    error: { backgroundColor: 'transparent' },
    title: {
      fontSize: globalStyles.global.baseline * 3,
      fontWeight: 100,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    },
    info: {
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none'
    },
    code: {
      fontSize: globalStyles.global.baseline,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none',
      fontFamily: 'monospace'
    },
    all: {
      zIndex: 20,
      width: '100%',
      height: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      paddingBottom: globalStyles.global.baseline,
      textAlign: 'center'
    }
  }
};

const Init = ({ multiple, onChange, onReject, accept, title, description }) => {
  return (
    <>
      <Dropzone
        accept={accept ? accept : null}
        style={styles.uploader}
        id="uploader-root"
        onDropAccepted={onChange}
        onDropRejected={onReject}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            style={mrg([styles.feedback.idle, styles.feedback.all])}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {title && <h2 style={styles.feedback.title}>{title}</h2>}
            <span style={styles.feedback.info}>
              {description
                ? description
                : 'Clique ou arraste um arquivo para esta caixa para iniciar o processo'}
            </span>
          </div>
        )}
      </Dropzone>
    </>
  );
};

const Loaded = () => (
  <div style={mrg([styles.feedback.loaded, styles.feedback.all])}>
    <h2 style={styles.feedback.title}>Ficheiro ZIP Seleccionado</h2>
    <Button type="secondary" style={styles.feedback.info} htmlType="submit">
      Submeter
    </Button>
  </div>
);

const Pending = ({ files, pending }) => {
  return (
    <div style={mrg([styles.feedback.pending, styles.feedback.all])}>
      <span style={styles.feedback.info}>A transferir ficheiros</span>
      <Loading style={{ margin: 0 }} />
    </div>
  );
};

const Success = redo => {
  return (
    <div style={mrg([styles.feedback.success, styles.feedback.all])}>
      <h2 style={styles.feedback.title}>Ficheiros Transferidos</h2>
    </div>
  );
};

const Error = ({ uploadError }) => (
  <div>
    <h2 style={mrg([styles.feedback.error, styles.feedback.all])}>
      Ocorreu um erro
    </h2>
    {uploadError && (
      <span style={styles.feedback.code}>{uploadError.message}</span>
    )}
  </div>
);
let selectedFile = null;

const onChangeHandler = event => {
  selectedFile = event.target.files[0];

  // this.setState({
  //   selectedFile:event.target.files[0] ,
  //   loaded: 0
  // });
};

const WA_uploadButton = async uploadEndpoint => {
  let result = await uploadZipFile(selectedFile, uploadEndpoint);
  console.log(result);
  await window.location.reload();
};

const onReject = err => {
  return message.error('Ficheiro Inválido: ' + JSON.stringify(err, null, 2));
};
const OldUpload = props => {
  const {
    files,
    pending,
    uploadError,
    status,
    // onSubmit,
    onChange
  } = useFileHandlers(props.uploadApi);

  if (status === 'FILES_UPLOADED') {
    setTimeout(() => props.done(), 1000);
  }

  const accept = props.accept ? props.accept : null;

  return (
    // <form style={styles.form} onSubmit={onSubmit}>
    <div id="uploaderInput" style={styles.uploaderInput}>
      {status === 'IDLE' && (
        <>
          <div style={{ width: '400px' }}>
            {/* <form method="POST" action="127.0.0.1:3000/financeiro/upload">
              Select a file: <input type="file" name="myFile" />
              <br />
              <br />
              <input type="submit" />
            </form> */}

            <span>
              Upload de ficheiro ZIP: Se necessário, recarregar a página depois
              do upload.
            </span>
            <br />
            <input type="file" name="file" onChange={onChangeHandler} />

            <button
              type="button"
              style={{
                background: '#eeeeee',
                border: '1px solid #aaaaaa',
                padding: 4,
                borderRadius: 3,
                cursor: 'pointer'
              }}
              className="btn btn-success btn-block"
              onClick={() => WA_uploadButton(props.endpoint)}
            >
              Upload
            </button>

            {/* <FilePond
                server=""
                files={testFiles}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              /> */}
          </div>

          {/* <Init
              title="Transferir Ficheiros"
              multiple={props.multiple === true}
              onChange={onChange}
              onReject={onReject}
              accept={accept}
            /> */}
        </>
      )}
      {status === 'LOADED' && <Loaded />}
      {status === 'PENDING' && <Pending files={files} pending={pending} />}
      {status === 'FILES_UPLOADED' &&
        (props.redo ? (
          <Init
            title={null}
            description="Clique ou arraste um arquivo para esta caixa para transferir mais ficheiros"
            multiple={props.multiple === true}
            onChange={onChange}
            onReject={onReject}
            accept={accept}
          />
        ) : (
          <Success />
        ))}
      {status === 'UPLOAD_ERROR' && <Error uploadError={uploadError} />}
    </div>
    // </form>
  );
};

export default OldUpload;
export { ENDPOINTS };

class AntUpload extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { fileList: [], uploading: false };
  }

  handleUpload = async () => {
    const { fileList } = this.state;
    // const formData = new FormData();
    // fileList.forEach(file => {
    //   formData.append('files[]', file);
    // });
    const file = fileList[0];

    if (!file || !this.props.endpoint) {
      throw new global.Error('AntUpload: Missing file or endpoint prop');
    }

    this.setState({
      uploading: true
    });

    console.log('sending zip: ', file);

    const response = await uploadZipFile(file, this.props.endpoint, 'file.zip');

    if (response?.result === 'OK') {
      this.setState({ FileList: [] });
      return await this.props.onSuccess?.();
    } else {
      return await this.props.onError?.(response);
    }
  };

  render() {
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      },

      onChange: async info => {
        if (info.file.status !== 'uploading') {
          console.log('uploading', info.file);
        }
        if (info.file.status === 'done') {
          console.log('done: ', info.file);
        } else if (info.file.status === 'error') {
          console.log('error: ', info.file);
        }
      },
      data: file => (file.name = 'file'),
      accept: this.props.accept || '.zip',
      fileList,
      name: this.props.name || 'file'
    };

    const selectLabel = this.props.selectLabel || 'Select File';
    const uploadingLabel = this.props.uploadingLabel || 'Uploading';
    const startLabel = this.props.startLabel || 'Start Upload';

    return (
      <div>
        <Upload {...props}>
          <Button disabled={this.props.disabled || this.state.fileList.length}>
            <Icon type="upload" /> {selectLabel}
          </Button>
        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          //loading={uploading}
          style={{ marginTop: 16 }}
          icon={uploading ? 'loading' : 'cloud-upload'}
        >
          {uploading ? uploadingLabel : startLabel}
        </Button>
      </div>
    );
  }
}

class TransformFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = { uploading: false };
  }

  render() {
    const props = {
      beforeUpload: async file => {
        return await this.props.onUpload?.(file);
      },
      accept: this.props.accept || '.xlsx',
      fileList: [], // Dont let ant handle the upload list state
      customRequest: () => {}
    };

    const selectLabel = this.props.selectLabel || 'Select File';

    return (
      <div
        className={
          this.props.containerClasses ??
          'transform-file d-flex justify-content-start align-items-start'
        }
      >
        <Upload {...props}>
          <Button
            disabled={this.props.disabled}
            loading={this.props.loading ?? false}
          >
            <Icon type="upload" /> {selectLabel}
          </Button>
        </Upload>
      </div>
    );
  }
}

export { AntUpload, TransformFile };
