import parser, { j2xParser as JSONparser } from 'fast-xml-parser';

export function processAnexoF(anexoF, errors, finalJSONFile) {
  console.log('Found anexo F', anexoF);

  let quadros = Object.keys(anexoF);

  //first we must know who is the "Titular do Rendimento"

  let nifTitular = '';
  let sujeitoPassivoA = '';
  let sujeitoPassivoB = '';

  //CHANGE AnexoJq03C03 according to JSON
  if (anexoF?.['3A3']?.[0]?.['Titular']) {
    nifTitular = anexoF['3A3'][0]['Titular'];
  }

  if (anexoF?.['301']?.[0]?.['Sujeito Passivo A']) {
    //Sujeito passivo A
    sujeitoPassivoA = anexoF['301'][0]['Sujeito Passivo A'];
  }

  if (anexoF?.['302']?.[0]?.['Sujeito Passivo B']) {
    //Sujeito passivo B
    sujeitoPassivoB = anexoF['302'][0]['Sujeito Passivo B'];
  }

  let anexoFTemporaryData = {
    //this temporary obj will be filled with all data. in the end is pushed to "final" IRS
    '@_id': nifTitular,
    // Quadro02: [{ AnexoJq02C01: 2020 }],
    // Quadro01: '',
    Quadro02: '',
    Quadro03: [
      {
        AnexoFq03C01: sujeitoPassivoA,
        AnexoFq03C02: sujeitoPassivoB,
        AnexoFq03C03: nifTitular
      }
    ],
    Quadro04: {},
    Quadro05: {},
    Quadro06: {
      AnexoFq06AT01: '',
      AnexoFq06BT01: '',
      AnexoFq06CT01: '',
      AnexoFq06DT01: '',
      AnexoFq06ET01: ''
    },
    Quadro07: {},
    Quadro08: {
      AnexoFq08AT01: '',
      AnexoFq08BT01: ''
    },
    Quadro09: {},
    Quadro10: {},
    Quadro11: {}
  };

  //console.log('temporaryData', temporaryData);

  quadros.forEach(quadro => {
    if (quadro === '41') {
      fillQuadro041(anexoF, anexoFTemporaryData);
    }

    if (quadro === '42') {
      fillQuadro042(anexoF, anexoFTemporaryData);
    }

    if (quadro === '42A') {
      fillQuadro042A(anexoF, anexoFTemporaryData);
    }
    if (quadro === '43') {
      fillQuadro043(anexoF, anexoFTemporaryData);
    }

    if (quadro === '5') {
      fillQuadro05(anexoF, anexoFTemporaryData);
    }

    // if (quadro === '6A') {
    //   fillQuadro06A(anexoF, anexoFTemporaryData);
    // }

    if (quadro === '6F') {
      fillQuadro06F(anexoF, anexoFTemporaryData);
    }
    if (quadro === '7') {
      fillQuadro07(anexoF, anexoFTemporaryData);
    }

    if (quadro === '92') {
      fillQuadro092(anexoF, anexoFTemporaryData);
    }

    if (quadro === '93') {
      fillQuadro093(anexoF, anexoFTemporaryData);
    }
    if (quadro === '10') {
      fillQuadro010(anexoF, anexoFTemporaryData);
    }
    if (quadro === '11') {
      fillQuadro011(anexoF, anexoFTemporaryData);
    }
  });

  finalJSONFile.AnexoF.push(anexoFTemporaryData);

  return {};

  function fillQuadro041(anexoF, anexoFTemporaryData) {
    let somaRendas = 0;
    let somaRetencaoNaFonte = 0;
    let somaConservacaoManutencao = 0;
    let somaCondominio = 0;
    let somaImpostoMunicipalImoveis = 0;
    let somaImpostoSelo = 0;
    let somataxasAutarquicas = 0;
    let somaOutros = 0;
    let somaValor = 0;

    let tableQuadro041 = anexoF['41'].map((entry, index) => {
      let Freguesia = entry['Freguesia'] ?? '';
      let TipoPredio = entry['TipoPredio'] ?? '';
      let Artigo = entry['Artigo'] ?? '';
      let Fraccao = entry['Fraccao'] ?? '';
      let Titular = entry['Titular'] ?? '';
      let ValorIliquidoRendas = entry['ValorIliquidoRendas'] ?? '';
      let NaturezaRendas = entry['NaturezaRendas'] ?? '';
      let RetencoesFonte = entry['RetencoesFonte'] ?? '';
      let NIFArrendatario = entry['NIFArrendatario'] ?? '';
      let CodPais = entry['CodPais'] ?? '';
      let ConservacaoManutencao = entry['ConservacaoManutencao'] ?? '';
      let Condominio = entry['Condominio'] ?? '';
      let imi = entry['IMI'] ?? '';
      let ImpostoSelo = entry['ImpostoSelo'] ?? '';
      let TaxasAutarquicas = entry['TaxasAutarquicas'] ?? '';
      let Outros = entry['Outros'] ?? '';
      let AnoInicioContrato = entry['AnoInicioContrato'] ?? '';
      let MesInicioContrato = entry['MesInicioContrato'] ?? '';
      let AnoInicioGastos = entry['AnoInicioGastos'] ?? '';
      let MesInicioGastos = entry['MesInicioGastos'] ?? '';
      let Valor = entry['Valor'] ?? '';

      if (ValorIliquidoRendas) {
        //Rendas
        somaRendas += ValorIliquidoRendas;
      }

      if (RetencoesFonte) {
        somaRetencaoNaFonte += RetencoesFonte;
      }

      if (ConservacaoManutencao) {
        somaConservacaoManutencao += ConservacaoManutencao;
      }

      if (Condominio) {
        somaCondominio += Condominio;
      }
      if (imi) {
        somaImpostoMunicipalImoveis += imi;
      }

      if (ImpostoSelo) {
        somaImpostoSelo += ImpostoSelo;
      }

      if (TaxasAutarquicas) {
        somataxasAutarquicas += TaxasAutarquicas;
      }

      if (Outros) {
        somaOutros += Outros;
      }

      if (Valor) {
        somaValor += Valor;
      }

      return {
        '@_numero': index + 1,
        NLinha: 4001 + index,

        Freguesia,
        TipoPredio,
        Artigo,
        Fraccao,
        Titular,
        ValorIliquidoRendas,
        NaturezaRendas,
        RetencoesFonte,
        NIFArrendatario,
        CodPais,
        ConservacaoManutencao,
        Condominio,
        IMI: imi,
        ImpostoSelo,
        TaxasAutarquicas,
        Outros,
        AnoInicioContrato,
        MesInicioContrato,
        AnoInicioGastos,
        MesInicioGastos,
        Valor
      };
    });

    anexoFTemporaryData.Quadro04.AnexoFq041T01 = [
      {
        'AnexoFq041T01-Linha': tableQuadro041
      }
    ];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC01 = [somaRendas];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC02 = [somaRetencaoNaFonte];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC03 = [
      somaConservacaoManutencao
    ];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC04 = [somaCondominio];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC05 = [
      somaImpostoMunicipalImoveis
    ];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC06 = [somaImpostoSelo];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC07 = [somataxasAutarquicas];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC08 = [somaOutros];
    anexoFTemporaryData.Quadro04.AnexoFq041T01SomaC09 = [somaValor];
  }

  function fillQuadro042(anexoF, anexoFTemporaryData) {
    let somaRendas = 0;
    let somaRetencaoNaFonte = 0;
    let somaConservacaoManutencao = 0;
    let somaCondominio = 0;
    let somaImpostoMunicipalImoveis = 0;
    let somaImpostoSelo = 0;
    let somataxasAutarquicas = 0;
    let somaOutros = 0;
    let somaValor = 0;

    let tableQuadro042 = anexoF['42'].map((entry, index) => {
      let NContrato = entry['NContrato'] ?? '';
      let Freguesia = entry['Freguesia'] ?? '';
      let TipoPredio = entry['TipoPredio'] ?? '';
      let Artigo = entry['Artigo'] ?? '';
      let Fraccao = entry['Fraccao'] ?? '';
      let Titular = entry['Titular'] ?? '';
      let ValorIliquidoRendas = entry['ValorIliquidoRendas'] ?? '';
      let NaturezaRendas = entry['NaturezaRendas'] ?? '';
      let RetencoesFonte = entry['RetencoesFonte'] ?? '';
      let NIFArrendatario = entry['NIFArrendatario'] ?? '';
      let CodPais = entry['CodPais'] ?? '';
      let ConservacaoManutencao = entry['ConservacaoManutencao'] ?? '';
      let Condominio = entry['Condominio'] ?? '';
      let imi = entry['IMI'] ?? '';
      let ImpostoSelo = entry['ImpostoSelo'] ?? '';
      let TaxasAutarquicas = entry['TaxasAutarquicas'] ?? '';
      let Outros = entry['Outros'] ?? '';
      let AnoInicioContrato = entry['AnoInicioContrato'] ?? '';
      let MesInicioContrato = entry['MesInicioContrato'] ?? '';
      let AnoInicioGastos = entry['AnoInicioGastos'] ?? '';
      let MesInicioGastos = entry['MesInicioGastoss'] ?? '';
      let Valor = entry['Valor'] ?? '';

      if (ValorIliquidoRendas) {
        //Rendas
        somaRendas += ValorIliquidoRendas;
      }

      if (RetencoesFonte) {
        somaRetencaoNaFonte += RetencoesFonte;
      }

      if (ConservacaoManutencao) {
        somaConservacaoManutencao += ConservacaoManutencao;
      }

      if (Condominio) {
        somaCondominio += Condominio;
      }
      if (imi) {
        somaImpostoMunicipalImoveis += imi;
      }

      if (ImpostoSelo) {
        somaImpostoSelo += ImpostoSelo;
      }

      if (TaxasAutarquicas) {
        somataxasAutarquicas += TaxasAutarquicas;
      }

      if (Outros) {
        somaOutros += Outros;
      }

      if (Valor) {
        somaValor += Valor;
      }

      return {
        '@_numero': index + 1,
        NLinha: 4501 + index,
        NContrato,
        Freguesia,
        TipoPredio,
        Artigo,
        Fraccao,
        Titular,
        ValorIliquidoRendas,
        NaturezaRendas,
        RetencoesFonte,
        NIFArrendatario,
        CodPais,
        ConservacaoManutencao,
        Condominio,
        IMI: imi,
        ImpostoSelo,
        TaxasAutarquicas,
        Outros,
        AnoInicioContrato,
        MesInicioContrato,
        AnoInicioGastos,
        MesInicioGastos,
        Valor
      };
    });

    anexoFTemporaryData.Quadro04.AnexoFq042T01 = [
      {
        'AnexoFq042T01-Linha': tableQuadro042
      }
    ];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC01 = [somaRendas];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC02 = [somaRetencaoNaFonte];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC03 = [
      somaConservacaoManutencao
    ];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC04 = [somaCondominio];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC05 = [
      somaImpostoMunicipalImoveis
    ];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC06 = [somaImpostoSelo];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC07 = [somataxasAutarquicas];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC08 = [somaOutros];
    anexoFTemporaryData.Quadro04.AnexoFq042T01SomaC09 = [somaValor];
  }

  function fillQuadro042A(anexoF, anexoFTemporaryData) {
    let tableQuadro042A = anexoF['42A'].map((entry, index) => {
      let eachItem = {}; //buildind the object on the right format for export
      Object.entries(entry).forEach(([key, value]) => {
        eachItem[key] = value;
      });

      return {
        '@_numero': index + 1,
        NLinha: 4501 + index,
        ...eachItem
      };
    });

    anexoFTemporaryData.Quadro04.AnexoFq042T02 = [
      {
        'AnexoFq042T02-Linha': tableQuadro042A
      }
    ];
  }

  function fillQuadro043(anexoF, anexoFTemporaryData) {
    let somaValorIliquidoRendimento = 0;
    let somaRetencoesFonte = 0;
    let somaConservacaoExtraordinaria = 0;
    let somaCondominio = 0;
    let somaOutros = 0;

    let tableQuadro043 = anexoF['43'].map((entry, index) => {
      let eachItem = {}; //buildind the object on the right format for export
      Object.entries(entry).forEach(([key, value]) => {
        if (key === 'ValorIliquidoRendimento') {
          somaValorIliquidoRendimento += value;
        }
        if (key === 'RetencoesFonte') {
          somaRetencoesFonte += value;
        }
        if (key === 'ValorIliquidoRendimento') {
          somaConservacaoExtraordinaria += value;
        }
        if (key === 'ValorIliquidoRendimento') {
          somaCondominio += value;
        }
        if (key === 'Outros') {
          somaOutros += value;
        }

        eachItem[key] = value;
      });

      return {
        '@_numero': index + 1,
        NLinha: 4901 + index,
        ...eachItem
      };
    });

    anexoFTemporaryData.Quadro04.AnexoFq043T01 = [
      {
        'AnexoFq043T01-Linha': tableQuadro043
      }
    ];

    anexoFTemporaryData.Quadro04.AnexoFq043T01SomaC01 = [
      somaValorIliquidoRendimento
    ];
    anexoFTemporaryData.Quadro04.AnexoFq043T01SomaC02 = [somaRetencoesFonte];
    anexoFTemporaryData.Quadro04.AnexoFq043T01SomaC03 = [
      somaConservacaoExtraordinaria
    ];
    anexoFTemporaryData.Quadro04.AnexoFq043T01SomaC04 = [somaCondominio];
    anexoFTemporaryData.Quadro04.AnexoFq043T01SomaC05 = [somaOutros];
  }

  function fillQuadro05(anexoF, anexoFTemporaryData) {
    let somaRendaRecebida = 0;
    let somaRetencoes = 0;
    let somaRendaPaga = 0;

    let tableQuadro05 = anexoF['5'].map((entry, index) => {
      let Titular = entry['Titular'] ?? '';
      let RendaRecebida = entry['RendaRecebida'] ?? '';
      let Retencoes = entry['Retencoes'] ?? '';
      let NifSublocatario = entry['NifSublocatario'] ?? '';
      let CodPais = entry['CodPais'] ?? '';
      let RendaPaga = entry['RendaPaga'] ?? '';
      let NifSenhorio = entry['NifSenhorio'] ?? '';

      if (RendaRecebida) {
        somaRendaRecebida += RendaRecebida;
      }

      if (Retencoes) {
        somaRetencoes += Retencoes;
      }

      if (RendaPaga) {
        somaRendaPaga += RendaPaga;
      }

      return {
        '@_numero': index + 1,
        NLinha: 5001 + index,
        Titular,
        RendaRecebida,
        Retencoes,
        NifSublocatario,
        CodPais,
        RendaPaga,
        NifSenhorio
      };
    });

    anexoFTemporaryData.Quadro05 = [
      {
        AnexoFq05T01: [
          {
            'AnexoFq05T01-Linha': tableQuadro05
          }
        ],
        AnexoFq05T01SomaC01: [somaRendaPaga],
        AnexoFq05T01SomaC02: [somaRetencoes],
        AnexoFq05T01SomaC03: [somaRendaRecebida]
      }
    ];
  }

  // function fillQuadro06A(anexoF, anexoFTemporaryData) {
  //   let tableQuadro06A = anexoF['6A'].map((entry, index) => {
  //     let CamposQuadro41 = entry['CamposQuadro41'] ?? '';

  //     return {
  //       '@_numero': index + 1,
  //       CamposQuadro41
  //     };
  //   });

  //   anexoFTemporaryData.Quadro06.AnexoFq06AT01 = [
  //     {
  //       'AnexoFq06AT01-Linha': tableQuadro06A
  //     }
  //   ];
  // }

  function fillQuadro06F(anexoF, anexoFTemporaryData) {
    let entry = anexoF['6F']?.[0];

    let Englobamento = '';

    if (entry?.['Sim'] === 'X') {
      Englobamento = 'S';
    }
    if (entry?.['Não'] === 'X') {
      Englobamento = 'N';
    }

    anexoFTemporaryData.Quadro06.AnexoFq06B06 = [Englobamento];
  }

  function fillQuadro07(anexoF, anexoFTemporaryData) {
    let somaRendimentoDistribuido = 0;
    let somaRetencoesFonte = 0;

    let tableQuadro7 = anexoF['7'].map((entry, index) => {
      let Titular = entry['Titular'] ?? '';
      let NIFEntidadeEmitente = entry['NIFEntidadeEmitente'] ?? '';
      let RendimentoDistribuido = entry['RendimentoDistribuido'] ?? '';
      let RetencoesFonte = entry['RetencoesFonte'] ?? '';
      let NIFEntidadeRetentora = entry['NIFEntidadeRetentora'] ?? '';

      if (RendimentoDistribuido) {
        somaRendimentoDistribuido += RendimentoDistribuido;
      }
      if (RetencoesFonte) {
        somaRetencoesFonte += RetencoesFonte;
      }

      return {
        '@_numero': index + 1,
        NLinha: 7001 + index,
        Titular,
        NIFEntidadeEmitente,
        RendimentoDistribuido,
        RetencoesFonte,
        NIFEntidadeRetentora
      };
    });

    anexoFTemporaryData.Quadro07 = [
      {
        AnexoFq07T01: [
          {
            'AnexoFq07T01-Linha': tableQuadro7
          }
        ],
        AnexoFq07T01SomaC01: [somaRendimentoDistribuido],
        AnexoFq07T01SomaC02: [somaRetencoesFonte]
      }
    ];
  }

  function fillQuadro092(anexoF, anexoFTemporaryData) {
    let entry = anexoF['92']?.[0];
    let ValorAdicionalImi = entry['ValorAdicionalImi'] ?? '';
    anexoFTemporaryData.Quadro09.AnexoFq09C9101 = [ValorAdicionalImi];
  }

  function fillQuadro093(anexoF, anexoFTemporaryData) {
    let entry = anexoF['93']?.[0];
    let ValorTributavelAdicionalImi =
      entry['ValorTributavelAdicionalImi'] ?? '';
    anexoFTemporaryData.Quadro09.AnexoFq09C9102 = [ValorTributavelAdicionalImi];
  }

  function fillQuadro010(anexoF, anexoFTemporaryData) {
    let tableQuadro010 = anexoF['10'].map((entry, index) => {
      let eachItem = {}; //buildind the object on the right format for export
      Object.entries(entry).forEach(([key, value]) => {
        eachItem[key] = value;
      });

      return {
        '@_numero': index + 1,
        NLinha: 10001 + index,
        ...eachItem
      };
    });

    anexoFTemporaryData.Quadro10.AnexoFq10T01 = [
      {
        'AnexoFq10T01-Linha': tableQuadro010
      }
    ];
  }
  function fillQuadro011(anexoF, anexoFTemporaryData) {
    let tableQuadro011 = anexoF['11'].map((entry, index) => {
      let eachItem = {}; //buildind the object on the right format for export
      Object.entries(entry).forEach(([key, value]) => {
        eachItem[key] = value;
      });

      return {
        '@_numero': index + 1,
        NLinha: 11001 + index,
        ...eachItem
      };
    });

    anexoFTemporaryData.Quadro11.AnexoFq11T01 = [
      {
        'AnexoFq11T01-Linha': tableQuadro011
      }
    ];
  }
}
