import React from 'react';

import autobind from 'auto-bind/react';
import { Helmet } from 'react-helmet';
import { Layout, Steps, Icon, message, Button } from 'antd';

import AppContext from '../../../context/AppContextBase';
import { AntUpload } from '../../../components/Upload';
import Loading from '../../../components/Loading';
import Report from './Report';

import {
  fetchSystemState,
  setSystemState,
  sendIva,
  fetchReport,
  reset
} from '../../../network/iva';
import { getTocs } from '../../../network/tocs';
import { scopes } from '../../../components/table/iva/ReportTable';
import { ENDPOINTS } from '../../../network/uploadFile';
import g from '../../../styles/global';
import DetailedMessage from '../../../components/util/DetailedMessage';

const { Content } = Layout;
const Step = Steps.Step;

const styles = {
  fullWidth: {
    width: '100%'
  },
  content: {
    padding: g.global.baseline,
    paddingTop: g.global.baseline * 2,
    paddingBottom: g.global.baseline * 2
  },
  table: {
    width: '100%',
    fontSize: g.global.baseline * 1.2,
    fontWeight: 100
  },
  column: {
    fontSize: g.global.baseline * 1.2,
    fontWeight: 100,
    padding: 0,
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  action: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    width: '100%',
    marginBottom: g.global.baseline,
    fontSize: g.global.baseline * 1.2
  },
  tag: {
    marginBottom: g.global.baseline * 0.5,
    fontSize: g.global.baseline * 0.8,
    padding: '0 ' + g.global.baseline * 0.5 + 'px'
  },
  radio: {
    button: {
      height: 'auto'
    },
    group: {
      marginBottom: g.global.baseline
    },
    title: { marginBottom: g.global.baseline * 0.5 }
  },
  pageHeaderBox: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: g.global.baseline
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: g.global.baseline
  },
  client: {
    title: {
      marginBottom: g.global.baseline * 0.5,
      fontSize: g.global.baseline * 1.6,
      fontWeight: 700,
      color: '#888888',
      width: '100%'
    },
    infoBox: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      alignItems: 'center',
      width: '100%'
    },
    description: {
      marginRight: g.global.baseline * 1.2,
      fontSize: g.global.baseline,
      color: '#666666'
    }
  },
  timeline: {
    item: {
      padding: 0,
      margin: 0,
      minHeight: 0
    }
  },
  steps: {
    content: {
      width: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignCenter,
      borderRadius: g.global.baseline * 2
    },
    action: {
      width: '100%',
      marginTop: '24px'
    }
  },
  feedback: {
    title: {
      fontSize: g.global.baseline * 4,
      fontWeight: 100,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: g.global.baseline,
      textAlign: 'center'
    },
    info: {
      fontSize: g.global.baseline * 1.5,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none',
      paddingBottom: g.global.baseline
    },
    main: {
      zIndex: 20,
      width: '100%',
      height: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      fontSize: g.global.baseline * 2,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: g.global.baseline,
      textAlign: 'center'
    }
  }
};

const states = {
  idle: 'idle',
  uploading: 'uploading',
  processing: 'processing',
  consistency: 'consistency',
  sending: 'sending',
  sent: 'sent',
  error: 'error'
};

const targets = {
  production: { key: 'production', label: 'Produção' },
  test: { key: 'test', label: 'Testes' }
};

const getStepFromState = stateName => {
  switch (stateName) {
    case states.error:
      return { current: -1, waiting: false };
    case states.idle:
      return { current: 0, waiting: false };
    case states.uploading:
      return { current: 0, waiting: true };
    case states.processing:
      return { current: 1, waiting: true };
    case states.consistency:
      return { current: 1, waiting: false };
    case states.sending:
      return { current: 2, waiting: true };
    case states.sent:
      return { current: 2, waiting: false };
    default:
      return { current: 0, waiting: true };
  }
};

const formatError = error => {
  if (error?.code || error?.message || error?.context) {
    return (
      <>
        <span style={styles.feedback.info}>
          <span style={{ fontWeight: error.code ? 300 : 'unset' }}>
            {error.code && `Código ${error.code}: `}
          </span>
          <span>{error.message ?? 'Não especificado'}</span>
          {error.context && (
            <pre
              style={{
                fontSize: g.global.baseline,
                paddingTop: g.global.baseline
              }}
            >
              Context: {error.context}
            </pre>
          )}
        </span>
      </>
    );
  } else {
    return 'Erro não especificado';
  }
};

// Antd made me do it...
const nullOption = {
  key: 'null',
  value: null,
  label: 'N/A',
  maybeNull: function(originalValue) {
    return originalValue === this.key ? this.value : originalValue;
  }
};

class IvaReport extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      current: -1,
      waiting: false,
      consistency: null,
      report: null,
      pollTimer: null,
      target: targets.production.key
    };
    autobind(this);
  }

  async componentDidMount() {
    await this.refreshState();
    await this.getTocs();
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.stopPollState();
  }

  async gotoStep(step) {
    await setSystemState(step);
    this.refreshState();
  }

  async getTocs() {
    let response = await getTocs();

    if (response?.result === 'OK' && response?.data) {
      this.setState({ tocs: response.data });
    } else {
      DetailedMessage.error(
        'Erro no pedido de TOCS ao servidor: ' + response?.message,
        response
      );
    }
  }

  async pollState() {
    // are we polling already ?
    if (this.state.pollTimer) {
      return;
    }
    // otherwise, create the polling function
    const checkState = async () => {
      // are we there yet?
      let req = await fetchSystemState();
      if (!(req?.result === 'OK' && req?.data)) {
        return;
      }
      // if so, get the results
      if (req.data.systemState === states.error) {
        const currentObj = getStepFromState(req.data.systemState);
        this.setState({ ...currentObj, error: req.data });
        this.stopPollState();
      }
      if (req.data.systemState === states.idle) {
        this.setState({ consistency: null, report: null });
        this.stopPollState();
      }
      if (req.data.systemState === states.consistency) {
        req = await fetchReport();

        this.setState({ report: null, consistency: req.data });
        this.stopPollState();
      }

      if (req.data.systemState === states.sending) {
        const currentObj = getStepFromState(req.data.systemState);
        this.setState({ ...currentObj, sendingProgress: req.data.progress });
        //this.stopPollState();
      }

      if (req.data.systemState === states.sent) {
        req = await fetchReport();

        this.setState({
          consistency: null,
          report: req.data,
          sendingProgress: null
        });
        this.stopPollState();
      }
    };

    const pollTimer = setInterval(checkState, 2000);
    this.setState({ pollTimer: pollTimer });
  }

  async stopPollState() {
    if (!this.state.pollTimer) {
      return;
    }
    console.log('IVA stopped polling server state');
    clearInterval(this.state.pollTimer);
    this.setState({ pollTimer: null });
  }

  async refreshState(forceState) {
    let response = {};
    if (forceState) {
      response.result = 'OK';
      response.data = { systemState: forceState, data: null };
    } else {
      response = await fetchSystemState();
      console.log({ response });
    }

    if (response?.result === 'OK' && response?.data) {
      const currentObj = getStepFromState(response.data.systemState);
      this.setState({ ...currentObj });
      this.takeStateAction(response.data.systemState, response.data.progress);
    } else {
      DetailedMessage.error(
        'Erro no pedido de estado de sistema ao servidor: ' + response?.message,
        response
      );
    }
  }

  takeStateAction(stateName, data = null) {
    // intentionnal fallthrough
    switch (stateName) {
      case states.error:
        if (data) {
          this.setState({ error: data });
        }
        break;
      case 'processing':
      case 'consistency':
      case 'sending':
      case 'sent':
        this.pollState();
        break;
      default:
        break;
    }
  }

  getContent(step) {
    switch (step) {
      case -1:
        console.log(this.state);
        return (
          <div style={styles.feedback.main}>
            <div style={styles.feedback.title}>Erro</div>
            {formatError(this.state.error)}

            <Button
              type="danger"
              ghost
              style={styles.pageHeaderButton}
              onClick={this.reset}
            >
              REINICIAR PROCESSO
            </Button>
          </div>
        );
      case 0:
        return (
          <>
            <div style={{ ...styles.client.title, width: 'auto' }}>
              Ficheiro ZIP
            </div>
            <AntUpload
              disabled={
                /*!this.state.tocs || !this.state.toc || */ !this.state.target
              }
              endpoint={ENDPOINTS.ivaUpload}
              accept=".zip"
              selectLabel="Seleccionar ficheiro ZIP"
              uploadingLabel="A enviar"
              startLabel="Enviar"
              onSuccess={this.refreshState}
              onError={err =>
                DetailedMessage.error('Erro a enviar ficheiro', err)
              }
            ></AntUpload>
          </>
        );
      case 1:
        return (
          <Report
            reset={this.reset}
            onSend={this.send}
            scope={scopes.CONSISTENCY}
            data={this.state.consistency}
          />
        );
      case 2:
        return (
          <Report
            scope={scopes.REPORT}
            data={this.state.report}
            reset={this.reset}
            progress={this.state.sendingProgress || null}
          />
        );
      default:
        return <Loading />;
    }
  }

  async send() {
    let tocId = nullOption.maybeNull(this.state.toc);

    const req = sendIva(tocId, this.state.target);

    if (!(req?.result === 'OK')) {
      console.log('refreshing');
      await this.refreshState();
    } else {
      message.error(
        'Ocorreu um erro. Verifique a sua ligação à internet e tente novamente.'
      );
    }
    setTimeout(async () => await this.refreshState(), 300);
  }

  async reset() {
    const req = await reset();
    if (req?.result === 'OK') {
      this.setState({
        loading: false,
        current: 0,
        waiting: false,
        consistency: null,
        report: null,
        availableVars: null
      });
      this.refreshState();
    } else {
      message.error(
        'Ocorreu um erro. Verifique a sua ligação à internet e tente novamente.'
      );
    }
  }

  getStepIcon(step) {
    switch (step) {
      case states.idle:
      case states.uploading:
        return (
          <div style={{ ...styles.action, fontSize: g.global.baseline * 2 }}>
            <Icon type="cloud-upload-o" />

            {/* {process.env.NODE_ENV === 'development' && ( */ false && (
              <Icon
                style={{
                  color: g.colors.feedback.error,
                  fontSize: g.global.baseline,
                  paddingLeft: g.global.baseline / 2
                }}
                type="play-circle"
                onClick={() => this.gotoStep(states.idle)}
              />
            )}
          </div>
        );
      case states.consistency:
        return (
          <div style={{ ...styles.action, fontSize: g.global.baseline * 2 }}>
            <Icon type="check-circle-o" />
            {/* {process.env.NODE_ENV === 'development' && ( */ false && (
              <Icon
                style={{
                  color: g.colors.feedback.error,
                  fontSize: g.global.baseline,
                  paddingLeft: g.global.baseline / 2
                }}
                type="play-circle"
                onClick={() => this.gotoStep(states.consistency)}
              />
            )}
          </div>
        );
      case states.sending:
        return (
          <div style={{ ...styles.action, fontSize: g.global.baseline * 2 }}>
            <Icon type="mail" />
            {/* {process.env.NODE_ENV === 'development' && ( */ false && (
              <Icon
                style={{
                  color: g.colors.feedback.error,
                  fontSize: g.global.baseline,
                  paddingLeft: g.global.baseline / 2
                }}
                type="play-circle"
                onClick={() => this.gotoStep(states.sent)}
              />
            )}
          </div>
        );

      default:
        return <Icon type="question-circle-o" />;
    }
  }

  render() {
    const { current } = this.state;
    return (
      <>
        <Helmet>
          <title>Finpartner - IVA</title>
        </Helmet>

        <Content
          style={{
            ...styles.fullWidth,
            ...g.layout.flexVertical,
            ...g.layout.flexStart,
            maxHeight: '100%',
            overflowY: 'scroll',
            ...styles.content
          }}
        >
          {this.state.loading ? (
            <div style={styles.feedback.main}>
              <span style={styles.feedback.info}>A carregar</span>
              <Loading style={{ margin: 0 }} />
            </div>
          ) : (
            [
              <div key="steps" style={{ width: '100%' }}>
                <Steps current={current}>
                  <Step
                    key="idle"
                    title={<span>Upload</span>}
                    icon={this.getStepIcon(states.idle)}
                  />
                  <Step
                    key="consistency"
                    title={<span>Verificação</span>}
                    icon={this.getStepIcon(states.consistency)}
                  />
                  <Step
                    key="send"
                    title={<span>Envio</span>}
                    icon={this.getStepIcon(states.sending)}
                  />
                </Steps>
              </div>,
              <div
                key="content"
                style={{
                  ...styles.steps.content,
                  ...g.layout.flexVertical,
                  ...g.layout.flexCenter,
                  ...g.layout.alignCenter,
                  flexGrow: 1,
                  padding: g.global.baseline
                }}
              >
                {this.getContent(this.state.current)}
              </div>
            ]
          )}
        </Content>
      </>
    );
  }
}

export default IvaReport;
