import React, { useEffect, useState, useCallback } from 'react';
import { InputNumber, Tag } from 'antd4';
import isNil from 'lodash.isnil';
import op from 'object-path';

import './Fields.scss';

const NumberField = ({
  data,
  updateField,
  width,
  label,
  path,
  disabled,
  max,
  min
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);
  const [isDirty, setDirty] = useState(false);

  useEffect(
    value => {
      const updateValue = op.get(data, path);
      setValue(updateValue);
    },
    [data, path]
  );

  const checkBoundaries = value => {
    let clone = value;
    if (!isNil(max)) {
      if (clone > max) {
        console.log('onsave setting max');
        clone = max;
      }
    }
    if (!isNil(min)) {
      if (clone < min) {
        console.log('onsave setting min');
        clone = min;
      }
    }

    return clone;
  };

  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async event => {
      const value = checkBoundaries(event?.target?.value);

      if (isDirty) {
        const response = await updateField(path, value);
        if (response?.ok) {
          // console.log('response ok, setting dirty to FALSE');
          setDirty(false);
          setStatusClass('save-success');

          setTimeout(() => {
            setStatusClass('');
          }, 3000);
        } else {
          setStatusClass('save-error');
        }
      } else {
        // console.log('not dirty');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, isDirty, updateField]
  );

  return (
    <div
      key={path}
      className={`input-container d-flex align-items-center w-${width ?? '25'}`}
    >
      <div className="input-tag-container">
        <Tag className="input-prefix border-radius">{label}</Tag>
      </div>
      <InputNumber
        max={max}
        min={min}
        className={'input input-number ' + statusClass}
        disabled={disabled}
        value={value}
        onBlur={onSave}
        onPressEnter={onSave}
        onChange={value => {
          //console.log('SETTING dirty and value');
          setDirty(true);
          value = checkBoundaries(value);
          setValue(value);
        }}
      />
    </div>
  );
};

export default NumberField;
