import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';

const BE_ADDR = address;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export async function fetchUserDataIfAuthenticated() {
  console.log('NETWORK FETCH');
  const userInfo = await authenticate();
  console.log('NETWORK FETCH after authenticate');
  if (!userInfo) {
    return null;
  }

  console.log('NETWORK FETCH before fetch');

  try {
    console.log({
      address: BE_ADDR + 'users/' + userInfo.attributes.sub,
      jwt: userInfo?.signInUserSession?.accessToken?.jwtToken
    });
    const response = await fetch(BE_ADDR + 'users/' + userInfo.attributes.sub, {
      method: 'GET',
      //body: data,
      headers: {
        Accept: 'application/json',
        jwt: userInfo?.signInUserSession?.accessToken?.jwtToken
      }
    });

    console.log('RESPONSE IN NETWORK', response);

    return await handleResponse(response);
  } catch (error) {
    console.error('NETWORK ERROR:');
    console.error(error);
  }
}
