import deburr from 'lodash/deburr';

import dataSchema from './data.schema.json';

const shouldRecurse = (key, object) => {
  const target = object?.[key];
  return (
    target &&
    typeof target == 'object' &&
    !(target.hasOwnProperty('type') && target.hasOwnProperty('default'))
  );
};

const shouldApply = (key, object) => {
  return !['minProperties', 'type', 'default'].includes(key);
};

const cloneObject = (object, fn) => {
  const result = {};
  for (const key in object) {
    if (shouldRecurse(key, object)) {
      result[key] = cloneObject(object[key], fn);
    } else if (shouldApply(key, object)) {
      const { key: newKey, value: newValue } = fn.apply(this, [
        key,
        object[key]
      ]);
      result[newKey] = newValue;
    } else {
      result[key] = object[key];
    }
  }
  return result;
};

let counter = 0;

export const processSchema = () => {
  const map = {};
  const schema = cloneObject(dataSchema.properties, (key, value) => {
    const newKey = deburr(
      key
        .replace(/[^a-zA-Z0-9_ ]/g, '')
        .replace(/ +/g, '_')
        .toLowerCase()
    );
    if (map[newKey]) {
      map['DUPLICATE' + counter++ + '_' + newKey] = { newKey, key };
      return {
        key: newKey,
        value
      };
    } else {
      map[newKey] = { newKey, key };
      return {
        key: newKey,
        value
      };
    }
  });
  return { schema, map };
};
