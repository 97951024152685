import React from 'react';
import { Button, Tooltip } from 'antd4';
import { ClearOutlined } from '@ant-design/icons';

import {
  Field,
  DateField,
  TextAreaField,
  TitleField,
  NumberField,
  SelectField,
  Hr,
  Placeholder
} from '../../../fields';
import '../../../fields/Fields.scss';

import {
  ArraySection,
  ArrayTitle,
  ActionAddArrayElement,
  ActionDeleteArrayElement,
  ArraySearch,
  ArrayFilters
} from '../array';
import FilterPaymentType from '../arrayFilters/FilterPaymentType';

const paymentTypeOptions = [
  { label: 'IMI', value: 'IMI' },
  { label: 'Repres. Fiscal', value: 'Repres. Fiscal' },
  { label: 'IRS', value: 'IRS' },
  { label: 'IS', value: 'IS' }
];

const dataPath = 'payments';
let dataCache;

const Payments = ({
  data,
  addArrayElement,
  deleteArrayElement,
  updateField
}) => {
  const [filteredData, setFilteredData] = React.useState(data);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);
  dataCache = data;

  React.useEffect(() => {
    // Reset filters and cache when new data comes in
    setFilteredData(data);
    dataCache = data;
  }, [data]);

  return (
    <ArraySection
      data={filteredData}
      dataPath={dataPath}
      deleteArrayElement={deleteArrayElement}
      updateField={updateField}
      renderToolbar={props => {
        return (
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-start align-items-center">
              <ArrayTitle title="Pagamentos"></ArrayTitle>
            </div>
            <div className="d-flex justify-content-end align-items-center fg-1">
              <ActionAddArrayElement
                onClick={() => addArrayElement(dataPath)}
              ></ActionAddArrayElement>
              <ArraySearch
                showClear={false}
                dataPath={dataPath}
                dataCache={dataCache}
                onSearch={data => {
                  setFilteredData(data);
                  setIsSearch(true);
                }}
                onClear={() => {
                  setFilteredData(dataCache);
                  setIsSearch(false);
                }}
              ></ArraySearch>
              <ArrayFilters
                showClear={false}
                dataPath={dataPath}
                dataCache={dataCache}
                onClear={() => {
                  setFilteredData(dataCache);
                  setIsFiltered(false);
                }}
                isFiltered={isFiltered}
                filters={[
                  <FilterPaymentType
                    options={paymentTypeOptions}
                    dataCache={dataCache}
                    dataPath={dataPath}
                    onFilter={data => {
                      setFilteredData(data);
                      setIsFiltered(true);
                    }}
                    onClear={() => {
                      setFilteredData(dataCache);
                      setIsFiltered(false);
                    }}
                  ></FilterPaymentType>
                ]}
              ></ArrayFilters>
              <Tooltip title={'Limpar Filtros e Pesquisa'}>
                <Button
                  className="add-relative mr-3"
                  size="small"
                  type={isFiltered || isSearch ? 'danger' : 'default'}
                  icon={<ClearOutlined></ClearOutlined>}
                  shape="circle"
                  onClick={() => {
                    setFilteredData(dataCache);
                    setIsFiltered(false);
                    setIsSearch(false);
                  }}
                ></Button>
              </Tooltip>
            </div>
          </div>
        );
      }}
    >
      <SinglePayment></SinglePayment>
    </ArraySection>
  );
};

export default Payments;

const SinglePayment = ({
  data,
  dataIndex,
  updateField,
  deleteArrayElement
}) => {
  const element = data?.[dataPath]?.[dataIndex] ?? {};
  return (
    <div
      key={'' + dataPath + dataIndex}
      className={`fields-container array-item array-item-${
        dataIndex % 2 === 0 ? 'even' : 'odd'
      } w-100 border-radius`}
    >
      <div className="array-index d-flex justify-content-start w-100">
        <span className="d-flex justify-content-center align-items-center border-radius-50 mr-3">
          {dataIndex}
        </span>
        <ActionDeleteArrayElement
          onClick={() => deleteArrayElement(dataPath, element?._id)}
        ></ActionDeleteArrayElement>
      </div>
      <TitleField title={'Fase 1'}></TitleField>
      <Hr width={100}></Hr>
      <NumberField
        key={'warned_value'}
        label={'Valor Avisado'}
        path={`payments.${dataIndex}.warned_value`}
        data={data}
        updateField={updateField}
      ></NumberField>
      <DateField
        showTime={false}
        key={'warned_date'}
        label={'Data Avisado'}
        path={`payments.${dataIndex}.warned_date`}
        data={data}
        updateField={updateField}
      ></DateField>
      <Placeholder width={50}></Placeholder>
      <TitleField title={'Fase 2'}></TitleField>
      <Hr width={100}></Hr>
      <Field
        key={'receiving_account'}
        label={'Conta Receb.'}
        path={`payments.${dataIndex}.receiving_account`}
        data={data}
        updateField={updateField}
      ></Field>
      <DateField
        showTime={false}
        key={'received_date'}
        label={'Data Recebimento'}
        width={33}
        path={`payments.${dataIndex}.received_date`}
        data={data}
        updateField={updateField}
      ></DateField>
      <Placeholder width={25}></Placeholder>
      <NumberField
        key={'received_value'}
        label={'Valor Recebimento'}
        path={`payments.${dataIndex}.received_value`}
        data={data}
        updateField={updateField}
      ></NumberField>
      <SelectField
        key={'payment_type'}
        multiple={true}
        width={50}
        label={'Tipo de Pagamento'}
        options={paymentTypeOptions}
        path={`payments.${dataIndex}.payment_type`}
        data={data}
        updateField={updateField}
      ></SelectField>
      <TextAreaField
        key={'observation'}
        width={100}
        label={'Observação'}
        minRows={2}
        path={`payments.${dataIndex}.observation`}
        data={data}
        updateField={updateField}
      ></TextAreaField>
      <SelectField
        key={'transfer_type'}
        width={50}
        label={'Tipo de Transferência'}
        options={[
          { label: 'App', value: 'App' },
          { label: 'Próprio Banco', value: 'Próprio Banco' },
          { label: 'Banco de Amigo', value: 'Banco de Amigo' },
          { label: 'Dinheiro', value: 'Dinheiro' }
        ]}
        path={`payments.${dataIndex}.transfer_type`}
        data={data}
        updateField={updateField}
      ></SelectField>
      <TitleField title={'Fase 3'}></TitleField>
      <Hr width={100}></Hr>
      <Field
        key={'payment_reference'}
        width={50}
        label={'Referência do Pagamento'}
        path={`payments.${dataIndex}.payment_reference`}
        data={data}
        updateField={updateField}
      ></Field>
      {/* <NumberField
        key={'finpartner_state_payment_value'}
        label={'Valor do Pagamento (Finp/Estado)'}
        width={33}
        path={`payments.${dataIndex}.finpartner_state_payment_value`}
        data={data}
        updateField={updateField}
      ></NumberField> */}
      <DateField
        showTime={false}
        key={'phase3_payment_by_responsible_date'}
        label={'Data de entrega p/ Responsável do Pagamento'}
        width={50}
        path={`payments.${dataIndex}.phase3_payment_by_responsible_date`}
        data={data}
        updateField={updateField}
      ></DateField>
      <SelectField
        key={'phase3_payment_type'}
        multiple={true}
        width={50}
        label={'Tipo de Pagamento'}
        options={[
          { label: 'IMI', value: 'IMI' },
          { label: 'IRS', value: 'IRS' },
          { label: 'IS', value: 'IS' }
        ]}
        path={`payments.${dataIndex}.phase3_payment_type`}
        data={data}
        updateField={updateField}
      ></SelectField>
      <TextAreaField
        key={'phase3_payment_observation'}
        width={100}
        label={'Observação'}
        minRows={2}
        path={`payments.${dataIndex}.phase3_payment_observation`}
        data={data}
        updateField={updateField}
      ></TextAreaField>
      <TitleField title={'Fase 4'}></TitleField>
      <Hr width={100}></Hr>
      <NumberField
        key={'finpartner_state_payment_value'}
        label={'Valor do Pagamento (Finp/Estado)'}
        width={33}
        path={`payments.${dataIndex}.finpartner_state_payment_value`}
        data={data}
        updateField={updateField}
      ></NumberField>
      <DateField
        showTime={false}
        key={'finpartner_state_payment_date'}
        label={'Data do Pagamento (Finp/Estado)'}
        width={50}
        path={`payments.${dataIndex}.finpartner_state_payment_date`}
        data={data}
        updateField={updateField}
      ></DateField>
      <TextAreaField
        key={'finpartner_state_payment_observation'}
        width={100}
        label={'Observação'}
        minRows={2}
        path={`payments.${dataIndex}.finpartner_state_payment_observation`}
        data={data}
        updateField={updateField}
      ></TextAreaField>
    </div>
  );
};
