import React from 'react';

import { Row, Col, Card, Form, Select, Input, Button, message } from 'antd';

import AppContext from '../../../context/AppContextBase';

import DetailedMessage from '../../../components/util/DetailedMessage';
import validator from 'validator';
import globalStyles from '../../../styles/global';

import { postGroup } from '../../../network/users';

const { Option } = Select;

const styles = {
  pageHeaderBox: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: globalStyles.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  attachments: {
    borderTop: '1px solid #e8e8e8',
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    alignItems: 'flex-start',
    marginTop: globalStyles.global.baseline * 2,
    width: '100%'
  },
  card: {
    body: {
      width: '100%',
      paddingBottom: globalStyles.global.baseline
    }
  },
  type: {
    fontSize: globalStyles.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    backgroundColor: '#e8e8e8',
    marginRight: globalStyles.global.baseline,
    paddingLeft: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline * 0.5,
    paddingBottom: globalStyles.global.baseline * 0.5
  },
  form: {
    item: {
      marginBottom: 0,
      paddingRight: globalStyles.global.baseline,
      width: '100%'
    },
    actions: {
      width: '100%',
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexBetween
    },
    body: {
      margin: '2%',
      width: '96%'
    }
  }
};

class CreateGroup extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { departments: [], loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.state = { loading: false };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    // Current user dept:
    //const dept = this.context.getUserAttribute('department');

    // if (dept !== '*') {
    //   this.setState({ departments: [dept] });
    // } else {
    //   this.setState({ departments: ['*', 'CM', 'FIN', 'ID'] });
    // }

    this.setState({ departments: ['ID'] });

    this.setState({ loading: false });
  }

  resetFields() {
    this.props.form.resetFields(['name', 'email', 'department', 'notes']);
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });

        const response = await postGroup(values);
        if (response?.result === 'OK') {
          message.success('User Created Successfully');
          this.resetFields();
        } else {
          DetailedMessage.error(
            'Error saving data - ' + response.message,
            response
          );
        }
        this.setState({ loading: false });
      } else {
        message.error('Required values missing: ' + err);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form style={styles.form.body} onSubmit={this.handleSubmit}>
        <Card
          bodyStyle={styles.card.body}
          headStyle={{}}
          title={'New User Group'}
          bordered={true}
        >
          <Row style={styles.card.body}>
            <Col span={8}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Input
                    disabled={this.state.loading}
                    placeholder="Group Name"
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={8} offset={0}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    },
                    {
                      validator: async (rule, value, callback) => {
                        if (value && !validator.isEmail(value)) {
                          callback('Invalid Email');
                        } else {
                          callback();
                        }
                      }
                    }
                  ]
                })(<Input disabled={this.state.loading} placeholder="Email" />)}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('department', {
                  innitialValue: 'ID',
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Select
                    disabled={this.state.loading || !this.state.departments}
                    placeholder="Department"
                  >
                    {this.state.departments
                      ? this.state.departments.map(option => (
                          <Option key={option}>{option}</Option>
                        ))
                      : null}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator(
                  'notes',
                  {}
                )(
                  <Input.TextArea
                    autosize={{ minRows: 2, maxRows: 8 }}
                    disabled={this.state.loading}
                    placeholder="Notes"
                  />
                )}
              </Form.Item>
            </Col>

            <Col style={styles.form.actions}>
              <Button
                style={{
                  marginBottom: globalStyles.global.baseline,
                  marginTop: globalStyles.global.baseline,
                  marginRight: globalStyles.global.baseline
                }}
                disabled={this.state.loading}
                type="secondary"
                onClick={() => this.resetFields()}
              >
                Clear
              </Button>
              <Button
                style={{
                  marginBottom: globalStyles.global.baseline,
                  marginTop: globalStyles.global.baseline
                }}
                disabled={this.state.loading}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const crmForm = Form.create({ name: 'create_group' })(CreateGroup);

export default crmForm;
