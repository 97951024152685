import { cleanIncomeCode, parseCurrency } from './transforms';

const dataKeys = {
  '301': {
    lineSkip: 1,
    columns: [{ excelKey: 'Sujeito Passivo A', dataKey: 'Sujeito Passivo A' }]
  },
  '302': {
    lineSkip: 1,
    columns: [{ excelKey: 'Sujeito Passivo B', dataKey: 'Sujeito Passivo B' }]
  },
  '3A3': {
    lineSkip: 1,
    columns: [{ excelKey: 'Titular', dataKey: 'Titular' }]
  },
  '4A': {
    lineSkip: 3,
    columns: [
      {
        excelKey: 'Código rendimento',
        dataKey: 'Código rendimento',
        transform: value => cleanIncomeCode(value, '4A')
      },
      { excelKey: 'País da fonte', dataKey: 'País da fonte' },
      {
        excelKey: 'Rendimento bruto',
        dataKey: 'Rendimento bruto',
        transform: parseCurrency
      },
      {
        excelKey: 'Contribuições regimes proteção social',
        dataKey: 'Contribuições regimes proteção social',
        transform: parseCurrency
      },

      { excelKey: 'NIF', dataKey: 'NIF' },
      {
        excelKey: 'Imposto Pago Estrangeiro',
        dataKey: 'Imposto Pago Estrangeiro',
        transform: parseCurrency
      },
      {
        excelKey: 'Retenção na fonte',
        dataKey: 'Retenção na fonte',
        transform: parseCurrency
      },
      {
        excelKey: 'Retenção da sobretaxa',
        dataKey: 'Retenção da sobretaxa',
        transform: parseCurrency
      }
    ]
  },
  '5A': {
    lineSkip: 2,
    columns: [
      {
        excelKey: 'Código rendimento',
        dataKey: 'Código rendimento',
        transform: value => cleanIncomeCode(value, '5A')
      },
      { excelKey: 'País da fonte', dataKey: 'País da fonte' },
      {
        excelKey: 'Rendimento bruto',
        dataKey: 'Rendimento bruto',
        transform: parseCurrency
      },
      {
        excelKey: 'Contribuições regimes proteção social',
        dataKey: 'Contribuições regimes proteção social',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto pago no estrangeiro',
        dataKey: 'Imposto pago no estrangeiro',
        transform: parseCurrency
      }
    ]
  },
  '6A': {
    lineSkip: 3,
    columns: [
      {
        excelKey: 'Código Rendimento',
        dataKey: 'Código Rendimento',
        transform: value => cleanIncomeCode(value, '6A')
      },
      { excelKey: 'País da  fonte', dataKey: 'País da fonte' },
      {
        excelKey: 'Estabelecimento estável ou instalação fixa (S/N)',
        dataKey: 'Estabelecimento estável'
      },
      {
        excelKey: 'Rendimento',
        dataKey: 'Rendimento',
        transform: parseCurrency
      },
      {
        excelKey: 'Contibuições regimes proteção social',
        dataKey: 'Contibuições regimes proteção social',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto pago no estrangeiro',
        dataKey: 'Imposto pago no estrangeiro',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF da entidade retentora',
        dataKey: 'NIF da entidade retentora'
      },
      {
        excelKey: 'Retenção na fonte',
        dataKey: 'Retenção na fonte',
        transform: parseCurrency
      }
    ]
  },
  '7A': {
    lineSkip: 2,
    columns: [
      {
        excelKey: 'Código rendimento',
        dataKey: 'Código rendimento',
        transform: value => cleanIncomeCode(value, '7A')
      },
      { excelKey: 'País da fonte', dataKey: 'País da fonte' },
      {
        excelKey: 'Rendimento Líquido',
        dataKey: 'Rendimento Líquido',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto pago no estrangeiro',
        dataKey: 'Imposto pago no estrangeiro',
        transform: parseCurrency
      }
    ]
  },
  '8A': {
    lineSkip: 3,
    columns: [
      {
        excelKey: 'Código rendim',
        dataKey: 'Código rendim',
        transform: value => cleanIncomeCode(value, '8A')
      },

      { excelKey: 'País da fonte', dataKey: 'País da Fonte' },
      {
        excelKey: 'Rendimento Bruto',
        dataKey: 'Rendimento Bruto',
        transform: parseCurrency
      },
      {
        excelKey: 'No país da Fonte',
        dataKey: 'No país da Fonte',
        transform: parseCurrency
      },
      { excelKey: 'Cód. País', dataKey: 'Cód. País' },
      {
        excelKey: 'Imposto retido',
        dataKey: 'Imposto retido',
        transform: parseCurrency
      },
      {
        excelKey: 'NIF da entidade retentora',
        dataKey: 'NIF da entidade retentora'
      },
      {
        excelKey: 'Retenção na fonte',
        dataKey: 'Retenção na fonte',
        transform: parseCurrency
      }
    ]
  },
  '91A': {
    lineSkip: 5,
    columns: [
      { excelKey: 'País da Fonte', dataKey: 'País da Fonte' },
      //  { excelKey: 'Código', dataKey: 'Código' },

      { excelKey: 'Ano', dataKey: 'Realização Ano' },
      { excelKey: 'Mês', dataKey: 'Realização Mês' },
      {
        excelKey: 'Valor',
        dataKey: 'Realização Valor',
        transform: parseCurrency
      },
      { excelKey: 'Ano', dataKey: 'Aquisição Ano' },
      { excelKey: 'Mês', dataKey: 'Aquisição Mês' },
      {
        excelKey: 'Valor',
        dataKey: 'Aquisição Valor',
        transform: parseCurrency
      },
      {
        excelKey: 'Encargos',
        dataKey: 'Despesas e encargos',
        transform: parseCurrency
      },

      {
        excelKey: 'Imposto pago no estrangeiro',
        dataKey: 'Imposto estrangeiro',
        transform: parseCurrency
      }
    ]
  },
  '91B': {
    lineSkip: 2,
    columns: [
      {
        excelKey: 'Código rendimento',
        dataKey: 'Código rendimento',
        transform: value => cleanIncomeCode(value, '91B')
      },
      { excelKey: 'País da fonte', dataKey: 'País da fonte' },
      {
        excelKey: 'Rendimento bruto',
        dataKey: 'Rendimento bruto',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto pago no estrangeiro',
        dataKey: 'Imposto pago no estrangeiro',
        transform: parseCurrency
      }
    ]
  },
  '92A': {
    lineSkip: 3,
    columns: [
      { excelKey: 'País da Fonte', dataKey: 'País da Fonte' },
      { excelKey: 'Código', dataKey: 'Código' },
      { excelKey: 'Ano', dataKey: 'Realização Ano' },
      { excelKey: 'Mês', dataKey: 'Realização Mês' },
      { excelKey: 'Dia', dataKey: 'Realização Dia' },
      {
        excelKey: 'Valor',
        dataKey: 'Realização Valor',
        transform: parseCurrency
      },
      { excelKey: 'Ano', dataKey: 'Aquisição Ano' },
      { excelKey: 'Mês', dataKey: 'Aquisição Mês' },
      { excelKey: 'Dia', dataKey: 'Aquisição Dia' },
      {
        excelKey: 'Valor',
        dataKey: 'Aquisição Valor',
        transform: parseCurrency
      },
      {
        excelKey: 'Despesas e Encargos',
        dataKey: 'Despesas e Encargos',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto pago no estrangeiro',
        dataKey: 'Imposto estrangeiro',
        transform: parseCurrency
      },
      {
        excelKey: 'País da contraparte',
        dataKey: 'País da contraparte'
      }
    ]
  },
  '92B': {
    lineSkip: 2,
    columns: [
      {
        excelKey: 'Código rendimento',
        dataKey: 'Código rendimento',
        transform: value => cleanIncomeCode(value, '92B')
      },
      { excelKey: 'País da fonte', dataKey: 'País da fonte' },
      {
        excelKey: 'Rendimento liquido',
        dataKey: 'Rendimento liquido',
        transform: parseCurrency
      },
      {
        excelKey: 'Imposto pago no estrangeiro',
        dataKey: 'Imposto pago no estrangeiro',
        transform: parseCurrency
      },
      {
        excelKey: 'País da contraparte',
        dataKey: 'País da contraparte'
      }
    ]
  },
  '111': {
    lineSkip: 2,
    columns: [
      { excelKey: 'IBAN', dataKey: 'IBAN' },
      { excelKey: 'BIC', dataKey: 'BIC' }
    ]
  },
  '112': {
    lineSkip: 1,
    columns: [
      {
        excelKey: 'Outros IDs',
        dataKey: 'Outros IDs'
      }
    ]
  }
};

const categoryKeysJ = {
  // These may overlap with other annexes, careful when adding keys
  '301': '301',
  '302': '302',
  '3A3': '3A3',
  '4A': '4A',
  '5A': '5A',
  '6A': '6A',
  '7A': '7A',
  '8A': '8A',
  '91A': '91A',
  '91B': '91B',
  '92A': '92A',
  '92B': '92B',
  '111': '111',
  '112': '112'
};

const annexSheetJ = { 'anexo j': { dataKey: 'j' } };

export default dataKeys;
export { categoryKeysJ, annexSheetJ };
