import React, { useCallback, useEffect, useState } from 'react';
import { Tag, Select } from 'antd4';
import op from 'object-path';

import './Fields.scss';
import isNil from 'lodash.isnil';

const { Option } = Select;

const SelectField = ({
  path,
  multiple,
  width,
  label,
  options,
  data,
  updateField
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);

  const isMultiple = multiple;

  useEffect(
    value => {
      const updateValue = op.get(data, path);
      setValue(updateValue);
    },
    [data, path]
  );
  const [statusClass, setStatusClass] = useState('');
  const onSave = useCallback(
    async value => {
      const response = await updateField(path, value);
      if (response?.ok) {
        //console.log('response ok, setting save-success');
        setStatusClass('save-success');

        setTimeout(() => {
          setStatusClass('');
        }, 3000);
      } else {
        setStatusClass('save-error');
      }
    },
    [path, updateField]
  );

  return (
    <div
      key={path}
      className={`input-container d-flex align-items-center w-${width ?? '25'}`}
    >
      <Tag className="input-prefix border-radius">{label}</Tag>
      <Select
        className={`input input-select ${statusClass}`}
        showSearch={true}
        mode="tags"
        multiple={multiple}
        placeholder=""
        value={
          isMultiple ? maybeEmptyArray(value) : !isNil(value) ? [value] : []
        }
        filterOption={(input, option) => {
          console.log({ input, option });
          return (
            option?.value?.toLowerCase?.()?.indexOf?.(input?.toLowerCase?.()) >=
            0
          );
        }}
        onSelect={(newValue, option) => {
          if (isMultiple) {
            const newState = maybeEmptyArray(value);
            if (!newState.includes(newValue)) {
              newState.push(newValue);
            }
            setValue(newState);
            return onSave(newState);
          }

          setValue(newValue);
          onSave(newValue);
        }}
        onDeselect={newValue => {
          if (isMultiple) {
            const newState = value?.filter(x => x !== newValue) ?? [];
            setValue(newState);
            onSave(newState);
          } else {
            setValue([]);
            onSave('');
          }
        }}
        onClear={() => {
          setValue([]);
          onSave([]);
        }}
      >
        {options.map(opt => (
          <Option key={opt.value} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const maybeEmptyArray = value => {
  return Array.isArray(value) ? value : [];
};

export default SelectField;
