import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Table, Modal, message, notification, Icon } from 'antd';
import autobind from 'auto-bind/react';

import AppContext from '../../context/AppContextBase';
import globalStyles from '../../styles/global';

import getColumns from './clientColumns';
import { updateClient, removeClient, getSecrets } from '../../network/clients';
import ConfirmLogin from '../form/ConfirmLogin';
import EditableField from '../util/EditableField';
import DetailedMessage from '../util/DetailedMessage';

const { confirm } = Modal;

const styles = {
  pageHeaderBox: {
    fontSize: globalStyles.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: globalStyles.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 1.5,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  table: {
    width: '100%',
    fontSize: globalStyles.global.baseline * 1.2,
    fontWeight: 100,
    border: '1px solid #e8e8e8'
  },
  modal: {
    body: {
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: globalStyles.global.baseline,
      paddingTop: globalStyles.global.baseline,
      paddingBottom: globalStyles.global.baseline * 2,
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      textAlign: 'left',
      fontSize: globalStyles.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: globalStyles.global.baseline
    },
    message: {
      marginBottom: globalStyles.global.baseline
    },
    error: {
      marginBottom: globalStyles.global.baseline,
      color: globalStyles.colors.feedback.error
    }
  }
};

const scopes = {
  DEFAULT: 'default',
  CONSISTENCY: 'consistency',
  REPORT: 'REPORT'
};

export { scopes };

const clean = value => value.replace(/ +/g, ' ');

export default class ClientTable extends React.Component {
  static contextType = AppContext;

  getColumns() {
    return getColumns({
      scope: this.props.scope,
      handleChange: this.handleChange,
      edit: this.edit,
      save: this.save,
      cancel: this.cancel,
      remove: this.confirmRemove,
      reveal: this.confirmReveal,
      consistencyWarning: this.consistencyWarning,
      isWarned: this.state.warned,
      setWarned: this.setWarned,
      handleReset: this.handleReset,
      checkPermission: this.context.checkPermission
    });
  }

  constructor(props, context) {
    super(props, context);

    autobind(this);

    this.state = {
      data: props.clients,
      warned: false,
      revealNif: null,
      revealData: null
    };
    // options = { scope, state, handleChange, edit, save, cancel, consistencyWarning, setWarned }

    this.state.columns = this.getColumns();

    this.cacheData = JSON.parse(JSON.stringify(props.clients));
    this.state.cacheInit = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.clients !== prevState.data) {
      this.setState({ data: this.props.clients });
      if (!this.state.cacheInit) {
        this.cacheData = JSON.parse(JSON.stringify(this.props.clients));
        if (this.cacheData && this.cacheData.length) {
          this.setState({ cacheInit: true });
        }
      }
    }
  }

  setWarned() {
    this.setState({ warned: true });
  }

  consistencyWarning() {
    if (!this.state.warned) {
      notification.open({
        duration: 6,
        message: 'Informação Importante',
        description: (
          <span>
            <span>
              As alterações feitas durante processo de envio são temporárias e{' '}
              <u>são guardadas apenas durante o mesmo</u>, desaparecendo no
              final do processo.
            </span>
            <br />
            <br />
            <span>
              Para efectuar alterações permanentes utilize a lista de clientes
            </span>
          </span>
        ),
        icon: (
          <Icon
            type="warning"
            style={{
              color: globalStyles.colors.background.pink
            }}
          />
        )
      });
      this.setState({ warned: true });
    }
  }

  handleChange(value, key, column, isRadio = false) {
    const newData = this.state.data;
    const target = newData.filter(item => key === item.nif)[0];
    if (target) {
      target[column] = value;
      this.setState({ data: newData });
    }
  }

  edit(key) {
    const newData = [...this.state.data];
    const target = newData.filter(item => key === item.nif)[0];
    if (target) {
      let old = this.cacheData.filter(item => key === item.nif)[0];

      if (!old) {
        this.cacheData.push(JSON.parse(JSON.stringify(target)));
      } else {
        Object.assign(old, JSON.parse(JSON.stringify(target)));
      }

      target.editable = true;
      this.setState({ data: newData });
    }
  }
  async save(key) {
    this.setState(async state => {
      const newData = [...state.data];
      const target = newData.filter(item => key === item.nif)[0];
      if (target) {
        for (let field in target) {
          if (!(typeof target[field] === 'string')) {
            continue;
          }
          target[field] = clean(target[field]);
        }
        target.saving = true;
        let response;
        target.editable = false;

        // validate emails:

        const cleanTarget = JSON.parse(JSON.stringify(target));
        delete cleanTarget.createdAt;
        delete cleanTarget.updatedAt;
        delete cleanTarget.client_state;
        delete cleanTarget.edited;
        delete cleanTarget.has_errors;
        delete cleanTarget.saving;
        delete cleanTarget.warning;

        response = await updateClient(target.nif, target, this.props.scope);

        if (response?.result === 'OK') {
          target.edited = true;
          this.cacheData = JSON.parse(JSON.stringify(newData));
          message.success('Alterações Guardadas', 3);
          if (this.props.onEdit) {
            this.props.onEdit();
          }
          target.saving = false;
          return { data: newData };
        } else {
          message.error(
            'Ocorreu um erro, as alterações não foram guardadas! Verifique a sua ligação à internet e/ou permissões de acesso.',
            8
          );
          target.saving = false;
          return {};
        }
      }
    });
  }
  cancel(key) {
    this.setState(state => {
      const newData = JSON.parse(JSON.stringify(state.data));
      let target = newData.filter(item => key === item.nif)[0];
      if (target) {
        const old = this.cacheData.filter(item => key === item.nif)[0];
        if (old) {
          Object.assign(target, old);

          target.info_to_send = old.info_to_send;
          target.send_method = old.send_method;

          target.editable = false;
          delete target.edited;
          delete target.saving;
          return { data: newData };
        }
      }
    });
  }

  async confirmRemove(key) {
    const self = this;
    confirm({
      okButtonProps: { ghost: true },
      title: (
        <span style={{ fontSize: globalStyles.global.baseline * 2 }}>
          Apagar Cliente
        </span>
      ),
      icon: (
        <Icon
          type="warning"
          style={{
            fontSize: globalStyles.global.baseline * 2.5,
            color: globalStyles.colors.feedback.error
          }}
        />
      ),
      content: (
        <div style={styles.modal.body}>
          <span style={styles.modal.message}>
            Tem certeza de que pretende apagar este cliente?
          </span>
          <ul style={styles.modal.list}>
            <li style={{ ...styles.modal.error, fontWeight: 700 }}>
              Esta operação é irreversível.
            </li>
          </ul>
        </div>
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        self.remove(key);
      }
    });
  }

  async confirmReveal(key) {
    this.setState({ revealNif: key });
  }

  async getRevealData(nif) {
    const response = await getSecrets(nif);

    if (response?.result === 'OK') {
      this.setState({ revealData: response.data.at });
    } else {
      DetailedMessage.error(
        'Ocorreu um erro, o pedido não foi efectuado! Verifique a sua ligação à internet e/ou permissões de acesso.',
        response
      );
      throw new Error(response);
    }
  }

  async saveClientData(nif, data) {
    const response = await updateClient(nif, data, this.props.scope);

    if (response?.result === 'OK') {
      message.success('Alterações Guardadas', 3);
    } else {
      DetailedMessage.error('Erro.', response);
    }
  }

  async remove(key) {
    const response = await removeClient(key);

    if (response?.result === 'OK') {
      let newData = [...this.state.data];
      newData = newData.filter(client => client.nif !== key);

      this.setState({ data: newData });
      this.cacheData = JSON.parse(JSON.stringify(newData));
      message.success('Alterações Guardadas', 3);
    } else {
      DetailedMessage.error(
        'Ocorreu um erro, as alterações não foram guardadas! Verifique a sua ligação à internet e/ou permissões de acesso.',
        response
      );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          {/* antd overrides - I should get shot for this */}
          <style type="text/css">{`.ant-table-thead > tr > th {
              padding: ${globalStyles.global.baseline}px;
            }
            tr.ant-table-row td {
              padding: ${globalStyles.global.baseline}px;
              line-height: 1;
            }
            .ant-table-tbody > tr.error {
              background-color: ${globalStyles.colors.foreground.error}
            }

            .ant-table-tbody > tr.edited {
              background-color: ${globalStyles.colors.foreground.edited}
            }
            .ant-table-tbody > tr.warning {
              background-color: ${globalStyles.colors.foreground.warning}
            }
            .ant-table-tbody > tr.success {
              background-color: ${globalStyles.colors.foreground.success}
            }
          `}</style>
        </Helmet>
        <Table
          loading={!this.state.data}
          style={styles.table}
          rowKey={record => record.nif}
          columns={this.state.columns}
          dataSource={this.state.data}
          rowClassName={(record, index) => {
            if (record.edited) {
              return 'edited';
            } else if (record.has_errors) {
              return 'error';
            } else if (record.warning) {
              return 'warning';
            } else if (record.success) {
              return 'success';
            }
            return '';
          }}
          pagination={{
            position: 'both',
            showSizeChanger: true,
            total: this.state.data.length || 0,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
            defaultPageSize: 100,
            onShowSizeChange: (current, size) => {
              //TODO: save page size change on user settings
            }
          }}
        />

        <Modal
          width="80vw"
          bodyStyle={styles.modal.body}
          visible={!!this.state.revealNif}
          closeable
          onCancel={() => {
            this.setState({ revealData: null, revealNif: null });
          }}
          footer={null}
        >
          {this.state.revealData ? (
            <Row
              style={{
                width: '100%',
                padding: globalStyles.global.baseline * 2
              }}
            >
              <EditableField
                span={12}
                editable
                title="Username"
                value={this.state.revealData.username}
                onSave={value =>
                  this.saveClientData(this.state.revealNif, {
                    at: {
                      username: value
                    }
                  })
                }
                onSaveSuccess={() => {}}
                onSaveError={err => {}}
              />
              <EditableField
                span={12}
                editable
                title="Password"
                value={this.state.revealData.password}
                onSave={value =>
                  this.saveClientData(this.state.revealNif, {
                    at: {
                      password: value
                    }
                  })
                }
                onSaveSuccess={() => {}}
                onSaveError={err => {}}
              />
            </Row>
          ) : (
            <ConfirmLogin
              username={this.context.state.userData.email}
              success={async user =>
                await this.getRevealData(this.state.revealNif)
              }
              error={async e => {
                DetailedMessage.error(
                  'Erro na autenticação ' +
                    (e?.message ? `: ${e.message}` : ''),
                  e
                );
                return;
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}

ClientTable.contextType = AppContext;
