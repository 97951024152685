import React from 'react';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';

import AppContext from '../../../context/AppContextBase';

import { consistencyColumns, reportColumns } from './columns';

import g from '../../../styles/global';

const statuses = { ok: 'ok', fail: 'fail', notSent: 'not-sent' };

const styles = {
  pageHeaderBox: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: g.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  table: {
    width: '100%',
    fontSize: g.global.baseline * 1.2,
    fontWeight: 100,
    border: '1px solid #e8e8e8'
  },
  modal: {
    body: {
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  }
};

const scopes = {
  CONSISTENCY: 'consistency',
  REPORT: 'report'
};

export { scopes };

export default class ReportTable extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: props.clients
    };
    // options = { scope, state, handleChange, edit, save, cancel, consistencyWarning, setWarned }
  }

  render() {
    let columns;
    switch (this.props.scope) {
      case scopes.CONSISTENCY:
        columns = consistencyColumns;
        break;
      case scopes.REPORT:
        columns = reportColumns;
        break;
      default:
        throw new Error('Invalid ConsistencyTable State');
    }
    return (
      <>
        <Helmet>
          {/* antd overrides - I should get shot for this */}
          <style type="text/css">{`.ant-table-thead > tr > th {
              padding: ${g.global.baseline}px;
            }
            tr.ant-table-row td {
              padding: ${g.global.baseline}px;
              line-height: 1;
            }
            .ant-table-tbody > tr.error {
              background-color: ${g.colors.foreground.error}
            }
            .ant-table-tbody > tr.warning {
              background-color: ${g.colors.foreground.warning}
            }
            .ant-table-tbody > tr.not-send {
              background-color: ${g.colors.foreground.edited}
            }
            .ant-table-tbody > tr.success {
              background-color: ${g.colors.foreground.success}
            }
          `}</style>
        </Helmet>
        <Table
          loading={!this.state.data.length}
          style={styles.table}
          rowKey={record => record.nif}
          columns={columns}
          dataSource={this.state.data}
          rowClassName={(record, index) => {
            if (
              record?.consistency?.status === statuses.fail ||
              record?.report?.status === statuses.fail
            ) {
              return 'error';
            } else if (
              record?.consistency?.warningList?.length ||
              record?.report?.warningList?.length
            ) {
              return 'warning';
            } else if (record?.report?.status === statuses.notSent) {
              return 'not-sent';
            } else if (record.report?.status === statuses.ok) {
              return 'success';
            }
            return '';
          }}
          pagination={{
            position: 'both',
            showSizeChanger: true,
            total: this.state.data.length || 0,
            defaultPageSize: 250,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSizeOptions: [
              '10',
              '20',
              '30',
              '40',
              '50',
              '100',
              '250',
              '500'
            ],
            onShowSizeChange: (current, size) => {
              //TODO: save page size change on user settings
            }
          }}
        />
      </>
    );
  }
}

ReportTable.contextType = AppContext;
