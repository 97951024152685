import xlsx from 'xlsx';
import moment from 'moment';

export const getWorkbook = async uint8Array => {
  return await xlsx.read(uint8Array, {
    type: 'array',
    cellStyles: true,
    blankCell: false
  });
};

export const getSheet = (workbook, sheetNameOrIndex) => {
  return workbook.Sheets[sheetNameOrIndex];
};

export const getSheetRowJson = (sheet, rowIndex) => {
  let rows = xlsx.utils.sheet_to_json(sheet, {
    raw: false,
    header: undefined,
    defval: '',
    blankrows: false
  });

  const result = rows?.[rowIndex];

  if (!result) {
    throw new Error(`XLS getRow: row with index ${rowIndex} not found`);
  }

  return result;
};

export const getSheetJson = (
  sheet,
  options = { raw: false, blankrows: false, defval: null, header: 'A', rawNumbers: true }
) => {
  const sheetJson = xlsx.utils.sheet_to_json(sheet, options);
  return sheetJson;
};

export const tryParseDate = string => {
  const formats = [
    // Dumb mode requested by client

    'M/D/YYYY',
    'M/DD/YYYY',
    'MM/D/YYYY',
    'MM/DD/YYYY',
    'M/D/YY',
    'M/DD/YY',
    'MM/D/YY',
    'MM/DD/YY',
    'M-D-YYYY',
    'MM-D-YYYY',
    'M-DD-YYYY',
    'MM-DD-YYYY',
    'M-D-YY',
    'M-DD-YY',
    'MM-D-YY',
    'MM-DD-YY',
    'YYYY',
    'YYYY-MM-DD',
    'YYYY-MM-D',
    'YYYY-M-DD',
    'YYYY-M-D',
    'YYYY-MM-DD',
    'YYYY-MM-D',
    'YYYY-M-DD',
    'YYYY-M-D'
  ];

  let result;
  //eslint-disable-next-line
  for (const format of formats) {
    result = moment(string, format);
    if (result.isValid()) {
      return result;
    }
  }
  throw new Error(`Invalid Date format: "${string}"`);
};

// eslint-disable-next-line no-unused-vars
export const printXlsSheets = workbook => {
  console.log('\n\n' + Object.keys(workbook) + '\n\n');
  console.log('--- Workbook.Sheets ---');
  console.log(workbook.Workbook.Sheets);
};

// let count = 0;
// const counter = () => (count += 1);

// NOTES

// Read

// XLSX.read(data, read_opts) attempts to parse data.
// XLSX.readFile(filename, read_opts) attempts to read filename and parse.

// Write

// XLSX.write(wb, write_opts) attempts to write the workbook wb
// XLSX.writeFile(wb, filename, write_opts) attempts to write wb to filename. In browser-based environments, it will attempt to force a client-side download.
// XLSX.writeFileAsync(filename, wb, o, cb) attempts to write wb to filename. If o is omitted, the writer will use the third argument as the callback.
// XLSX.stream contains a set of streaming write functions.

// Workbooks

// var first_sheet_name = workbook.SheetNames[0];
// var address_of_cell = 'A1';
// /* Get worksheet */
// var worksheet = workbook.Sheets[first_sheet_name];
// /* Find desired cell */
// var desired_cell = worksheet[address_of_cell];
// /* Get the value */
// var desired_value = desired_cell ? desired_cell.v : undefined;

// Utils

// Importing:

// aoa_to_sheet converts an array of arrays of JS data to a worksheet.
// json_to_sheet converts an array of JS objects to a worksheet.
// table_to_sheet converts a DOM TABLE element to a worksheet.
// sheet_add_aoa adds an array of arrays of JS data to an existing worksheet.
// sheet_add_json adds an array of JS objects to an existing worksheet.

// Exporting:

// sheet_to_json converts a worksheet object to an array of JSON objects.
// sheet_to_csv generates delimiter-separated-values output.
// sheet_to_txt generates UTF16 formatted text.
// sheet_to_html generates HTML output.
// sheet_to_formulae generates a list of the formulae (with value fallbacks).

// Cell and cell address manipulation:

// format_cell generates the text value for a cell (using number formats).
// encode_row / decode_row converts between 0-indexed rows and 1-indexed rows.
// encode_col / decode_col converts between 0-indexed columns and column names.
// encode_cell / decode_cell converts cell addresses.
// encode_range / decode_range converts cell ranges.
