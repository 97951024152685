import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Button, Upload, Spin } from 'antd4';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';

import { ArraySearch } from '../array';

import routes from '../../../network/immigration';
import AppToaster from '../../../components/AppToaster';

import '../../../fields/Fields.scss';

const { uploadFile, getFileLink, removeFile } = routes;

let dataCache;

let dataPath = 'uploads';

const Uploads = ({ label, data, addArrayElement, deleteArrayElement }) => {
  const [value, setValue] = useState(data); // value is entire uploads array
  dataCache = data;
  const [isLoading, setLoading] = useState(0);

  useEffect(
    value => {
      setValue(data);
      dataCache = data;
    },
    [data]
  );
  const [statusClass, setStatusClass] = useState('');

  const onSave = useCallback(
    async file => {
      // file.safeFilename = getSafeFilename(file.name); //name override
      // console.log('Uploading: ', file);
      setLoading(oldLoading => oldLoading + 1);
      // Upload to S3
      const s3Result = await uploadFile(file);

      if (!s3Result.ok) {
        // Could not upload. Bail
        setStatusClass('save-error');
        setLoading(oldLoading => oldLoading - 1);
        return;
      }
      const s3key = s3Result?.data?.key;
      const toSave = {
        s3key,
        originalName: file.name, //file.name,
        fileType: ''
      };

      // Save to backend
      // const backendResponse = await updateField(path, toSave);
      const backendResponse = await addArrayElement(dataPath, toSave);

      if (!backendResponse?.ok) {
        try {
          // could not save in backend, cancel upload (remove file)
          await removeFile(s3key);
        } catch (err) {
          // ignore? we can't do anything else here if deleting fails
          // we would need a "cleaner" to clean dangling files regularly
          console.error(err);
        }
        setStatusClass('save-error');
        setLoading(oldLoading => oldLoading - 1);
        return;
      } else {
        setStatusClass('save-success');

        setTimeout(() => {
          setStatusClass('');
        }, 3000);
        setLoading(oldLoading => oldLoading - 1);
      }
    },
    [addArrayElement]
  );

  const onDelete = useCallback(
    async beUpload => {
      setLoading(oldLoading => oldLoading + 1);
      try {
        // could not save in backend, cancel upload (remove file)
        await removeFile(beUpload?.s3key);
      } catch (err) {
        // ignore? we can't do anything else here if deleting fails
        // we would need a "cleaner" to clean dangling files regularly
        setStatusClass('save-error');
        setLoading(oldLoading => oldLoading - 1);
        AppToaster.error('Erro a remover documento');
        return;
      }

      // Save to backend
      // const backendResponse = await updateField(path, toSave);
      const backendResponse = await deleteArrayElement(dataPath, beUpload?._id);

      if (!backendResponse?.ok) {
        setStatusClass('save-error');
        AppToaster.error('Erro a remover documento. Recarregue a página.');
      }
      setLoading(oldLoading => oldLoading - 1);
    },
    [deleteArrayElement]
  );

  const loadingArray = new Array(isLoading);
  for (let i = 0; i < isLoading; i++) {
    loadingArray.push(<Spin key={i}></Spin>);
  }

  return (
    <Fragment key={'' + dataPath}>
      <div className="array-container w-100">
        <div className="d-flex justify-content-start align-items-center w-100 pt-2 pb-3">
          <div className="d-flex justify-content-start align-items-center">
            {label && <h4 className="mb-0 pr-2">{label}</h4>}
            <span className="font-1 pr-2">(Histórico)</span>
          </div>
          <div className="d-flex justify-content-end align-items-center fg-1">
            <Upload
              name="file"
              multiple={true}
              className="ant-upload-row d-flex justify-content-lg-start align-items-center"
              beforeUpload={file => {
                onSave(file);
                return false;
              }}
              showUploadList={false}
              listType="text"
            >
              <Button
                className="border-radius mr-3"
                size="small"
                shape="circle"
                type="primary"
                ghost
              >
                <FileAddOutlined />
              </Button>
            </Upload>
            <ArraySearch
              dataPath={dataPath}
              dataCache={dataCache}
              onSearch={setValue}
            ></ArraySearch>
          </div>
        </div>

        <div className="array-container-inner w-100">
          {value[dataPath].map?.((nestedDatum, dataIndex) => {
            return (
              <div
                key={'' + nestedDatum?._id + dataIndex}
                className={`fields-container array-item array-item-${
                  dataIndex % 2 === 0 ? 'even' : 'odd'
                } w-100 border-radius`}
              >
                <div className="array-index pb-0 d-flex justify-content-start align-items-center">
                  <span className="d-flex justify-content-center align-items-center border-radius-50 mr-3">
                    {dataIndex}
                  </span>
                </div>
                <div className="d-flex fg-1 justify-content-between align-items-center">
                  {nestedDatum?.originalName && (
                    <Button
                      className={`mr-2 border-radius ${statusClass}`}
                      type="link"
                      onClick={async () => {
                        let fileLink;
                        try {
                          fileLink = await getFileLink(nestedDatum?.s3key);
                        } catch (err) {
                          console.error(err);
                          AppToaster.error('Erro a obter documento');
                          return;
                        }
                        window.open(fileLink, '_blank');
                      }}
                    >
                      <span>{nestedDatum?.originalName ?? ''}</span>
                    </Button>
                  )}
                  <Button
                    className="delete-element mr-2"
                    size="small"
                    type="danger"
                    ghost
                    icon={<DeleteOutlined></DeleteOutlined>}
                    shape="circle"
                    onClick={() => onDelete(nestedDatum)}
                  ></Button>
                </div>
              </div>
            );
          })}
          {isLoading > 0 && <>{loadingArray}</>}
        </div>
      </div>
    </Fragment>
  );
};

function getSafeFilename(weirdName) {
  return weirdName.replace(/[^a-z0-9_\-]/gi, '_').toLowerCase();
}

export default Uploads;
