// defaults to pixels
const globalBaseline = 12;

const globalStyles = {
  global: {
    baseline: 12,
    body: {
      backgroundColor: '#ffffff',
      margin: 0,
      fontWeight: 100
    }
  },
  colors: {
    foreground: {
      success: '#E6FFE7',
      warning: '#FFF3E6',
      error: '#FFE6E8',
      edited: '#E6EAFF'
    },
    feedback: {
      success: '#76FAC6',
      warning: '#FFC74D',
      error: '#FF4D5B',
      edited: '#AA4DFF'
    },
    background: {
      neutral: 'transparent',
      caiadoBlue: '#9D9E9C',
      queenBlue: '#0353a4',
      // https://coolors.co/ff7aa8-493657-ffd1ba-7c98b3-d6dbd2 :
      pink: '#f5222d',
      violet: '#493657',
      peach: '#ffd1ba',
      weldon: '#7c98b3',
      timber: '#d6dbd2'
    }
  },
  badges: {
    cyan: { backgroundColor: '#2db7f5' }
  },
  typography: {
    size: {
      base: globalBaseline
    },
    weight: 100,
    colors: {
      primary: '#444',
      light: '#fff'
    }
  },
  layout: {
    flexVertical: {
      display: 'flex',
      flexDirection: 'column'
    },
    flexHorizontal: {
      display: 'flex',
      flexDirection: 'row'
    },
    flexStart: {
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    flexCenter: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    flexEnd: {
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    flexBetween: {
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    flexEvenly: {
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },
    alignCenter: { alignItems: 'center' },
    alignStart: { alignItems: 'flex-start' },
    alignEnd: { alignItems: 'flex-end' }
  }
};

export default globalStyles;
