import React from 'react';
import autobind from 'auto-bind';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, Tag, Tooltip, Popconfirm } from 'antd4';
import {
  LeftCircleOutlined,
  ClusterOutlined,
  IdcardOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  EuroCircleFilled
} from '@ant-design/icons';

import './FamilyTree.scss';
import SingleClient from './SingleClient';
import CreateRelative from './CreateRelative';

class FamilyTree extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selected: props.parent, type: null };

    autobind(this);
  }

  static getDerivedStateFromProps(props) {
    if (props.descendantId) {
      return {
        selected: props.parent?.family?.find?.(
          x => x._id === props.descendantId
        ),
        type: SingleClient.TYPES.descendant
      };
    }

    return { selected: props.parent, type: SingleClient.TYPES.parent };
  }

  navigateToDescendant(_id) {
    const { match, location, history } = this.props;
    let result;
    const url = location?.pathname.replace(/\/$/, '');
    const descendantId = match?.params?.descendant;
    if (descendantId) {
      result = url.replace(descendantId, _id);
    } else {
      result = url + '/' + _id;
    }

    if (location?.search) {
      result += location.search;
    }
    return history.push(result);
  }

  async createRelative(relative) {
    const parentId = this.props.parent?._id;
    return await this.props.onCreateRelative?.(relative, parentId);
  }

  render() {
    const family = this.props.parent?.family ?? [];
    const hasFamily = family.length > 0;

    return (
      <>
        <Helmet>
          <title>{'Cliente ' + this.state.selected?.main?.nome ?? ''}</title>
        </Helmet>
        <div
          id="family-tree"
          className="d-flex flex-col justify-content-start align-items-start w-100 h-100"
        >
          <div className="family-header d-flex flex-row justify-content-start align-items-center w-100">
            <Link
              onClick={() => this.props.search('')}
              className="row-link h-100 border-radius hover-bg-blue d-flex align-items-center"
              to={'../..'}
            >
              <LeftCircleOutlined className="icon-back"></LeftCircleOutlined>
            </Link>
            <h3 className="no-margin d-flex justify-content-start">
              <span className="header-title d-flex align-items-center">
                Investidor Principal
              </span>
              <Tag>{this.props.parent?.main?.nome ?? ' não encontrado'}</Tag>
            </h3>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center w-100 h-85">
            <div className="sidebar card h-100 w-20">
              <div className="card-header">
                <h3 className="card-header-heading">
                  <ClusterOutlined className="color-gray header-icon"></ClusterOutlined>
                  Gestão
                </h3>
              </div>
              <div className="card-body d-flex flex-col justify-content-start align-items-start">
                <div className="main-investor w-100 d-flex flex-col justify-content-start align-items-start">
                  <h4 className="card-header-heading color-gray">
                    Investidor Principal
                  </h4>
                  <Tooltip title={'Seleccionar investidor'}>
                    <Button
                      type="secondary"
                      onClick={() => this.navigateToDescendant('')}
                      className={`border-radius d-flex justify-content-start`}
                    >
                      {this.props.parent?.main?.nome ?? ' não encontrado'}
                    </Button>
                  </Tooltip>
                </div>
                <div className="family w-100 d-flex flex-col justify-content-start align-items-start">
                  <h4 className="card-header-heading color-gray">Familiares</h4>
                  {family.map(member => {
                    return (
                      <Tooltip key={member._id} title={'Seleccionar familiar'}>
                        <Button
                          className={`member-button w-100 border-radius d-flex flex-row align-items-center justify-content-between ${
                            this.props.descendantId === member._id
                              ? 'focused'
                              : ''
                          }`}
                          onClick={() => this.navigateToDescendant(member._id)}
                        >
                          <div>
                            {member.main?.nome ? member.main?.nome : 'no name'}
                          </div>
                          {member.main?.familiar_com_faturacao && (
                            <Tooltip
                              onMouseOver={e => e.stopPropagation()}
                              title={'Tem Facturação Própria'}
                            >
                              <EuroCircleFilled className="color-green" />
                            </Tooltip>
                          )}
                        </Button>
                      </Tooltip>
                    );
                  })}
                  <div
                    className={`w-100 d-flex justify-content-${
                      hasFamily ? 'center' : 'start'
                    } align-items-center`}
                  >
                    <Tooltip title={'Criar familiar'}>
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        icon={<PlusOutlined></PlusOutlined>}
                        shape="circle"
                        onClick={() => {
                          this.setState(state => ({
                            showCreateRelative: true
                          }));
                        }}
                      ></Button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* <div className="card-footer"></div> */}
            </div>

            <div className="data card h-100 w-100">
              <div className="card-header d-flex justify-content-between">
                <h3 className="no-margin">
                  <IdcardOutlined className="color-gray header-icon"></IdcardOutlined>
                  {this.state.showCreateRelative
                    ? 'Criar Familiar'
                    : this.state.selected?.main?.nome ?? ''}
                </h3>
                <div className="operations d-flex flex-col justify-content-start align-items-start">
                  <div className="d-flex flex-col justify-content-start align-items-start">
                    <div className="operation d-flex flex-row align-items-center">
                      <Tooltip
                        title={
                          'Eliminar ' + this.state.selected?.main?.nome ??
                          'cliente'
                        }
                      >
                        <Popconfirm
                          okText="Apagar"
                          okButtonProps={{ type: 'danger', size: 'small' }}
                          cancelText="Cancelar"
                          title={
                            <>
                              <h3>Confirmar Operação!</h3>
                              {this.state.type ===
                                SingleClient.TYPES.parent && (
                                <span>
                                  Todos os familiares serão eliminados!
                                </span>
                              )}
                            </>
                          }
                          icon={
                            <ExclamationCircleOutlined
                              style={{ color: 'red' }}
                            />
                          }
                          onConfirm={async () => {
                            if (this.state.type === SingleClient.TYPES.parent) {
                              await this.props.onDelete(
                                this.state.selected?._id
                              );
                            } else {
                              await this.props.onDelete(
                                this.props.parent?._id,
                                this.state.selected?._id
                              );
                              await this.navigateToDescendant('');
                            }
                          }}
                        >
                          <Button
                            type="danger"
                            ghost
                            icon={<DeleteOutlined></DeleteOutlined>}
                          >
                            Eliminar
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.showCreateRelative ? (
                  <div
                    className={`w-100 d-flex flex-col justify-content-center align-items-center`}
                  >
                    <div className="w-100 d-flex justify-content-start pb-3"></div>
                    <CreateRelative
                      onCreate={this.createRelative}
                      onCancel={() => {
                        this.setState({ showCreateRelative: false });
                      }}
                    ></CreateRelative>
                  </div>
                ) : (
                  <SingleClient
                    tab={this.props.tab ?? 1}
                    type={this.state.type}
                    data={this.state.selected}
                    parent={this.props.parent}
                    updateField={this.props.updateField}
                    addArrayElement={this.props.addArrayElement}
                    deleteArrayElement={this.props.deleteArrayElement}
                  ></SingleClient>
                )}
              </div>
              {/* <div className="card-footer"></div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(FamilyTree);
