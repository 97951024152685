import React from 'react';
import { Button, Icon, Tooltip, Input } from 'antd';

import {
  renderColumn,
  renderDateColumn,
  textSorter,
  dateSorter,
  booleanSorter
} from '../../common/table';

import globalStyles from '../../styles/global';

import { scopes } from './ClientTable';

const styles = {
  actionContainer: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    marginRight: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  notesContainer: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignCenter
  },
  editAction: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter,
    borderRight: '1px solid #e8e8e8'
  },
  client: {
    title: {
      marginRight: globalStyles.global.baseline * 0.5,
      fontSize: globalStyles.global.baseline * 1.6,
      fontWeight: 700,
      color: '#888888',
      width: '100%'
    },
    infoBox: {
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      alignItems: 'center',
      width: '100%'
    },
    description: {
      paddingRight: globalStyles.global.baseline * 1.2,
      fontSize: globalStyles.global.baseline,
      color: '#666666'
    }
  }
};

const getConsistencyIcon = record => {
  let title;
  let type;
  let color;
  if (record.edited) {
    title = 'modificado';
    type = 'edit';
    color = globalStyles.colors.feedback.edited;
  } else if (record.has_errors) {
    title = 'Erros';
    type = 'close-circle';
    color = globalStyles.colors.feedback.error;
  } else if (record.warning) {
    title = record.warning;
    type = 'warning';
    color = globalStyles.colors.feedback.warning;
  } else {
    title = 'OK';
    type = 'check';
    color = globalStyles.colors.feedback.success;
  }
  return (
    <Tooltip title={title}>
      <Icon
        type={type}
        style={{
          fontSize: globalStyles.global.baseline * 1.5,
          color: color
        }}
      />
    </Tooltip>
  );
};

// const getReportIcon = record => {
//   return (
//     <Tooltip title={'Unknown'}>
//       <Icon
//         type={'warning'}
//         style={{
//           fontSize: globalStyles.global.baseline * 1.5,
//           color: globalStyles.colors.feedback.warning
//         }}
//       />
//     </Tooltip>
//   );
// };

const defaults = {};
const consistency = {};
const report = {};

const getColumns = options => {
  const {
    scope,
    handleChange,
    edit,
    save,
    cancel,
    remove,
    reveal,
    consistencyWarning,
    isWarned,
    setWarned,
    checkPermission
  } = options;

  const isConsistency = scope === scopes.CONSISTENCY;

  defaults.nif = width => {
    return {
      title: 'NIF',
      dataIndex: 'nif',
      key: 'nif',
      width: width,
      render: (obj, record) => ({
        props: { colSpan: 1 },
        children: (
          <div style={styles.client.title}>
            <span>{obj?.toString() || ''}</span>
          </div>
        )
      }),
      sorter: textSorter('nif')
    };
  };
  defaults.name = width => {
    return {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: width,
      render: (obj, record) => ({
        props: { colSpan: 1 },
        children: (
          <div>
            <span>{obj?.toString() || ''}</span>
          </div>
        )
      }),
      sorter: textSorter('name')
    };
  };
  defaults.primavera = width => {
    return {
      title: 'Primavera',
      dataIndex: 'primavera',
      key: 'primavera',
      width: width,
      render: (obj, record) => ({
        props: { colSpan: 1 },
        children: (
          <div>
            <span>{obj?.toString() || ''}</span>
          </div>
        )
      }),
      sorter: textSorter('primavera')
    };
  };
  /*defaults.language = width => {
    return {
      title: (
        <Tooltip title="Língua">
          <Icon
            type="audio"
            style={{
              fontSize: globalStyles.global.baseline * 1.5
            }}
          />
        </Tooltip>
      ),
      dataIndex: 'language',
      key: 'language',
      width: width,

      render: (obj, record) => {
        const options = {
          'pt-PT': 'PT',
          'en-US': 'EN'
        };
        return renderEditableColumn(
          obj,
          record,
          'language',
          handleChange,
          true,
          options
        );
      },
      sorter: textSorter('language'),
    };
  };
  defaults.email_contacts = width => {
    return {
      title: 'Contactos',
      dataIndex: 'email_contacts',
      key: 'email_contacts',
      width: width,
      render: (obj, record) =>
        renderEditableColumn(
          obj,
          record,
          'email_contacts',
          handleChange,
          false,
          [],
          searchText
        ),
      sorter: textSorter('email_contacts'),
    };
  };*/
  defaults.notes = width => {
    return {
      title: 'Notas',
      dataIndex: 'notes',
      width: width,
      key: 'notes',
      render: (obj, record) => {
        return record.editable ? (
          <div style={styles.notesContainer}>
            <Input.TextArea
              value={obj}
              style={{ marginRight: globalStyles.global.baseline }}
              onChange={e => handleChange(e.target.value, record.nif, 'notes')}
            />
            <div style={styles.editAction}>
              <Tooltip title={'Guardar'}>
                <Button
                  style={styles.action}
                  disabled={record.saving}
                  icon="check"
                  type="primary"
                  onClick={() => save(record.nif)}
                ></Button>
              </Tooltip>
              <Tooltip title={'Cancelar'}>
                <Button
                  style={styles.action}
                  disabled={record.saving}
                  ghost
                  icon="close"
                  type="danger"
                  onClick={() => cancel(record.nif)}
                ></Button>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div>{obj}</div>
        );
      },
      sorter: textSorter('notes')
    };
  };
  defaults.createdAt = width => {
    return {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: width,
      render: renderDateColumn(1),
      sorter: dateSorter('createdAt')
    };
  };
  defaults.action = width => {
    return {
      title: 'Acções',
      key: 'Acções',
      width: width,
      render: (text, record) => ({
        props: { colSpan: 1 },
        children: (
          <div style={styles.actionContainer}>
            {checkPermission('base:clients:edit') && (
              <Tooltip title={'Editar Notas'}>
                <Button
                  disabled={record.editable || record.saving}
                  style={styles.action}
                  ghost
                  type="primary"
                  icon="form"
                  onClick={() => {
                    if (isConsistency && !isWarned) {
                      setWarned();
                      consistencyWarning();
                    }
                    return edit(record.nif);
                  }}
                ></Button>
              </Tooltip>
            )}
            {checkPermission('base:clients:view_secrets') && (
              <Tooltip title={'Editar Credenciais'}>
                <Button
                  style={styles.action}
                  disabled={record.editable || record.saving}
                  type="primary"
                  icon="lock"
                  ghost
                  onClick={async () => {
                    return await reveal(record.nif);
                  }}
                ></Button>
              </Tooltip>
            )}
            {checkPermission('base:clients:remove') && (
              <Tooltip title={'Apagar'}>
                <Button
                  style={styles.action}
                  disabled={record.editable || record.saving}
                  icon="delete"
                  ghost
                  type="danger"
                  onClick={() => {
                    return remove(record.nif);
                  }}
                ></Button>
              </Tooltip>
            )}
          </div>
        )
      })
    };
  };

  consistency.errors = width => {
    return {
      title: (
        <Tooltip title={'Estado'}>
          <Icon
            type={'check'}
            style={{
              fontSize: globalStyles.global.baseline * 1.5
            }}
          />
        </Tooltip>
      ),
      dataIndex: 'has_errors',
      key: 'has_errors',
      width: width,
      render: (obj, record) => getConsistencyIcon(record),
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        booleanSorter('has_errors')(a, b)
          ? booleanSorter('has_errors')(a, b)
          : booleanSorter('warning')(a, b)
    };
  };
  consistency.state = width => {
    return {
      title: 'Descriç.',
      dataIndex: 'client_state',
      key: 'client_state',
      width: width,
      render: (obj, record) => {
        let content;
        if (record.edited) {
          content = 'Editado';
        } else if (record.has_errors) {
          content = record.nif_state;
        } else if (record.warning) {
          content = record.warning;
        } else {
          content = 'OK';
        }
        return (
          <span
            style={{
              fontWeight: 700
            }}
          >
            {content}
          </span>
        );
      },
      sorter: textSorter('client_state')
    };
  };
  consistency.files = width => {
    return {
      title: 'Files',
      dataIndex: 'client_files',
      key: 'client_files',
      width: width,
      render: (obj, record) => {
        const fileList = obj.map(fileName => (
          <span key={fileName}>
            <span>{fileName}</span>
            <br />
            <br />
          </span>
        ));
        return renderColumn(1)(fileList);
      }
    };
  };

  report.send_result = width => {
    return {
      title: 'Resultado',
      dataIndex: 'send_result',
      key: 'send_result',
      width: width,
      render: (obj, record) => renderColumn(1)(obj),
      defaultSortOrder: 'ascend',
      sorter: textSorter('send_result')
    };
  };

  report.send_date = width => {
    return {
      title: 'Data Env.',
      dataIndex: 'send_date',
      key: 'send_date',
      width: width,
      render: renderDateColumn(1, 'YYYY-MM-DD H:mm:ss'),
      sorter: dateSorter('createdAt')
    };
  };
  /*
  report.language = width => {
    return {
      title: (
        <Tooltip title="Língua">
          <Icon
            type="audio"
            style={{
              fontSize: globalStyles.global.baseline * 1.5
            }}
          />
        </Tooltip>
      ),
      dataIndex: 'language',
      key: 'language',
      width: width,

      render: (obj, record) => {
        return renderColumn(
          1,
          {},
          {
            'pt-PT': 'PT',
            'en-US': 'EN'
          }
        )(obj);
      },
      sorter: textSorter('language'),
    };
  };
*/
  let result;
  if (scope === scopes.DEFAULT) {
    result = [
      defaults.nif('15%'),
      defaults.name('15%'),
      defaults.primavera('15%'),
      // defaults.language('4%'),
      defaults.createdAt('15%'),
      defaults.notes('30%'),
      defaults.action(null)
    ];
  } else if (scope === scopes.CONSISTENCY) {
    result = [
      defaults.nif('15%'),
      // defaults.language('4%'),
      defaults.createdAt('10%'),
      defaults.notes('10%'),
      defaults.action('10%'),
      consistency.state('7%'),
      consistency.errors('5.5%')
      //consistency.files(null)
    ];
  } else if (scope === scopes.REPORT) {
    result = [
      defaults.nif('15%'),
      // defaults.language('4%'),
      defaults.createdAt('10%'),
      defaults.notes('10%'),
      defaults.action('10%'),
      report.send_result('27%'),
      report.send_date('15%')
    ];
  }
  return result;
};

export default getColumns;
