import React, { Fragment } from 'react';
import autobind from 'auto-bind';
import { Select } from 'antd4';
import {
  Field,
  DateField,
  TextAreaField,
  CheckboxField,
  UploadField,
  Hr,
  Placeholder,
  FieldNationality,
  SelectField
} from '../../../fields';

import '../../../fields/Fields.scss';

class Main extends React.Component {
  constructor(props) {
    super(props);

    autobind(this);
  }

  render() {
    return (
      <Fragment key={'main'}>
        <h4 className="w-100">Dados Principais</h4>
        <Field
          key={'role'}
          width={33}
          label={'Relação c/ Principal'}
          path={'main.role'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <CheckboxField
          key={'familiar_com_faturacao'}
          label={'Tem faturação própria'}
          width={33}
          path={'main.familiar_com_faturacao'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></CheckboxField>
        <Field
          key={'applicant'}
          width={50}
          label={'Requente'}
          path={'main.applicant'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'nome'}
          width={50}
          label={'Nome'}
          path={'main.nome'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'dossier'}
          label={'Dossier'}
          path={'main.dossier'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'juris'}
          label={'Juris'}
          path={'main.juris'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <Field
          key={'situacao'}
          width={50}
          label={'Situação'}
          path={'main.situacao'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <SelectField
          multiple={true}
          key={'investment_type'}
          width={50}
          label={'Tipos de Investimento'}
          options={[
            { label: 'Imóveis', value: 'imoveis' },
            { label: 'Fundos', value: 'fundos' },
            { label: 'N/A', value: 'N/A' }
          ]}
          path={`main.investment_type`}
          data={this.props.data}
          updateField={this.props.updateField}
        ></SelectField>
        <TextAreaField
          key={'observacao_geral'}
          width={100}
          label={'Obs. Geral'}
          minRows={2}
          path={'main.observacao_geral'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></TextAreaField>
        <Hr width={100}></Hr>
        <Field
          key={'nif'}
          width={33}
          label={'NIF'}
          path={'main.nif'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <DateField
          showTime={false}
          key={'nif_data_pedido'}
          label={'Data pedido'}
          width={33}
          path={'main.nif_data_pedido'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></DateField>
        <DateField
          showTime={false}
          key={'nif_data_recebido'}
          label={'Data Recebido'}
          width={33}
          path={'main.nif_data_recebido'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></DateField>
        <UploadField
          key={'nif_document'}
          label={'Doc. NIF'}
          width={66}
          path={'main.nif_document'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></UploadField>
        <Placeholder width={33}></Placeholder>
        <Field
          key={'password'}
          width={33}
          label={'Password (AT)'}
          path={'main.password'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        <DateField
          showTime={false}
          key={'data_pedido_password'}
          label={'Data Pedido'}
          width={33}
          path={'main.data_pedido_password'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></DateField>
        <Hr width={100}></Hr>
        <Field
          key={'passaporte'}
          width={50}
          label={'Passaporte'}
          path={'main.passaporte'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field>
        {/* <Field
          key={'nacionalidade'}
          width={50}
          label={'Nacionalidade'}
          path={'main.nacionalidade'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></Field> */}
        <FieldNationality
          key={'nacionalidade'}
          width={50}
          label={'Nacionalidade'}
          path={'main.nacionalidade'}
          data={this.props.data}
          updateField={this.props.updateField}
        ></FieldNationality>
      </Fragment>
    );
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  onSearch(val) {
    console.log('search:', val);
  }
}

export default Main;
